import React, { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import MainPagetitle from "../../../layouts/MainPagetitle";
import FlexCard from "../LoanApplications/Helpers/FlexCard";

const MyApplicationView = (props) => {
  const fromPath = props?.urlparams?.params?.path;
  const [show, setShow] = useState(false);
  const [applicationData, setApplicationData] = useState([]);
  const [editdata, setEditdata] = useState({});

  const [checkValue, setCheckValue] = useState("currentOutstanding");

  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [transactionTableData, setTransactionTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [rowcount, setRowcount] = useState(0);

  const transactionColumns = [
    {
      dataIndex: "loanAccountNumber",
      title: "LAN",
      render: (text, record) => {
        return <h6 className="text-primary">{text}</h6>;
      },
    },
    {
      dataIndex: "paymentDate",
      title: "Payment Date",
      render: (text, record) => {
        if (record?.paymentDate) {
          let date = new Date(record?.paymentDate).dateToDDMMYYYY(
            new Date(record?.paymentDate)
          );
          return date || "-";
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "paymentAmt",
      title: "Payment Amount",
      // sorter: (a, b) => a.paymentAmt - b.paymentAmt,
      render: (text, record) => {
        return <>{text?.cur()}</>;
      },
    },
    {
      dataIndex: "paymentMode",
      title: "Payment Mode",
      render: (text, record) => {
        return <>{text?.toTitleCase()}</>;
      },
    },
    {
      dataIndex: "paymentStatus",
      title: "Payment Status",
      render: (text, record) => {
        let status = record?.paymentStatus || "pending";
        let mappedStatus = _enum.STATUS_COLOR_MAP[status?.toLowerCase()] || "";

        return (
          <Dropdown className="status-dropdown pe-none">
            <Dropdown.Toggle as="div" className={mappedStatus}>
              {status?.toTitleCase()}
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
    {
      dataIndex: "paymentReferenceNum",
      title: "Payment Ref. No.",
    },
    {
      dataIndex: "totalAmt",
      title: "Total Amount",
      render: (text, record) => {
        return <>{text?.cur()}</>;
      },
    },
    // {
    //   dataIndex: "status",
    //   title: "Txn. Status",
    //   render: (text, record) => {
    //     let status = record?.status || "pending";
    //     let mappedStatus = _enum.STATUS_COLOR_MAP[status?.toLowerCase()] || "";

    //     return (
    //       <Dropdown className="status-dropdown pe-none">
    //         <Dropdown.Toggle as="div" className={mappedStatus}>
    //           {status?.toTitleCase()}
    //         </Dropdown.Toggle>
    //       </Dropdown>
    //     );
    //   },
    // },
  ];

  const onPageChange = (pageData) => {
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 10,
    });
  };

  const getApplication = () => {
    let payload = {
      applicationId: props.urlparams.params.id,
    };
    props.apis.apiGetCustomerApplicationById(payload).then((res) => {
      setEditdata(res);
      setApplicationData({
        loan_details: {
          loan_Id: res?.loanAccountNumber,
          loan_amount: res?.sanctionedLoanAmount?.cur(),
          start_date: new Date(res?.sanctionedLoanStartDate).dateToDDMMYYYY(
            new Date(res?.sanctionedLoanStartDate)
          ),
          end_date: new Date(res?.sanctionedLoanEndDate).dateToDDMMYYYY(
            new Date(res?.sanctionedLoanEndDate)
          ),
          loan_agreement: res?.loanAgreementFileId,
          tenure: res?.sanctionedLoanTenure,
          ROI: res?.sanctionedLoanIRR,
          processing_fee: res?.sanctionedLoanPF?.cur(),
          interest: res?.loanInterestAmtTillDate?.cur(),
          penal_interest: res?.penaltyInterestAmtTillDate?.cur(),
          total_payable: res?.totalAmountPayableAmtTillDate?.cur(),
          loan_status: res?.loanAccountStatus,
          outstanding_amount: res?.totalOutstandingAmtTillDate,
        },
      });
      setIsFetching(false);

      if (res?.loanAccountNumber) {
        let repaymentPayload = {
          loanAccountNumber: res?.loanAccountNumber,
        };
        props.apis
          .apiAdminGetAllLoanRepayments(repaymentPayload)
          .then((res) => {
            setTransactionTableData(res);
          });
      }
    });
  };

  const handleShow = (e) => {
    setShow(true);
  };

  const handleClose = (e) => {
    setShow(false);
  };

  const handlePayment = (e) => {
    e.persist();
    let value = e.target.value;
    setCheckValue(value);
  };

  const handleSubmit = (e, data) => {
    let payload = {
      loanAccountNumber: editdata.loanAccountNumber || "",
      // redirecturl: `${endpoints[environment].baseFE_URL}app/myapplications`,
    };

    if (checkValue === "currentOutstanding") {
      payload.amount = Number(editdata?.totalOutstandingAmtTillDate) || "";
    } else {
      payload.amount = Number(data?.amount);
    }

    // console.log({ editdata, payload });

    const resolve = () => {
      handleClose();
      getApplication();
    };

    props.apis
      .apiPayinByCustomer(payload)
      .then((res) => {
        if (res) {
          let atag = document.createElement("a");
          if (environment === "production") {
            atag.href = `https://pay.easebuzz.in/pay/${res?.data}`;
          } else {
            atag.href = `https://testpay.easebuzz.in/pay/${res?.data}`;
          }
          atag.setAttribute("target", "_blank");
          document.body.appendChild(atag);
          atag.click();
          document.body.removeChild(atag);
        }
      })
      .catch((err) => {
        resolve();
      });
  };

  const getBackLink = () => {
    let url;
    if (fromPath === "customerhome") {
      url = "/app/customerhome";
    } else {
      url = "/app/myapplications";
    }
    return url;
  };

  useEffect(() => {
    getApplication();
  }, []);
  return (
    <>
      <MainPagetitle
        mainTitle={`My Application | (Application No.)${props.urlparams.params.id}`}
        back={"View"}
        backLink={getBackLink()}
        pageTitle={"My Application"}
        parentTitle={"Home"}
        pageIcon={imagepaths.greyUserCheckIconActive}
      />

      <div className="container-fluid">
        <div className="card p-4">
          <FlexCard
            title="Loan Details"
            data={applicationData && applicationData?.loan_details}
            colspan_key="col-3"
            colspan_value="col-3"
            hasPdfFile={true}
            pdfIdKey="loan_agreement"
          />
          <div className="d-flex justify-content-start w-100">
            <button
              type="button"
              className="btn btn-primary"
              onClick={(...args) => handleShow(...args)}
            >
              Pay Now
            </button>
          </div>
        </div>

        <div className="card p-4">
          <div className="form_group_card px-0">
            <div className="d-flex align-items-start justify-content-between">
              <h2 className="mb-3 theme_text_sm">Transaction History</h2>
            </div>
            <div>
              <AppTable
                data={transactionTableData}
                columns={transactionColumns}
                onChange={(...arg) => onPageChange(...arg)}
                total={rowcount}
                reorder={true}
                editable={false}
                viewable={false}
                deletable={false}
                loading={isFetching}
              ></AppTable>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={(...args) => handleClose(...args)}
        className="customer_application_modal payment_modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <AppForm onSubmit={handleSubmit}>
            <div className="payment_modal_content_box">
              <p className="content_title">How much you want to pay?</p>
              <div className="checkbox_container mb-4 flex-column">
                <div class="form-check me-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="currentOutstanding"
                    name="communicationAddress"
                    id="currentOutstanding"
                    onChange={(...args) => handlePayment(...args)}
                    checked={checkValue === "currentOutstanding"}
                  />
                  <label class="form-check-label" for="currentOutstanding">
                    Current Outstanding
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="otherAmount"
                    name="communicationAddress"
                    id="otherAmount"
                    onChange={(...args) => handlePayment(...args)}
                    checked={checkValue === "otherAmount"}
                  />
                  <label class="form-check-label" for="otherAmount">
                    Other Amount
                  </label>
                </div>
              </div>
            </div>
            {checkValue === "otherAmount" && (
              <AppInput name="amount" label="Amount" type="number"></AppInput>
            )}
            <Modal.Footer className="justify-content-center">
              <button
                type="button"
                className="btn btn-primary-lite"
                onClick={(...args) => handleClose(...args)}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Pay Now
              </button>
            </Modal.Footer>
          </AppForm>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default constore(MyApplicationView);

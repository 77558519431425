import React from "react";

const DocsCard = ({
  size,
  data,
  hasAdditionalDocs = false,
  additionalDocs = [],
  editable = false,
  onEdit = (v) => v,
}) => {
  var BASE_URL = `${endpoints[environment].api_base}/api/v1/files/download/`;
  return (
    <div className={`card profile_card mx-0 ${(size && size) || ""}`}>
      <div className="card-header p-0 border-0">
        <div className="d-flex align-items-center mb-3">
          <img
            src={imagepaths.cardGaurdIcon}
            alt="cardGaurdIcon"
            className="card_icon"
          />
          <h5 className="title ms-2 mb-0">Docs Details</h5>
        </div>
        {editable && (
          <button
            type="button"
            className="btn edit_btn"
            onClick={(e) => onEdit(e)}
          >
            <i className="fa fa-pencil"></i>
          </button>
        )}
      </div>
      <div className="card-body p-0">
        <div className="docs_card_container row g-0">
          <div className="col-6 col-sm-4 col-md-6 col-lg-3">
            <div className="docs_card">
              <img src={imagepaths.docsCardRedIcon} alt="docsCardRedIcon" />
              <h2 className="title">PAN Card Front</h2>
              {data?.panFileMetaData && data?.panFileMetaData?.length !== 0 ? (
                <a
                  href={`${BASE_URL}${data?.panFileMetaData?.fileId}`}
                  className="text-primary"
                >
                  Download
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-6 col-sm-4 col-md-6 col-lg-3">
            <div className="docs_card">
              <img src={imagepaths.docsCardRedIcon} alt="docsCardRedIcon" />
              <h2 className="title">Aadhar Card (Front)</h2>
              {data?.aadharFrontFileMetaData &&
              data?.aadharFrontFileMetaData?.length !== 0 ? (
                <a
                  href={`${BASE_URL}${data?.aadharFrontFileMetaData?.fileId}`}
                  className="text-primary"
                >
                  Download
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-6 col-sm-4 col-md-6 col-lg-3">
            <div className="docs_card">
              <img src={imagepaths.docsCardRedIcon} alt="docsCardRedIcon" />
              <h2 className="title">Aadhar Card (Back)</h2>
              {data?.aadharBackFileMetaData &&
              data?.aadharBackFileMetaData?.length !== 0 ? (
                <a
                  href={`${BASE_URL}${data?.aadharBackFileMetaData?.fileId}`}
                  className="text-primary"
                >
                  Download
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-6 col-sm-4 col-md-6 col-lg-3">
            <div className="docs_card">
              <img src={imagepaths.docsCardRedIcon} alt="docsCardRedIcon" />
              <h2 className="title">Photo</h2>
              {data?.photoFileMetaData &&
              data?.photoFileMetaData?.length !== 0 ? (
                <a
                  href={`${BASE_URL}${data?.photoFileMetaData?.fileId}`}
                  className="text-primary"
                >
                  Download
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          {hasAdditionalDocs &&
            additionalDocs.map((doc, i) => {
              return (
                <>
                  <div className="col-6 col-sm-4 col-md-6 col-lg-3">
                    <div className="docs_card">
                      <img
                        src={imagepaths.docsCardRedIcon}
                        alt="docsCardRedIcon"
                      />
                      <h2 className="title">{doc?.fileName}</h2>
                      {doc?.fileId ? (
                        <a
                          href={`${BASE_URL}${doc?.fileId}`}
                          className="text-primary"
                        >
                          Download
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default constore(DocsCard);

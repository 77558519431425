export const SeniorUnderwriterMenuList = [
  {
    title: "Dashboard",
    iconStyle: imagepaths.Dashboard,
    iconActive: imagepaths.DashboardActive,
    to: "/app/home",
    id: ["home", "portfolioinfo"],
  },
  {
    title: "Pending Applications",
    iconStyle: imagepaths.applicationCheckImg,
    iconActive: imagepaths.applicationCheckActiveImg,
    to: "/app/pendingapplications",
    id: "pendingapplications",
  },
  {
    title: "My Applications",
    iconStyle: imagepaths.applicationCheckImg,
    iconActive: imagepaths.applicationCheckActiveImg,
    to: "/app/loanapplications",
    id: "loanapplications",
  },
  {
    title: "Loan Details",
    iconStyle: imagepaths.applicationCheckImg,
    iconActive: imagepaths.applicationCheckActiveImg,
    to: "/app/loandetails",
    id: "loandetails",
  },
  {
    title: "Know Your Customer",
    iconStyle: imagepaths.PersonSearchIcon,
    iconActive: imagepaths.PersonSearchIconActive,
    to: "/app/kyc",
    id: ["kyc", "creditscore"],
  },
];

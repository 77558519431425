import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FlexCard from "../Helpers/FlexCard";
import PostSanctionedDocsEdit from "./PostSanctionedDocsEdit";

const PostSanctionedDocs = (props) => {
  var BASE_URL = `${endpoints[environment].api_base}/api/v1/files/download/`;
  var isReadonly = props.urlparams.params.readonly === "true" ? true : false;
  var tabStatus = props.urlparams.params.status;
  var applStatus = props.urlparams.params.applicationStatus;
  const [sanctioneddocs, setSanctioneddocs] = useState([]);
  const [editdata, setEditdata] = useState(null);
  const [activeAccordion, setActiveAccordion] = useState("permanent_address");
  // ----------------------------------------------------------------
  const [uwdecision, setUwdecision] = useState("");
  const [queries, setQueries] = useState([{ query: "" }]);
  const [editmode, setEditmode] = useState(false);
  const [bankDataAsPerBSA, setBankDataAsPerBSA] = useState({});
  const [summaryScoreData, setSummaryScoreData] = useState(null);
  // ----------------------------------------------------------------

  // Add Queries
  const addQueries = () => {
    setQueries((prevState) => [...prevState, { query: "" }]);
  };

  const handleUWDecision = (val) => {
    let value = val[0].key;
    setUwdecision(value);
    setQueries([{ query: "" }]);
  };

  const handleDecision = (e, data) => {
    // console.log("formData", data);

    const resolve = (title) => {
      navigate(`/app/loanapplications?activetab=${tabStatus}`);
      swal2.fire({
        title: `Post Sanction ${title} Successfully`,
        icon: "success",
        iconColor: "#DE3232",
        confirmButtonColor: "#DE3232",
        confirmButtonText: "Close",
        timer: 3000,
      });
    };

    if (data?.uwDecision === _enum.UNDER_WRITER_DECISION_MAP["approved"]) {
      props?.apis
        ?.apiUnderWriterApprovePostSanctionApplication({
          applicationId: props.urlparams.params.id,
        })
        .then(() => resolve("Approved"));
    }
    if (data?.uwDecision === _enum.UNDER_WRITER_DECISION_MAP["declined"]) {
      swal2
        .fire({
          title:
            "Are you sure you want to reject the application?\n Please provide your remarks",
          icon: "info",
          iconColor: "#DE3232",
          input: "text",
          showCancelButton: true,
          confirmButtonColor: "#DE3232",
          cancelButtonColor: "##D6DBE7",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let payload = {
              rejectedReason: result.value,
            };
            props.apis
              .apiUnderWriterRejectApplication(payload, {
                applicationId: props.urlparams.params.id,
              })
              .then(() => resolve("Declined"))
              .catch((err) => {
                console.log(err);
              });
          }
        });
    }
  };

  const getApplicationDetails = () => {
    props.apis
      .apiGetUnderWriterApplicationDetails({
        applicationNumber: props.urlparams.params.id,
      })
      .then((res) => {
        if (res) {
          let addressObj;
          let communicationAddressObj = res?.ApplicationAddressInfo.filter(
            (v) => v.addressType === "COMMUNICATION"
          );
          let permanentAddressObj = res?.ApplicationAddressInfo.filter(
            (v) => v.addressType === "PERMANENT"
          );
          if (permanentAddressObj.length > 0) {
            addressObj = permanentAddressObj;
          } else if (
            permanentAddressObj.length === 0 &&
            communicationAddressObj.length > 0
          ) {
            addressObj = communicationAddressObj;
          }

          console.log({ addressObj });
          let addr1 = addressObj[0]?.address1?.toTitleCase();
          let addr2 = addressObj[0]?.address2?.toTitleCase();
          let addr3 = addressObj[0]?.address3?.toTitleCase();
          let address = `${addr1 ? addr1 + "," : ""} ${
            addr2 ? addr2 + "," : ""
          } ${addr3 ? addr3 + "," : ""}`;

          setSanctioneddocs({
            permanent_address: {
              residence_status: addressObj[0]?.residenceStatus,
              pin_code: addressObj[0]?.pincode,
              city: addressObj[0]?.city,
              address_1: address,
              state: addressObj[0]?.state,
              document_proof: _enum.DOC_TYPES_MAP[addressObj[0]?.docType],
            },
            bank_account_details: {
              account_holder_name: res?.accountHolderName,
              account_no: res?.accountNumber,
              bank_name: res?.accountHolderBankName?.toTitleCase(),
              IFSC_code: res?.accountHolderBankIFSC,
            },
            additionalDocsSubmitted: res?.additionalDocsSubmitted?.filter(
              (v) => v.category === "FINAL_REVIEW"
            ),
            tvr_comment: res?.customerTVRComment,
          });
          setEditdata({
            permanent_address:
              res?.ApplicationAddressInfo[1] || res?.ApplicationAddressInfo[0],
            bank_account_details: {
              accountHolderName: res?.accountHolderName,
              accountNumber: res?.accountNumber,
              accountHolderBankName: res?.accountHolderBankName,
              accountHolderBankIFSC: res?.accountHolderBankIFSC,
            },
            additionalDocsSubmitted: res?.additionalDocsSubmitted?.filter(
              (v) => v.category === "FINAL_REVIEW"
            ),
            additionalDocsRequested: res?.additionalDocsRequested,
          });
        }
      });
  };

  const getBankDataAsPerBSA = () => {
    // props.apis
    //   .apiUnderWriterGetBsaReport({
    //     applicationNumber: props.urlparams.params.id,
    //     // isDownload: false,
    //   })
    //   .then((res) => {
    //     console.log({ res });
    //     let result = Object.fromEntries(
    //       Object.entries(res?.result?.json).filter(([key, value]) =>
    //         key.startsWith("Summary")
    //       )
    //     );
    //     let data = result["Summary - Scorecard"].reduce((acc, curr) => {
    //       acc[curr.Item] = curr.Details;
    //       return acc;
    //     }, {});
    //     setBankDataAsPerBSA(
    //       Object.only(data, [
    //         "Customer Name",
    //         "Account Number",
    //         "Bank",
    //         "IFSC Code",
    //       ])
    //     );
    //   })
    //   .catch((err) => {});

    props.apis
      .apiUnderWriterVerifyBsaStatus({
        applicationNumber: props.urlparams.params.id,
      })
      .then((res) => {
        if (res?.report?.reportStatus === "ANALYSED") {
          let accountData = Object.only(res?.reportAccounts?.[0]?.account, [
            "accountName",
            "accountNumber",
            "bankName",
            "ifscCode",
            "accountTypeName",
            "startDate",
            "endDate",
          ]);
          setSummaryScoreData({
            ...accountData,
            bankScore: res.report.bankScore,
          });
        } else if (res?.status === "ANALYSIS ON HOLD") {
          swal2.fire({
            title: "BSA Analysis On-Hold! \n Please check with team",
            icon: "success",
            iconColor: "#FF4D4D",
            timer: 2000,
          });
        } else if (res?.status === "IN PROGRESS") {
          swal2.fire({
            title: "BSA generation In-progress! \n Please check in sometime",
            icon: "success",
            iconColor: "#FF4D4D",
            timer: 2000,
          });
        } else if (res?.status === "pending") {
          // generateReport();
        }
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          // generateReport();
        }
      });
  };

  useEffect(() => {
    getApplicationDetails();
  }, [editmode]);

  useEffect(() => {
    getBankDataAsPerBSA();
  }, []);

  // ----------------------------------------------------------------
  const openEditView = (e, name) => {
    console.log({ name });
    setEditmode(true);
    setActiveAccordion(name);
  };
  const closeEditView = (e) => {
    setEditmode(false);
  };

  const handlePennyDrop = (e, record) => {
    e.preventDefault();
    e.stopPropagation();
    console.log({ record });

    let paymentPayload = {
      accountNumber: record.account_no,
      ifsc: record.IFSC_code,
    };
    props.apis
      .apiUnderWriterGeneratePennyDropPayment(paymentPayload)
      .then((res) => {
        // console.log({ res });

        swal2.fire({
          title: res?.error,
          icon: "error",
          iconColor: "#FF4D4D",
        });

        if (res.status === "success" && res.transactionId) {
          let statusPayload = {
            reference_id: res?.referenceId,
            transaction_id: res.transactionId,
          };

          props.apis
            .apiUnderWriterCheckPennyDropStatus(statusPayload)
            .then((res) => {
              // console.log({ res });
              if (res?.status === "success") {
                swal2.fire({
                  title: res?.error,
                  icon: "success",
                  iconColor: "#FF4D4D",
                });
              } else {
                swal2.fire({
                  title: res?.error,
                  icon: "error",
                  iconColor: "#FF4D4D",
                });
              }
            });
        }
      })
      .catch((err) => {});
  };
  // ----------------------------------------------------------------
  props.$setNode({
    props,
    sanctioneddocs,
  });
  return (
    <>
      {editmode === false ? (
        <>
          <div className="card px-4 pt-4 mb-0">
            <FlexCard
              title="Bank Account Details"
              data={sanctioneddocs && sanctioneddocs?.bank_account_details}
              editable={isReadonly ? false : true}
              onEdit={(...args) =>
                openEditView(...args, "bank_account_details")
              }
            />
          </div>
          <div className="card px-4 py-4 mb-0">
            <div className="flex_card_outer">
              <div className="flex_card px-3">
                <div className="d-flex align-item-center justify-content-between">
                  <h4 className="title">Verified Account Holder Name: </h4>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(...args) =>
                      handlePennyDrop(
                        ...args,
                        sanctioneddocs?.bank_account_details
                      )
                    }
                  >
                    Verify
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card p-4">
            <FlexCard
              title="Bank Account Details As per BSA"
              data={summaryScoreData}
            />
          </div>
          <div className="card profile_card additional_docs mx-0">
            <div className="card-header p-0 border-0">
              <div className="d-flex align-items-center mb-3">
                <h4 className="theme_text_sm ms-2 mb-0">Additional Document</h4>
                <button
                  type="button"
                  className={Object.className({
                    "btn edit_btn": true,
                    "d-none": isReadonly
                      ? true
                      : sanctioneddocs?.additionalDocsSubmitted?.length === 0
                      ? true
                      : false,
                  })}
                  onClick={(...args) =>
                    openEditView(...args, "additional_docs")
                  }
                >
                  <i className="fa fa-pencil"></i>
                </button>
              </div>
            </div>
            <div className="card-body p-0">
              <div className="docs_card_container row g-0">
                {sanctioneddocs &&
                sanctioneddocs?.additionalDocsSubmitted?.length > 0
                  ? sanctioneddocs?.additionalDocsSubmitted?.map((doc) => {
                      return (
                        <div className="col-6 col-sm-4 col-md-6 col-lg-3">
                          <div className="docs_card d-flex align-items-center justify-content-evenly">
                            <img
                              src={imagepaths.docsCardRedIcon}
                              alt="docsCardRedIcon"
                            />
                            <div>
                              {doc?.docType?.toUpperCase() === "OTHERS" ? (
                                <h2 className="title">{doc?.fileName}</h2>
                              ) : (
                                <h2 className="title">{doc?.docType}</h2>
                              )}
                              {sanctioneddocs &&
                              sanctioneddocs?.additionalDocsSubmitted?.length >
                                0 ? (
                                <a
                                  href={`${BASE_URL}${doc?.fileId}`}
                                  className="text-primary"
                                >
                                  Download
                                </a>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>

          <div className="card profile_card tvr_comment mx-0">
            <div className="card-header p-0 border-0">
              <div className="d-flex align-items-center mb-3">
                <h4 className="theme_text_sm ms-2 mb-0">TVR Comment</h4>
              </div>
            </div>
            <div className="card-body p-0 ms-2">
              <p className="comment_heading">Comment</p>
              <p className="comment">
                {(sanctioneddocs && sanctioneddocs?.tvr_comment) || "-"}
              </p>
            </div>
          </div>

          {isReadonly ? (
            <>
              <div className="nav_container mt-5">
                {tabStatus === "sanctioned" ? (
                  <button
                    type="button"
                    onClick={(...args) => props.changeTab(...args, "queries")}
                    className="btn btn-primary-lite me-3"
                  >
                    Back
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={(...args) =>
                      props.changeTab(...args, "finalreview")
                    }
                    className="btn btn-primary-lite me-3"
                  >
                    Back
                  </button>
                )}
                <Link
                  to={`/app/loanapplications?activetab=${tabStatus}`}
                  className="btn btn-primary"
                >
                  Close
                </Link>
              </div>
            </>
          ) : !isReadonly && applStatus === "SANCTIONED" ? (
            <>
              <div className="nav_container mt-5">
                {tabStatus === "sanctioned" ? (
                  <button
                    type="button"
                    onClick={(...args) => props.changeTab(...args, "queries")}
                    className="btn btn-primary-lite me-3"
                  >
                    Back
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={(...args) =>
                      props.changeTab(...args, "finalreview")
                    }
                    className="btn btn-primary-lite me-3"
                  >
                    Back
                  </button>
                )}
                <Link
                  to={`/app/loanapplications?activetab=${tabStatus}`}
                  className="btn btn-primary"
                >
                  Close
                </Link>
              </div>
            </>
          ) : (
            <AppForm
              className={Object.className({
                "mx-auto": true,
              })}
              onSubmit={handleDecision}
            >
              <div
                className={Object.className({
                  "d-none": applStatus === "SANCTIONED",
                })}
              >
                <AppInput
                  name="uwDecision"
                  label="Underwriter Decision"
                  type="select"
                  options={_enum.UNDER_WRITER_DECISIONS.filter(
                    (v) => v.key !== "DISCREPANCY"
                  )}
                  onChange={(...args) => handleUWDecision(...args)}
                  outerclass="col-12 col-md-3"
                  required="true"
                ></AppInput>

                <div
                  className={Object.className({
                    "card mt-3 h-auto": true,
                  })}
                >
                  {uwdecision && uwdecision === "DISCREPANCY" ? (
                    <>
                      <div className="row p-3">
                        {queries &&
                          queries.map((query, i) => {
                            return (
                              <div className="col-12 col-md-4">
                                <AppInput
                                  name={`queries.${i}.query`}
                                  label={`Query`}
                                  type="textarea"
                                ></AppInput>
                              </div>
                            );
                          })}
                      </div>
                      <div className="p-3">
                        <button
                          type="button"
                          className="btn btn-primary-transparent py-0"
                          onClick={(...args) => addQueries(...args)}
                        >
                          + Add More
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="row p-3">
                      <AppInput
                        name="messageText"
                        label="Remarks"
                        type="textarea"
                        outerclass="col-12 col-md-9 mb-3"
                      ></AppInput>
                    </div>
                  )}
                </div>
              </div>

              <div className="nav_container mt-5">
                <Link
                  to={`/app/loanapplications?activetab=${tabStatus}`}
                  className="btn btn-primary-lite me-3"
                >
                  Cancel
                </Link>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </AppForm>
          )}
        </>
      ) : (
        <PostSanctionedDocsEdit
          closeEditView={(...args) => closeEditView(...args)}
          editdata={editdata}
          activeAccordion={activeAccordion}
        />
      )}
    </>
  );
};

export default constore(PostSanctionedDocs);

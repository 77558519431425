import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { Dropdown, Modal } from "react-bootstrap";
import MainPagetitle from "../../../layouts/MainPagetitle";
import ApplicationModal from "../Applications/ApplicationModal";
import LeadsCenteredModal from "./LeadsCenteredModal";
import LeadsModal from "./LeadsModal";
import LeadsSearch from "./LeadsSearch/LeadsSearch";

const Leads = (props) => {
  const isAdmin = props?.$store?.persona === _enum.ROUTE_PERSONAS.admin;
  const isSales = props?.$store?.persona === _enum.ROUTE_PERSONAS.sales;
  const isOperations = props?.$store?.persona === _enum.ROUTE_PERSONAS.ops;
  const isSalesLeads =
    props?.$store?.persona === _enum.ROUTE_PERSONAS.sales_lead;

  const infoForm = useRef(null);
  const [loggedUserRole, setLoggedUserRole] = useState(null);
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
    order_by: "updatedAt",
    order_direction: "DESC",
  });
  //
  const bulkUploadModalRef = useRef(0);
  const changePasswordModalRef = useRef(0);
  const [popupBulkUploadFields, setPopupBulkUploadFields] = useState({});
  const [popupChangePasswordFields, setPopupChangePasswordFields] = useState(
    {}
  );
  const [popupBulkUploadData, setPopupBulkUploadData] = useState({});
  const [changepasswordData, setChangepasswordData] = useState({});
  const [bulkUploadModalFields, setBulkUploadModalFields] = useState([
    // {
    //   label: "Product Type",
    //   name: "productType",
    //   type: "select",
    //   options: [],
    //   customcolspan: "12",
    // },
    {
      label: "File Upload (Max limit 10,000)",
      name: "file",
      type: "filearea",
      accept: ".xlsx, .xls",
      customcolspan: "12",
    },
  ]);
  const [changepasswordFields, setChangepasswordFields] = useState([
    {
      label: "Old Password",
      name: "oldPassword",
      type: "password",
      customcolspan: "12",
    },
    {
      label: "New Password ",
      name: "newPassword",
      type: "password",
      customcolspan: "12",
    },
    {
      label: "Confirm Password",
      name: "confirmPassword",
      type: "password",
      customcolspan: "12",
    },
  ]);

  const [activeKey, setActiveKey] = useState("");
  //
  const [tableData, setTableData] = useState([]);
  const [isadmin, setIsadmin] = useState(false);
  const [istelecaller, setIstelecaller] = useState(false);
  const [isOps, setIsOps] = useState(false);
  const [logsData, setLogsData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });
  const [modalFields, setModalFields] = useState([
    {
      label: "Name",
      name: "leadName",
      type: "text",
      placeholder: "Please Enter",
    },
    {
      label: "Mobile No.",
      name: "mobileNumber",
      type: "text",
      onChange: (value) => handleMobileNo(value),
      placeholder: "Please Enter",
    },
    {
      label: "Email ID",
      name: "emailAddress",
      type: "email",
      onChange: (value) => handleEmailId(value),
      placeholder: "Please Enter",
    },
    {
      label: "Source",
      name: "source",
      type: "select",
      options: _enum.SOURCES,
    },
    {
      label: "Req. Loan Amt.",
      name: "loanAmount",
      type: "number",
      placeholder: "Please Enter",
    },
    {
      label: "Location",
      name: "location",
      type: "text",
      placeholder: "Please Enter",
    },
    {
      label: "Status",
      name: "status",
      type: "select",
      options: isSales
        ? _enum.ADMIN_LEADS_STATUS_WITH_FOLLOW_UP
        : [{ key: "OPEN", label: "Open" }],
      onChange: (...args) => handleLeadStatus(...args),
      placeholder: "Please Enter",
    },
    {
      label: "Remarks",
      name: "remarks",
      type: "textarea",
    },
    {
      label: "Follow Up Date",
      name: "followUpDate",
      type: "date",
    },
    {
      label: "Follow Up Time",
      name: "followUpTime",
      type: "time",
    },
  ]);
  const [editData, setEditData] = useState([]);
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});
  const [roles, setRoles] = useState([]);

  // ----------------   APPLICATION MODAL   ----------------

  const [applModalFields, setApplModalFields] = useState([
    {
      label: "Customer Name",
      name: "leadName",
      type: "text",
      placeholder: "Please Enter",
    },
    {
      label: "Mobile No.",
      name: "mobileNumber",
      type: "number",
      required: true,
      placeholder: "Please Enter",
    },
    // {
    //   label: "Product Type",
    //   name: "productType",
    //   type: "select",
    //   options: [],
    //   placeholder: "Please Enter",
    // },
    {
      label: "Bank Name",
      name: "bankName",
      type: "select",
      options: [],
      placeholder: "Please Enter",
    },
    {
      label: "Application No.",
      name: "applicationNumber",
      type: "text",
      placeholder: "Please Enter",
    },
    {
      label: "Application Date",
      name: "applicationDate",
      type: "date",
      defaultValue: new Date(),
      required: true,
      placeholder: "Please Enter",
    },
    {
      label: "Loan Amt",
      name: "loanAmount",
      type: "number",
      placeholder: "Please Enter",
    },
    {
      label: "Application Status",
      name: "status",
      type: "select",
      options: _enum.APPLICATION_STATUS,
      placeholder: "Please Enter",
    },
    {
      label: "Assigned By",
      name: "assignedPersonId",
      type: "text",
      placeholder: "Please Enter",
    },
    {
      label: "Tele Mobile No.",
      name: "tele_mobile_number",
      type: "number",
      placeholder: "Please Enter",
    },
    {
      label: "Remarks",
      name: "remarks",
      type: "textarea",
    },
  ]);
  const [editApplData, setEditApplData] = useState({});
  const [editApplMode, setEditApplMode] = useState(false);
  const [viewApplMode, setViewApplMode] = useState(false);
  const [openApplCanvas, setOpenApplCanvas] = useState(false);
  const [popupApplFields, setPopupApplFields] = useState({});
  const [popupApplData, setPopupApplData] = useState({});
  const [applLogsData, setApplLogsData] = useState([]);

  const applLogsColumns = [
    {
      dataIndex: "date",
      title: "Transaction Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "status",
      title: "Status",
      render: (text, record) => {
        return (
          <span className={`badge light badge-${_enum.STATUS_COLOR_MAP[text]}`}>
            {text?.toTitleCase() || "-"}
          </span>
        );
      },
    },
    {
      dataIndex: "updatedById",
      title: "Updated By",
    },
    {
      dataIndex: "remarks",
      title: "Remarks",
    },
  ];

  // ----------------------------------------------------------------
  const [rowcount, setRowcount] = useState(0);
  const [columns, setColumns] = useState([
    {
      dataIndex: "leadName",
      title: "Name",
      render: (text, record) => {
        return (
          <>
            <span className="text-primary">{text}</span>
            {record?.isVerified && (
              <i className="fa fa-circle-check text-red ms-2"></i>
            )}
          </>
        );
      },
    },
    {
      dataIndex: "mobileNumber",
      title: "Mobile No.",
    },
    {
      dataIndex: "location",
      title: "Location",
    },
    // {
    //   dataIndex: "productType",
    //   title: "Product Type",
    //   render: (text, record) => {
    //     return <>{text || "-"}</>;
    //   },
    // },
    {
      dataIndex: "assignedBy",
      title: "Assigned By",
      render: (text, record) => {
        let emp = record?.assignedEmployee?.employeeName;
        return emp;
      },
    },
    {
      dataIndex: "assignedBy",
      title: "Tele Mobile No.",
      render: (text, record) => {
        let emp = record?.assignedEmployee?.mobileNumber;
        return emp;
      },
    },
    {
      dataIndex: "source",
      title: "Source",
      render: (text, record) => {
        return <>{text?.toTitleCase() || "-"}</>;
      },
    },
    {
      dataIndex: "createdAt",
      title: "Created Date",
      render: (text, record) => {
        if (record?.createdAt) {
          let date = new Date(record?.createdAt).dateToDDMMYYYY(
            new Date(record?.createdAt)
          );
          return date || "-";
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "status",
      title: loggedUserRole === _enum.PERSONAS.sales ? "Lead Status" : "Status",
      render: (text, record) => {
        let mappedStatus =
          _enum.STATUS_COLOR_MAP[record?.status?.toLowerCase()] || "";
        return (
          <Dropdown className="status-dropdown no-arrow pe-none">
            <Dropdown.Toggle as="div" className={`${mappedStatus}`}>
              <span>{record?.status?.toTitleCase() || "-"}</span>
              <i class="fa-solid fa-chevron-down ms-2"></i>
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
    {
      dataIndex: "followUpAt",
      title: "Follow Up At",
      render: (text, record) => {
        if (record?.followUpAt) {
          let date = new Date(record?.followUpAt).dateToDDMMYYYY(
            new Date(record?.followUpAt)
          );
          let time = new Date(record?.followUpAt).toLocaleTimeString();
          return (
            <>
              {date} {time}
            </>
          );
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "assignedEmployee",
      title: "Assigned To",
      render: (text, record) => {
        let emp = record?.assignedEmployee?.name;
        return emp || "-";
      },
    },
    {
      dataIndex: "remarks",
      title: "Remarks",
    },
  ]);
  const logsColumns = [
    {
      dataIndex: "date",
      title: "Transaction Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "status",
      title: "Status",
      render: (text, record) => {
        return (
          <span className={`badge light badge-${_enum.STATUS_COLOR_MAP[text]}`}>
            {text?.toTitleCase() || "-"}
          </span>
        );
      },
    },
    {
      dataIndex: "updatedById",
      title: "Updated By",
    },
    {
      dataIndex: "remarks",
      title: "Remarks",
    },
  ];

  // ROW SELECTION
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [assignDept, setAssignDept] = useState({});
  const [notSelected, setNotSelected] = useState(true);
  const [disableBulkDownload, setDisableBulkDownload] = useState(true);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => {
      let { role } = props?.$store?.user;
      if (role === _enum.PERSONAS.sales && record?.status !== "INTERESTED") {
        return { disabled: true };
      }
    },
  };

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      console.log("has values");
      setNotSelected(false);
      setDisableBulkDownload(false);
    } else {
      console.log("has no values");
      setNotSelected(true);
      setDisableBulkDownload(true);
    }
  }, [selectedRowKeys]);

  const onPageChange = (pageData) => {
    // console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  // VALIDATIONS

  const handleMobileNo = (e) => {
    const numberReg = _enum?.REGEX?.number;
    const fieldValue = e.target.value;
    if (numberReg.test(fieldValue) && fieldValue.length === 10) {
      setErrorMessage("");
    } else {
      setErrorMessage({
        name: "mobileNumber",
        message: "Please enter a valid Mobile Number",
      });
    }
  };

  const handleEmailId = (e) => {
    const emailReg = _enum?.REGEX?.email;
    const fieldValue = e.target.value;
    if (emailReg.test(fieldValue)) {
      setErrorMessage("");
    } else {
      setErrorMessage({
        name: "emailAddress",
        message: "Please enter a valid Email Address",
      });
    }
  };

  const handleLeadStatus = (value) => {
    const targetValue = value[0]?.key;
    if (!isAdmin) {
      let targetRow = document
        .querySelector("[name='followUpDate']")
        .closest(".row");

      if (targetRow) {
        if (targetValue !== "FOLLOW_UP") {
          targetRow.classList.add("d-none");
        } else {
          targetRow.classList.remove("d-none");
        }
      }
    }
  };

  const handleAssignDept = (val) => {
    console.log({ handleAssignDept: val });
    setAssignDept(val);
    let payload = {
      leads: [...selectedRowKeys],
      assignedRole: val[0]?.key || "",
    };

    if (selectedRowKeys.length === 0) {
      console.log("empty selection");

      // CLEAR ASSIGN TO SELECT BOX
      let assignInput = document.querySelector(
        `[name='appinput-assignedRole'].form-group`
      );
      console.log(assignInput);
      let { values: assignValues = [] } = assignInput.vnode.refs.select.state;
      if (assignValues.length) {
        assignInput.vnode.refs.select.clearAll();
      }
      // swal2.fire({
      //   title:
      //     "Please select a lead from table before assigning to any department",
      //   icon: "info",
      //   iconColor: "#FF4D4D",
      // });
    }
  };

  const handleSubmitAssignDept = () => {
    let payload = {
      leads: [...selectedRowKeys],
      assignedRole: assignDept[0]?.key || "",
    };
    console.log({ handleSubmitAssignDept: payload });

    if (payload.assignedRole === "") {
      swal2.fire({
        title: "Please select any department",
        icon: "info",
        iconColor: "#FF4D4D",
      });
    } else {
      props.apis.apiAssignDeptToLead(payload).then((res) => {
        console.log({ apiAssignDeptToLead: res });

        if (res) {
          swal2.fire({
            title: "Lead Assigned",
            text: "Lead assigned successfully !!",
            icon: "success",
            iconColor: "#FF4D4D",
            timer: 4000,
          });
          // CLEAR ASSIGN TO SELECT BOX
          let assignInput = document.querySelector(
            `[name='appinput-assignedRole'].form-group`
          );
          // console.log(assignInput);
          let { values: assignValues = [] } =
            assignInput.vnode.refs.select.state;
          if (assignValues.length) {
            assignInput.vnode.refs.select.clearAll();
          }
          setSelectedRowKeys([]);
          getLeads();
        }
      });
    }
  };

  const getRoles = () => {
    props.apis.apiGetMasterRoles().then((res) => {
      setRoles(res);
    });
  };

  const getLeads = (params = {}, reset) => {
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, [
        "limit",
        "order_by",
        "order_direction",
        "page",
      ]);

      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
        ...params,
      };
      console.log({ defaultSearchData: payload });
    } else {
      payload = {
        ...search,
        ...params,
      };
    }

    props.apis.apiGetLeads(payload).then(({ data, total }) => {
      setTableData(data);
      setIsFetching(false);
      setRowcount(total);
    });
  };

  const viewLead = (record) => {
    setEditmode(false);
    setViewmode(true);
    // GET LEAD BY ID
    props.apis.apiGetLeadsById({ leadId: record.leadId }).then((data) => {
      console.log({ apiGetLeadsById: data });

      let resFollowUpDate = data?.followUpAt !== null ? data?.followUpAt : null;
      let resFollowUpTime =
        data?.followUpAt !== null ? new Date(data?.followUpAt)?.toTime() : null;

      let alteredData, allowedArrFields;

      if (!isAdmin) {
        alteredData = {
          ...data,
          followUpDate: resFollowUpDate,
          followUpTime: resFollowUpTime,
        };
        allowedArrFields = [
          ...modalFields.map((v) => v.name),
          "followUpDate",
          "followUpTime",
        ];
      } else {
        alteredData = {
          ...data,
        };
        allowedArrFields = [...modalFields.map((v) => v.name)];
      }

      let filteredData = Object.only(alteredData, allowedArrFields);

      console.log({ modalFields, alteredData, filteredData });
      if (!isAdmin) {
        let followUpDateField = modalFields.find(
          (o) => o.name == "followUpDate"
        );
        let followUpTimeField = modalFields.find(
          (o) => o.name == "followUpTime"
        );

        followUpDateField.disabled = true;
        followUpTimeField.disabled = true;
        if (filteredData.status !== "FOLLOW_UP") {
          followUpDateField.outerclass = "d-none";
          followUpTimeField.outerclass = "d-none";
        } else {
          followUpDateField.outerclass =
            "col-xl-6 mb-3 w-100 d-flex flex-column";
          followUpTimeField.outerclass =
            "col-xl-6 mb-3 w-100 d-flex flex-column";
        }
      }

      setPopupdata(filteredData);
      setEditData(alteredData);
      openModal();
    });
  };

  const editLead = (record) => {
    setEditmode(true);
    setViewmode(false);
    // GET LEAD BY ID
    props.apis.apiGetLeadsById({ leadId: record.leadId }).then((data) => {
      console.log({ apiGetLeadsById: data });

      let resFollowUpDate = data?.followUpAt !== null ? data?.followUpAt : null;
      let resFollowUpTime =
        data?.followUpAt !== null ? new Date(data?.followUpAt)?.toTime() : null;

      let alteredData, allowedArrFields;

      if (!isAdmin) {
        alteredData = {
          ...data,
        };
        if (data?.status === "FOLLOW_UP") {
          alteredData.followUpDate = resFollowUpDate;
          alteredData.followUpTime = resFollowUpTime;
        } else {
          alteredData.followUpDate = "";
          alteredData.followUpTime = "";
        }
        allowedArrFields = [
          ...modalFields.map((v) => v.name),
          "followUpDate",
          "followUpTime",
        ];
      } else {
        alteredData = {
          ...data,
        };
        allowedArrFields = [...modalFields.map((v) => v.name)];
      }

      let filteredData = Object.only(alteredData, allowedArrFields);

      console.log({ modalFields, alteredData, filteredData });

      if (!isAdmin) {
        let followUpDateField = modalFields.find(
          (o) => o.name == "followUpDate"
        );
        let followUpTimeField = modalFields.find(
          (o) => o.name == "followUpTime"
        );

        followUpDateField.outerclass = "col-xl-6 mb-3 w-100 d-flex flex-column";
        followUpTimeField.outerclass = "col-xl-6 mb-3 w-100 d-flex flex-column";

        followUpDateField.disabled = false;
        followUpTimeField.disabled = false;
      }

      setPopupdata(filteredData);
      setEditData(alteredData);
      openModal();

      if (!isAdmin) {
        setTimeout(() => {
          let targetRow = document
            .querySelector("[name='followUpDate']")
            .closest(".row");

          if (targetRow) {
            if (filteredData.status !== "FOLLOW_UP") {
              targetRow.classList.add("d-none");
            } else {
              targetRow.classList.remove("d-none");
            }
          }
        }, 100);
      }
    });
  };

  const deleteLead = (record) => {
    console.log({ deleteLead: record });
    const { isAssigned } = record;
    if (
      props?.$store?.user?.role === _enum.PERSONAS.admin &&
      isAssigned === true
    ) {
      swal2.fire({
        title: "Assigned leads cannot be deleted",
        icon: "error",
        iconColor: "#FF4D4D",
        showCancelButton: false,
        timer: 3000,
      });
    } else {
      swal2
        .fire({
          title: "Are you sure you want to delete this lead?",
          icon: "warning",
          iconColor: "#FF4D4D",
          showCancelButton: true,
          confirmButtonColor: "#FF4D4D",
          cancelButtonColor: "##D6DBE7",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            props.apis.apiDeleteLead({ leadId: record.leadId }).then((res) => {
              console.log({ apiDeleteLead: res });
              swal2.fire({
                title: "Lead Deleted",
                text: "Lead deleted successfully !!",
                icon: "success",
                iconColor: "#FF4D4D",
                timer: 4000,
              });
              getLeads();
            });
          }
        });
    }
  };

  const handleSubmit = (e, data, form) => {
    e.preventDefault();
    let payload = {
      ...Object.except(data, ["followUpDate", "followUpTime"]),
    };

    if (data?.status === "FOLLOW_UP") {
      // Given date and time components
      if (data.followUpDate !== "" && data.followUpTime !== "") {
        const dateComponent = data?.followUpDate;
        const timeComponent = data?.followUpTime;

        // Combine the date and time components
        const dateTimeString = `${dateComponent}T${timeComponent}`;

        // Create a new Date object
        const dateTime = new Date(dateTimeString);

        console.log(dateTime); // Output in ISO 8601 format
        payload.followUpAt = dateTime?.toISOString();
      }
    } else {
      payload.followUpAt = null;
    }
    console.log({ data, payload });

    if (editmode) {
      props.apis
        .apiUpdateLead(payload, {
          leadId: editData?.leadId,
        })
        .then((res) => {
          console.log({ apiUpdateLead: res });
          swal2.fire({
            title: "Lead updated successfully.",
            icon: "success",
            iconColor: "#FF4D4D",
            timer: 4000,
          });
          closeModal();
          getData();
        });
    } else {
      props.apis.apiAddLead(payload).then((res) => {
        console.log({ apiAddLead: res });
        swal2.fire({
          title: "Lead added successfully.",
          icon: "success",
          iconColor: "#FF4D4D",
          timer: 4000,
        });
        closeModal();
        getData();
      });
    }
  };

  // -----------------------  APPLICATION   -----------------------

  const closeApplModal = () => {
    setOpenApplCanvas(false);
  };

  const addApplModalFields = (record) => {
    console.log({ addApplModalFields: record });

    const { leadName, mobileNumber, productType } = record;
    const {
      employeeId,
      employeeName,
      loggedinmobile = mobileNumber,
    } = props?.$store?.user;

    let fields = (applModalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );

    let nameField = applModalFields.find((o) => o.name == "leadName");
    nameField.defaultValue = leadName;
    nameField.disabled = true;
    //
    let mobileField = applModalFields.find((o) => o.name == "mobileNumber");
    mobileField.defaultValue = mobileNumber;
    mobileField.disabled = true;
    //
    let productField = applModalFields.find((o) => o.name == "productType");
    productField.defaultValue = productType;
    //
    let assignedByField = applModalFields.find(
      (o) => o.name == "assignedPersonId"
    );
    assignedByField.defaultValue = employeeName;
    assignedByField.disabled = true;
    //
    let teleMobileField = applModalFields.find(
      (o) => o.name == "tele_mobile_number"
    );
    teleMobileField.defaultValue = loggedinmobile;
    teleMobileField.disabled = true;

    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    setPopupApplFields(fields);
    setPopupApplData(formdata);
    setOpenApplCanvas(true);
    setEditApplData(record);
    // if need to show logs data
    // setApplLogsData(record?.LeadUpdateLogs);
  };

  const onApplModalClose = () => {
    console.log("onModalClose");
    setPopupApplFields({});
    setViewApplMode(false);
    setEditApplMode(false);
    setEditApplData({});
    closeApplModal();
  };

  const handleApplSubmit = (e, data, form) => {
    e.preventDefault();
    console.log("formdata: ", data);
    let formdata = data;
    let payload = {
      ...formdata,
    };
    const { leadId, leadName, mobileNumber } = editApplData;
    payload.leadId = leadId;
    payload.leadName = leadName;
    payload.mobileNumber = mobileNumber;
    payload.loanAmount = Number(formdata.loanAmount);
    payload.applicationDate = new Date(formdata.applicationDate).toISOString();
    payload.assignedPersonId = props?.$store?.user?.employeeId;
    payload.source = loggedUserRole;

    console.log({ handleApplSubmit: payload });

    // props.apis.apiAddApplication(payload).then((res) => {
    //   console.log({ apiAddApplication: res });
    //   swal2.fire({
    //     title: "Application submitted successfully",
    //     icon: "success",
    //     iconColor: "#FF4D4D",
    //     timer: 4000,
    //   });
    //   closeApplModal();
    //   props.navigate("/app/applications");
    // });
  };

  const onSearch = (data, reset) => {
    console.log("onSearch on leads", data, reset);
    if (Object.keys(data).length === 0) {
      setSearch({
        ...data,
        ...search,
      });
      getLeads();
    } else {
      setSearch({
        ...data,
        ...search,
      });
      getLeads(data, reset);
    }
  };

  const addModalFields = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setLogsData([]);
    let filteredFields;

    // if (loggedUserRole === _enum.PERSONAS.ops) {
    //   filteredFields = opsmodalFields;
    //   console.log({ filteredFields });
    // }

    let statusfield = modalFields.find((o) => o.name == "status");
    console.log({ loggedUserRole });
    switch (loggedUserRole) {
      // case _enum.PERSONAS.ops:
      //   // // label changes
      //   // namefield.label = "Customer Name";
      //   // // field changes
      //   // filteredFields = modalFields.exclude(
      //   //   ["emailAddress", "loanAmount"],
      //   //   "name"
      //   // );
      //   filteredFields = opsmodalFields;
      //   break;

      case _enum.PERSONAS.admin:
        // field changes
        filteredFields = modalFields.filter(
          (v) => v.name !== "followUpDate" && v.name !== "followUpTime"
        );
        break;

      case _enum.PERSONAS.sales:
        // label changes
        statusfield.label = "Lead Status";
        // field changes
        filteredFields = modalFields.exclude(["loanAmount"], "name");
        break;

      default:
        filteredFields = modalFields;
        statusfield.label = "Status";
        break;
    }

    let fields = (filteredFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    console.log("formdata: ", fields, formdata);

    // let { productType } = props?.$store?.user;
    // let productTypeField = filteredFields.find((o) => o.name == "productType");
    // productTypeField.defaultValue = productType;

    setModalFields([...filteredFields]);
    setPopupfields(fields);
    setPopupdata(formdata);
    setOpenCanvas(true);
  };

  // HANDLE BULK DOWNLOAD

  // HANDLE BULK UPLOAD
  const openBulkUploadmodal = () => {
    bulkUploadModalRef.current.click();
  };

  const closeBulkUploadmodal = () => {
    document.querySelector("#bulkUploadModal #bsmodalclose").click();
    setPopupBulkUploadData({});
    setPopupBulkUploadFields({});
  };

  const addBulkUploadModalFields = () => {
    let fields = (bulkUploadModalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    setPopupBulkUploadFields(fields);
    setPopupBulkUploadData(formdata);
    openBulkUploadmodal();
  };

  const handleBulkUploadSubmit = (e, data, form) => {
    console.log({ handleBulkUploadSubmit: data });
    let payload = {
      file: data?.file,
    };
    console.log({ payload });

    props.apis.apiBulkUploadLead(payload).then((res) => {
      console.log({ apiBulkUploadLead: res });
      if (res) {
        swal2.fire({
          title: "Bulk Uploaded Successfully.",
          text: `Records Uploaded : ${res?.count || 0}`,
          icon: "success",
          iconColor: "#FF4D4D",
          timer: 4000,
        });
        closeBulkUploadmodal();
      }
    });
  };

  // HANDLE CHANGE PASSWORD MODAL
  const openChangePasswordmodal = () => {
    changePasswordModalRef.current.click();
  };

  const closeChangePasswordmodal = () => {
    document.querySelector("#changepasswordModal #bsmodalclose").click();
    setChangepasswordData({});
    setPopupChangePasswordFields({});
  };

  const addChangepasswordData = () => {
    let fields = (changepasswordFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    setPopupChangePasswordFields(fields);
    setChangepasswordData(formdata);
    openChangePasswordmodal();
  };

  const handleChangePasswordSubmit = (e, data) => {
    console.log({ handleChangePasswordSubmit: data });
    if (data.newPassword !== data.confirmPassword) {
      swal2.fire({
        title: "Password and Confirm Password does not match",
        icon: "warning",
        iconColor: "#FF4D4D",
        timer: 4000,
      });
    } else {
      let payload = {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      };

      props.apis.apiPasswordReset(payload).then((res) => {
        console.log({ apiPasswordReset: res });
        if (res.status === 200) {
          swal2
            .fire({
              title:
                "Password changed successfully, Please re-login with new password",
              icon: "success",
              iconColor: "#FF4D4D",
            })
            .then((result) => {
              if (result.isConfirmed) {
                closeChangePasswordmodal();
                props?.apis?.applogout();
              }
            });
        }
      });
    }
  };

  const downloadTemplateLeads = (e) => {
    return props.apis.apiDownloadLeadsTemplate();
  };

  const downloadBulkLeads = (values) => {
    let leadIds = tableData?.map((v) => JSON.parse(v.leadId));
    let payload = {
      leads: [...selectedRowKeys],
    };
    console.log({ downloadBulkLeads: payload });

    props.apis.apiBulkDownloadLead(payload).then((res) => {
      console.log({ res });
      // if (res) {
      //   let url = `${props.apis.api().api_base}/api/v1/leads/bulk-download`;
      //   var downloadURL = url;
      //   var link = document.createElement("a");
      //   link.setAttribute(
      //     "download",
      //     `leads_${new Date().toLocaleDateString()}.xlsx`
      //   );
      //   link.href = downloadURL;
      //   link.target = "_blank";
      //   link.click();
      // }
    });
  };

  const onModalClose = () => {
    console.log("onModalClose");
    closeModal();
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});
  };

  const checkLoggedIn = () => {
    let loggedInUser = props?.$store?.user?.role;
    console.log({ loggedInUser });
    switch (loggedInUser) {
      case _enum.PERSONAS.admin:
        setIsadmin(true);
        setIstelecaller(false);
        setIsOps(false);
        break;
      case _enum.PERSONAS.sales:
        setIsadmin(false);
        setIstelecaller(true);
        setIsOps(false);
        break;
      case _enum.PERSONAS.ops:
        setIsadmin(false);
        setIstelecaller(false);
        setIsOps(true);
        break;
      default:
        setIsadmin(false);
        setIstelecaller(false);
        setIsOps(false);
        break;
    }
  };

  useEffect(() => {
    console.log({ isadmin, istelecaller, isOps });
  }, [isadmin, istelecaller, isOps]);

  useEffect(() => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    // console.log({ updatedfields: fields });
    setPopupfields(fields);
  }, [editData]);

  useEffect(() => {
    checkLoggedIn();

    const { emailAddress } = props?.$store?.logged;
    const { isPasswordUpdated, role } = props?.$store?.user;
    setLoggedUserRole(role);
    console.log({ emailAddress });
    if (
      !isPasswordUpdated &&
      role !== _enum.PERSONAS.admin &&
      emailAddress !== undefined
    ) {
      addChangepasswordData();
    }

    // ----------------------------------------------------------------
    // Altering table columns based on logged-in user role
    console.log("APPLICATIONS: ", role);

    let allowedColumns = {
      admin: [
        "leadName",
        "mobileNumber",
        "productType",
        "location",
        "createdAt",
        "source",
        "status",
        "assignedEmployee",
      ],
      sales: [
        "leadName",
        "mobileNumber",
        "location",
        "productType",
        "source",
        "status",
        "followUpAt",
        "remarks",
      ],
      ops: ["leadName", "mobileNumber", "productType", "assignedBy", "status"],
    };
    switch (role) {
      case _enum.PERSONAS.admin:
        updateColumns(allowedColumns.admin, { status: "Status" });
        break;
      case _enum.PERSONAS.sales:
        updateColumns(allowedColumns.sales, {
          status: "Lead Status",
        });
        break;
      case _enum.PERSONAS.ops:
        updateColumns(allowedColumns.ops, {
          leadName: "Customer Name",
          status: "Opps Status",
        });
        break;

      default:
        updateColumns(allowedColumns.sales, { status: "Status" });
        break;
    }

    // PERSIST ACTIVE TAB ON PAGE RE-LOAD
    let paramsActiveTab = props.urlparams.params.activetab;
    if (isAdmin) {
      if (paramsActiveTab) {
        setActiveKey(paramsActiveTab);
      } else {
        setActiveKey(_enum.PERSONAS.admin);
        navigate(`/app/leads?activetab=${_enum.PERSONAS.admin}`);
      }
    } else if (isSales) {
      setActiveKey(_enum.PERSONAS.sales);
      navigate(`/app/leads?activetab=${_enum.PERSONAS.sales}`);
    } else if (isOperations) {
      setActiveKey(_enum.PERSONAS.ops);
      navigate(`/app/leads?activetab=${_enum.PERSONAS.ops}`);
    }

    // scroll page to top
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let payload = {
      ...search,
    };
    getData(payload);
  }, [search]);

  // Function to find and update titles based on an object mapping IDs to titles
  function updateColumns(col, idMap) {
    // 1.filter out the columns
    let targetId = "dataIndex";
    let filteredColumns = columns.only(col, targetId);

    // 2.change columns title
    const updatedArray = filteredColumns.map((obj) => {
      if (idMap.hasOwnProperty(obj[targetId])) {
        return { ...obj, title: idMap[obj[targetId]] };
      }
      return obj;
    });
    setColumns(updatedArray);
    return updatedArray;
  }

  const filterTabData = [
    {
      key: _enum.PERSONAS.admin,
      name: "New Leads",
    },
    {
      key: _enum.PERSONAS.sales,
      name: "Assigned to tele callers",
    },
    {
      key: _enum.PERSONAS.ops,
      name: "Assigned to Ops Team",
    },
  ];

  const onFilterSearch = () => {};

  const handleSelect = (e, key) => {
    setActiveKey(key);
    navigate(`/app/leads?activetab=${key}`);
  };

  const getData = (params) => {
    // clear table data from previous request
    setTableData([]);

    let payload = {
      ...params,
    };

    const str = window.location.search;
    const pageParams = Object.fromEntries(new URLSearchParams(str));
    console.log(pageParams);

    if (pageParams.hasOwnProperty("source")) {
      payload.source = pageParams?.source;
    } else {
      delete payload.source;
    }

    console.log({ payload, search });
    if (_enum.PERSONAS.admin) {
      if (activeKey === _enum.PERSONAS.admin) {
        payload.assignedRole = _enum.PERSONAS.admin;
        getLeads(payload);
      } else if (activeKey === _enum.PERSONAS.sales) {
        payload.assignedRole = _enum.PERSONAS.sales;
        getLeads(payload);
      } else if (activeKey === _enum.PERSONAS.ops) {
        payload.assignedRole = _enum.PERSONAS.ops;
        getLeads(payload);
      }
    } else {
      getLeads(payload);
    }
  };

  useEffect(() => {
    getData();
    let assignedRoleInput = document.getElementById("assignedRoleInput");
    if (isAdmin && assignedRoleInput) {
      if (activeKey !== _enum.PERSONAS.admin) {
        assignedRoleInput?.classList.add("hidden");
      } else {
        assignedRoleInput?.classList.remove("hidden");
      }
    }
  }, [activeKey]);

  const downloadExcel = async () => {
    let payload = {
      assignedRole: activeKey,
    };
    props.apis.apiExportLeadsDetails(payload).then(async (res) => {
      if (res) {
        let data = await res;
        let blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute(
          "download",
          `${activeKey?.toLowerCase()}_leads_${new Date().toDate()}.xlsx`
        );
        link.click();
      }
    });
  };

  const deleteBulkLeads = async () => {
    let payload = {
      leadIds: [...selectedRowKeys],
    };
    props.apis.apiDeleteBulkLeads(payload).then(async (res) => {
      if (res) {
        swal2.fire({
          title: `${res?.message || "Leads deleted successfully"}`,
          icon: "success",
          iconColor: "#FF4D4D",
          showCancelButton: false,
          timer: 3000,
        });
        setSelectedRowKeys([]);
        getData();
      }
    });
  };

  const [leadsData, setLeadsData] = useState({});

  useEffect(() => {
    if (isSales) {
      // GET TOTAL LEADS DATA
      props.apis.apiGetTotalLeadsData().then((res) => {
        setLeadsData((prevState) => ({
          ...prevState,
          totalLeads: res?.totalLeadCount,
        }));
      });

      // GET PENDING LEADS DATA
      props.apis.apiGetPendingLeadsData().then((res) => {
        setLeadsData((prevState) => ({
          ...prevState,
          pendingLeads: res?.totalOpenLeadCount,
        }));
      });

      // GET COMPLETED LEADS DATA
      props.apis.apiGetCompletedLeadsData().then((res) => {
        setLeadsData((prevState) => ({
          ...prevState,
          completedLeads: res?.totalCompletedLeadCount,
        }));
      });

      // GET INTERESTED LEADS DATA
      props.apis.apiGetInterestedLeadsData().then((res) => {
        setLeadsData((prevState) => ({
          ...prevState,
          interestedLeads: res?.totalInterestedLeadCount,
        }));
      });
    }
    getRoles();
    getTelecallers();
  }, []);

  // ----------------------------------------------------------------
  const [show, setShow] = useState(false);
  const [assignedId, setAssignedId] = useState(null);
  const [assignedToFields, setAssignedToFields] = useState([
    {
      name: "profileId",
      label: "Assign To",
      type: "select",
      options: [],
    },
  ]);

  const handleShow = (e) => {
    setShow(true);
  };

  const handleClose = (e) => {
    setShow(false);
  };

  const handleReAssignedTo = () => {
    handleShow();
  };

  const handleReAssignedToSubmit = (e, data) => {
    let payload = {
      profileId: data?.profileId,
      leadIds: [...selectedRowKeys],
    };

    console.log({ data, payload });

    props.apis.apiAdminReassignLeads(payload).then((res) => {
      if (res) {
        swal2.fire({
          title: "Loan Re-Assigned To Underwriter Successfully",
          icon: "success",
          iconColor: "#FF4D4D",
          showCancelButton: false,
          timer: 3000,
        });
        setSelectedRowKeys([]);
        handleClose();
        getData();
      }
    });
  };

  const getTelecallers = () => {
    let payload = {
      role: _enum.PERSONAS.sales,
    };
    props.apis.apiGetEmployee(payload).then(({ data }) => {
      let salesField = assignedToFields.find((o) => o.name == "profileId");
      salesField.options = data.options("profileId", "name");
      setAssignedToFields([...assignedToFields]);
    });
  };

  props.$setNode({
    props,
    search,
    rowcount,
  });
  return (
    <>
      <MainPagetitle
        mainTitle="Leads"
        pageTitle={"Leads"}
        parentTitle={"Home"}
        pageIcon={imagepaths.SingleUserIconActive}
      />
      <div className="container-fluid">
        {isadmin && (
          <div className="filter_tab_container">
            {filterTabData?.map((btn) => {
              return (
                <>
                  <button
                    type="button"
                    id={btn.key}
                    className={Object.className({
                      active: activeKey === btn.key,
                    })}
                    onClick={(...args) => handleSelect(...args, btn.key)}
                  >
                    {btn.name}
                  </button>
                </>
              );
            })}
          </div>
        )}

        <div
          className={Object.className({
            " mx-auto": true,
            hidden: isadmin || isSalesLeads,
          })}
        >
          <div className="metrics_card_container mb-4">
            <div className="leads_detail_select_box">
              <AppForm
                ref={infoForm}
                onSubmit={(...args) => onFilterSearch(...args)}
                className="row justify-content-end"
              >
                <div
                  className="col-12 col-md-3 col-lg-3 col-xl-2"
                  id="column-timeline"
                >
                  <AppInputFocus
                    type="select"
                    options={_enum.OVERALL_DETAIL}
                    name="leads_detail"
                    placeholder="Select"
                  ></AppInputFocus>
                </div>
              </AppForm>
            </div>
            <div className="row g-4 metrics_card_row">
              <div className="col-12 col-md-6 col-lg-6 col-xl-3 metrics_card">
                <div className="col_left">
                  <img src={imagepaths.userIconLight} alt="card icon" />
                </div>
                <div className="col_right">
                  <h5 className="card-title">Total Leads</h5>
                  <div className="bottom_content">
                    <p className="card-text">
                      No. Of Leads :
                      <span className="ms-2">
                        {leadsData?.totalLeads || "-"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 col-xl-3 metrics_card">
                <div className="col_left">
                  <img src={imagepaths.userInfoRedIcon} alt="card icon" />
                </div>
                <div className="col_right">
                  <h5 className="card-title">Pending Leads</h5>
                  <div className="bottom_content">
                    <p className="card-text">
                      No. Of Leads :
                      <span className="ms-2">
                        {leadsData?.pendingLeads || "-"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 col-xl-3 metrics_card">
                <div className="col_left">
                  <img src={imagepaths.userCheckIcon} alt="card icon" />
                </div>
                <div className="col_right">
                  <h5 className="card-title">Completed Leads</h5>
                  <div className="bottom_content">
                    <p className="card-text">
                      No. Of Leads :
                      <span className="ms-2">
                        {leadsData?.completedLeads || "-"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 col-xl-3 metrics_card">
                <div className="col_left">
                  <img src={imagepaths.userCheckIcon} alt="card icon" />
                </div>
                <div className="col_right">
                  <h5 className="card-title">Interested Leads</h5>
                  <div className="bottom_content">
                    <p className="card-text">
                      No. Of Leads :
                      <span className="ms-2">
                        {leadsData?.interestedLeads || "-"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card border-0">
              <div className="card-body p-0">
                <div className="tbl-caption-container">
                  {!isadmin && <h4 className="heading mb-0">Leads</h4>}
                  <LeadsSearch onSearch={(...arg) => onSearch(...arg)} />
                  <div
                    className={Object.className({
                      header_box: true,
                      hidden: isOps,
                    })}
                  >
                    <div style={{ width: "130px" }} id="assignedRoleInput">
                      <AppInput
                        type="select"
                        name="assignedRole"
                        options={roles?.options("role") || []}
                        className="select-focus m-0 custom_pill_select"
                        outerclass="mb-0"
                        onChange={(...args) => handleAssignDept(...args)}
                        placeholder="Assign To"
                        disabled={notSelected}
                      ></AppInput>
                    </div>
                    <div>
                      <Link
                        to={"#"}
                        className={Object.className({
                          "btn btn-primary ms-3": true,
                        })}
                        onClick={(...args) => addModalFields(...args)}
                      >
                        + Add Lead
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className={Object.className({
                    "header_btn_box my-3": true,
                    hidden: !isadmin || activeKey !== _enum.PERSONAS.admin,
                  })}
                >
                  <a
                    className="btn btn-sm btn-outline-secondary me-2"
                    href={downloadTemplateLeads()}
                  >
                    <i className="fa fa-download me-2"></i>
                    <span>Download Template</span>
                  </a>
                  <button
                    className="btn btn-sm btn-outline-secondary me-2"
                    onClick={(e) => addBulkUploadModalFields(e)}
                    ref={bulkUploadModalRef}
                    data-bs-toggle="modal"
                    data-bs-target="#bulkUploadModal"
                  >
                    <i className="fa fa-upload me-2"></i>
                    <span>Bulk Upload</span>
                  </button>
                  <a
                    className="btn btn-sm btn-outline-secondary me-2"
                    onClick={(...args) => downloadBulkLeads(...args)}
                    disabled={disableBulkDownload}
                  >
                    <i className="fa fa-download me-2"></i>
                    <span>Bulk Download</span>
                  </a>
                </div>
                {isAdmin && (
                  <div className="action_btn_group text-end">
                    <button
                      type="button"
                      className="btn btn-primary-transparent file_download_btn text-primary"
                      onClick={(...args) => deleteBulkLeads(...args)}
                      disabled={notSelected}
                    >
                      <i className="fa fa-trash me-2"></i>
                      Delete Multiple Leads
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary-transparent file_download_btn text-primary"
                      onClick={(...args) => downloadExcel(...args)}
                    >
                      <i className="fa fa-download me-2"></i>
                      Download Excel
                    </button>
                  </div>
                )}
                <AppTable
                  data={tableData}
                  columns={columns}
                  onChange={(...arg) => onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  {...(istelecaller
                    ? {
                        viewAction: (e) => viewLead(e),
                        editAction: (e) => editLead(e),
                        deletable: false,
                        rowSelection: rowSelection,
                      }
                    : isOps
                    ? {
                        viewable: false,
                        editable: false,
                        deletable: false,
                        applyLink: true,
                        applyLinkAction: (...args) =>
                          addApplModalFields(...args),
                      }
                    : {
                        editAction: (e) => editLead(e),
                        viewAction: (e) => viewLead(e),
                        deleteAction: (e) => deleteLead(e),
                        rowSelection: rowSelection,
                      })}
                  checkbox={true}
                  loading={isFetching}
                ></AppTable>
                <div className="col-12 text-end py-4">
                  <button
                    className={Object.className({
                      "btn btn-primary m-3": true,
                      disabled: selectedRowKeys.length == 0,
                      hidden: activeKey !== _enum.PERSONAS.sales,
                    })}
                    onClick={(e) => handleReAssignedTo()}
                  >
                    Re-Assign Leads
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={Object.className({
            row: true,
            hidden: activeKey !== _enum.PERSONAS.admin,
          })}
        >
          <div className="col-12 text-end">
            <button
              className={Object.className({
                "btn btn-primary me-5": true,
                hidden: isOps,
              })}
              disabled={notSelected}
              onClick={(e) => handleSubmitAssignDept(e)}
            >
              Submit
            </button>
          </div>
        </div>

        {/* below button hidden on UI */}
        <button
          className="btn btn-sm btn-outline-secondary d-none"
          onClick={(e) => addChangepasswordData(e)}
          ref={changePasswordModalRef}
          data-bs-toggle="modal"
          data-bs-target="#changepasswordModal"
        >
          <span>Change Password</span>
        </button>
      </div>

      <LeadsCenteredModal
        id="bulkUploadModal"
        className="modal-lg"
        modalSize="default"
        title="Bulk Upload"
        submitText="Upload"
        data={popupBulkUploadData}
        fields={popupBulkUploadFields}
        onSubmit={(...args) => handleBulkUploadSubmit(...args)}
        modaltype="centeredmodal"
        onClose={() => closeBulkUploadmodal()}
      />

      <LeadsCenteredModal
        id="changepasswordModal"
        title="Change Password"
        submitText="Chage Password"
        hidecancelbtn={true}
        data={changepasswordData}
        fields={popupChangePasswordFields}
        onSubmit={(...args) => handleChangePasswordSubmit(...args)}
        modaltype="centeredmodal"
        onClose={() => closeChangePasswordmodal()}
      />

      <LeadsModal
        id="leadsCanvas"
        title={`${
          editmode
            ? "Edit Lead"
            : viewmode
            ? "View Lead"
            : loggedUserRole === _enum.PERSONAS.ops
            ? "Create Application"
            : "Add Lead"
        }`}
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        editdata={editData}
        fields={popupfields}
        editmode={editmode}
        logsData={logsData}
        logsColumns={logsColumns}
        noValidate={true}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={`${editmode ? "Update" : "Add"}`}
        disableallfields={viewmode ? true : false}
        errorMessage={errorMessage}
        {...(loggedUserRole === _enum.PERSONAS.sales
          ? {
              disablefields: [
                "emailAddress",
                "leadName",
                "mobileNumber",
                "productType",
                "source",
              ],
            }
          : {})}
      />

      <ApplicationModal
        id="applicationCanvas"
        title={`${
          editApplMode
            ? "Edit Application"
            : viewApplMode
            ? "View Application"
            : "Add Application"
        }`}
        show={openApplCanvas}
        onClose={() => onApplModalClose()}
        data={popupApplData}
        editdata={editApplData}
        fields={popupApplFields}
        editmode={editApplMode}
        logsData={applLogsData}
        logsColumns={applLogsColumns}
        showLogsTable={viewApplMode}
        onSubmit={(...args) => handleApplSubmit(...args)}
        submitText={`${editApplMode ? "Update" : "Add"}`}
        disableallfields={viewApplMode ? true : false}
      />
      {/* ASSIGNED TO MODAL */}
      <Modal
        show={show}
        onHide={(...args) => handleClose(...args)}
        className="customer_application_modal br-normal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Re-Assign Leads</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <AppForm onSubmit={handleReAssignedToSubmit}>
            {assignedToFields?.map((field, i) => (
              <AppInput {...field}></AppInput>
            ))}
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary-lite me-3"
                onClick={(...args) => handleClose(...args)}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Assign
              </button>
            </Modal.Footer>
          </AppForm>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default constore(Leads);

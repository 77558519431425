import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import MainPagetitle from "../../../layouts/MainPagetitle";
import EmployeeModal from "./EmployeeModal";
import EmployeeSearch from "./EmployeeSearch/EmployeeSearch";
import RepaymentModal from "../LoanDetails/Repayments/RepaymentModal";

const Employees = (props) => {
  const isAdmin = props?.$store?.persona === _enum.ROUTE_PERSONAS.admin;
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [modalFields, setModalFields] = useState([
    {
      label: "Employee Name",
      name: "name",
      type: "text",
      placeholder: "Please Enter",
    },
    {
      label: "Role",
      name: "role",
      type: "select",
      options: [],
    },
    {
      label: "Email ID",
      name: "emailAddress",
      type: "email",
      placeholder: "Please Enter",
      onChange: (value) => handleEmailId(value),
    },
    {
      label: "Mobile No.",
      name: "mobileNumber",
      type: "text",
      // defaultValue: "9876545432",
      placeholder: "Please Enter",
      onChange: (value) => handleMobileNo(value),
    },
    {
      label: "Work Location",
      name: "workLocation",
      type: "select",
      options: [],
      onChange: (value) => {
        setLocation(value);
      },
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "State",
      name: "state",
      type: "text",
      readOnly: true,
      // options: Object.keys(statecity).options(),
      // onChange: (value) => {
      //   getCities(value);
      // },
      placeholder: "Please Enter",
    },
    {
      label: "City",
      name: "city",
      type: "text",
      readOnly: true,
      // options: [],
      placeholder: "Please Enter",
    },
    {
      label: "Reporting Manager",
      name: "reportingPerson",
      type: "select",
      options: [],
      placeholder: "Please Enter",
    },
    {
      label: "Joining Date",
      name: "joiningDate",
      type: "date",
      defaultValue: new Date(),
    },
    {
      label: "Status",
      name: "status",
      type: "select",
      options: _enum.STATUS,
      placeholder: "Please Enter",
    },
  ]);
  const [editData, setEditData] = useState([]);
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});

  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [locations, setLocations] = useState([]);
  const [productType, setProductType] = useState([]);
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });

  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "name",
      title: "Name",
      sorter: (a, b) => a.name.length - b.name.length,
      render: (text, record) => {
        return (
          <div className="products">
            <div>
              <h6 className="text-primary">{record.profileId}</h6>
              <span>{record.name}</span>
            </div>
          </div>
        );
      },
    },
    {
      dataIndex: "mobileNumber",
      title: "Mobile No.",
    },
    {
      dataIndex: "emailAddress",
      title: "Email ID",
    },
    {
      dataIndex: "joiningDate",
      title: "Joining Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        if (record?.joiningDate) {
          return date;
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "role",
      title: "Role",
      render: (text, record) => {
        return <>{text?.toTitleCase()}</>;
      },
    },
    {
      dataIndex: "city",
      title: "Location",
      render: (text, record) => {
        return <>{record?.city || "-"}</>;
      },
    },
    {
      dataIndex: "status",
      title: "Status",
      render: (text, record) => {
        return (
          <span
            className={`badge light border-0 ${
              text.toLowerCase() === "active" ? "badge-success" : "badge-danger"
            } `}
          >
            {text?.toTitleCase()}
          </span>
        );
      },
    },
  ];

  const onPageChange = (pageData) => {
    console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 10,
    });
  };

  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  // const getCities = (value) => {
  //   // console.log({ getCities: value });
  //   let city = modalFields.find((o) => o.name == "city");
  //   city.options = Object.assign(
  //     city.options,
  //     statecity[value[0].key].options()
  //   );
  //   setModalFields([...modalFields]);
  // };

  const setLocation = (record) => {
    console.log({ setLocation: record[0] });
    let statefield = modalFields.find((o) => o.name == "state");
    statefield.defaultValue = record[0]?.state;

    let cityfield = modalFields.find((o) => o.name == "city");
    cityfield.defaultValue = record[0]?.city;

    // if (record[0] === undefined) {
    //   console.log("reset cityfield");
    //   // statefield.defaultValue = "";
    //   // cityfield.defaultValue = "";
    //   let stateinput = document.querySelector(
    //     "[name='appinput-state'].form-group input"
    //   );
    //   let cityinput = document.querySelector(
    //     "[name='appinput-city'].form-group input"
    //   );
    //   stateinput.value = "";
    //   cityinput.value = "";
    // }

    setModalFields([...modalFields]);
  };

  const getRoles = () => {
    props.apis.apiGetMasterRoles().then((res) => {
      // console.log({ roles: res });
      let role = modalFields.find((o) => o.name == "role");
      role.options = res.options("role");
      setModalFields([...modalFields]);
      setRoles(res);
      //
      // let role = modalFields.find((o) => o.name == "role");
      // let roles = res.filter((v) => v.role !== "ADMIN");
      // role.options = roles.options("role");
      // setRoles(res);
    });
  };

  const getDepartments = () => {
    props.apis.apiGetMasterDepartments().then((res) => {
      // console.log({ department: res, modalFields });
      let department = modalFields.find((o) => o.name == "department");
      let reportingDepartment = modalFields.find(
        (o) => o.name == "reportingDepartment"
      );
      department.options = res.options("department");
      reportingDepartment.options = res.options("department");
      setModalFields([...modalFields]);
      setDepartments(res);
    });
  };

  const getDesignations = () => {
    props.apis.apiGetMasterDesignations().then((res) => {
      let designation = modalFields.find((o) => o.name == "designation");
      let reportingDesignation = modalFields.find(
        (o) => o.name == "reportingDesignation"
      );
      designation.options = res.options("designation");
      reportingDesignation.options = res.options("designation");
      setModalFields([...modalFields]);
      setDesignations(res);
    });
  };

  const getLocations = () => {
    let payload = {
      ...search,
    };
    // props.apis.apiGetMasterLocations(payload).then(({ data }) => {
    //   // console.log({ apiGetMasterLocations: data });
    //   let location = modalFields.find((o) => o.name == "locationId");
    //   location.options = data.options("locationId", "locationName");
    //   setLocations(data);
    //   setOpenCanvas(false);
    // });
    let data = [
      // {
      //   locationId: 1,
      //   locationName: "Hyderabad",
      //   city: "Hyderabad",
      //   state: "Telangana",
      //   latitude: "17.366",
      //   longitude: "78.476",
      //   status: "active",
      // },
      {
        locationId: 2,
        locationName: "Delhi",
        city: "Delhi",
        state: "Union Territory of Delhi",
        latitude: "28.7",
        longitude: "77.1",
        status: "active",
      },
    ];
    let location = modalFields.find((o) => o.name == "workLocation");
    location.options = data.options("locationName");
  };

  const getProductTypes = () => {
    props.apis.apiGetProductTypes().then((res) => {
      let productType = modalFields.find((o) => o.name == "productType");
      productType.options = res.options("productType");
      setModalFields([...modalFields]);
      setProductType(res);
    });
  };

  const getEmployees = (params = {}, reset) => {
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, ["page", "limit"]);
      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
        ...params,
      };
    } else {
      payload = {
        ...search,
        ...params,
      };
    }
    props.apis.apiGetEmployee(payload).then(({ data, total }) => {
      console.log({ apiGetEmployee: data, total });
      data = data?.map((obj) => {
        let newObj = { ...obj };
        if (obj?.joiningDate) {
          newObj.joiningDate = new Date(obj.joiningDate).toDate();
        } else {
          newObj.joiningDate = null;
        }
        return newObj;
      });
      let reportingPersons = modalFields.find(
        (o) => o.name == "reportingPerson"
      );
      reportingPersons.options = data.options("name");
      // console.log({ data });
      setTableData(data);
      setIsFetching(false);
      setRowcount(total);
    });
  };

  const onSearch = (data, reset) => {
    console.log("onSearch on leads", data, reset);
    if (Object.keys(data).length > 0) {
      let defaultSearchData = Object.only(data, ["limit", "page"]);
      let payload;
      if (reset) {
        payload = {
          ...defaultSearchData,
        };
      } else {
        payload = {
          ...data,
          page: 1,
          limit: 10,
        };
      }
      console.log({ payload });
      setSearch(payload);
    }
  };

  const deleteEmployee = (record) => {
    console.log({ deleteEmployee: record });
    if (record.department === "Adminstration") {
      swal2.fire({
        title: "Administrative accounts cannot be deleted",
        icon: "error",
        iconColor: "#FF4D4D",
        showCancelButton: false,
        timer: 3000,
      });
    } else {
      swal2
        .fire({
          title: "Are you sure you want to delete this employee?",
          icon: "warning",
          iconColor: "#FF4D4D",
          showCancelButton: true,
          confirmButtonColor: "#FF4D4D",
          cancelButtonColor: "##D6DBE7",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            props.apis
              .apiDeleteEmployee({ profileId: record.profileId })
              .then((res) => {
                console.log({ apiDeleteEmployee: res });
                swal2.fire({
                  title: "Employee Deleted",
                  text: "Employee deleted successfully !!",
                  icon: "success",
                  iconColor: "#FF4D4D",
                  timer: 4000,
                });
                getEmployees();
              });
          }
        });
    }
  };

  const viewEmployee = (record) => {
    setEditmode(false);
    setViewmode(true);
    // GET EMPLOYEE BY ID
    props.apis
      .apiGetEmployeeById({ profileId: record.profileId })
      .then((data) => {
        console.log({ apiGetEmployeeById: data });
        let filteredData = Object.only(
          data,
          modalFields.map((v) => v.name)
        );
        console.log({ filteredData });
        setPopupdata(filteredData);
        setEditData(data);
        openModal();
      });
  };

  const editEmployee = (record) => {
    console.log({ record });
    setEditmode(true);
    setViewmode(false);
    // GET EMPLOYEE BY ID
    props.apis
      .apiGetEmployeeById({ profileId: record.profileId })
      .then((data) => {
        console.log({ apiGetEmployeeById: data });
        let filteredData = Object.only(
          data,
          modalFields.map((v) => v.name)
        );
        console.log({ filteredData });
        setPopupdata(filteredData);
        setEditData(data);
        let nameField = modalFields.find((o) => o.name == "name");
        nameField.disabled = true;
        setModalFields([...modalFields]);
        openModal();
      });
  };

  const handleMobileNo = (e) => {
    const numberReg = _enum?.REGEX?.number;
    const fieldValue = e.target.value;
    if (numberReg.test(fieldValue) && fieldValue.length === 10)
      setErrorMessage("");
    else
      setErrorMessage({
        name: "mobileNumber",
        message: "Please enter a valid Mobile Number",
      });
  };

  const handleEmailId = (e) => {
    const emailReg = _enum?.REGEX?.email;
    const fieldValue = e.target.value;
    if (emailReg.test(fieldValue)) setErrorMessage("");
    else
      setErrorMessage({
        name: "emailAddress",
        message: "Please enter a valid Email Address",
      });
  };

  const handleSubmit = (e, data, form) => {
    e.preventDefault();
    console.log("formdata: ", data);
    let formdata = data;
    let payload = {
      ...Object.except(formdata, [
        "reportingDepartment",
        "reportingDesignation",
        "reportingPerson",
        "workLocation",
        // "state",
        // "city",
        "address",
        "picture",
      ]),
    };
    if (formdata.joiningDate) {
      payload.joiningDate = new Date(formdata.joiningDate).toISOString();
    }
    payload.workLocation = data?.workLocation;
    payload.supervisorId = props?.$store?.user?.profileId || "";
    console.log({ payload });

    if (editmode) {
      let updateData = {
        ...Object.except(payload, ["profileId"]),
      };
      // console.log({ updateData });
      props.apis
        .apiUpdateEmployee(updateData, {
          profileId: editData?.profileId,
        })
        .then((res) => {
          console.log({ apiUpdateEmployee: res });
          swal2.fire({
            title: "Employee updated successfully.",
            icon: "success",
            iconColor: "#FF4D4D",
            timer: 4000,
          });
          closeModal();
          getEmployees();
        })
        .catch((error) => console.log(error));
    } else {
      props.apis
        .apiAddEmployee(payload)
        .then((res) => {
          console.log({ apiAddEmployee: res });
          swal2.fire({
            title: "Employee added successfully.",
            icon: "success",
            iconColor: "#FF4D4D",
            timer: 4000,
          });
          closeModal();
          getEmployees();
        })
        .catch((error) => console.log(error));
    }
  };

  const addModalFields = (values) => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    console.log("formdata: ", fields, formdata);
    setPopupfields(fields);
    setPopupdata(formdata);
    setOpenCanvas(true);
  };

  const onModalClose = () => {
    console.log("onModalClose");
    closeModal();
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});
    setErrorMessage({ name: "", message: "" });
  };

  const downloadExcel = async () => {
    let payload = {};
    props.apis.apiExportEmployeesDetails(payload).then(async (res) => {
      if (res) {
        let data = await res;
        let blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", `employees_${new Date().toDate()}.xlsx`);
        link.click();
      }
    });
  };

  useEffect(() => {
    getEmployees();
  }, [search]);

  useEffect(() => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    setPopupfields(fields);
  }, [editData]);

  useEffect(() => {
    console.log({ Employees: props });
    getRoles();
    // getDepartments();
    // getDesignations();
    getLocations();
    // getProductTypes();
  }, []);

  props.$setNode({
    props,
    modalFields,
    viewmode,
    editmode,
  });

  return (
    <>
      <MainPagetitle
        mainTitle="Employee"
        pageTitle={"Employee"}
        parentTitle={"Home"}
        pageIcon={imagepaths.LeadsIconActive}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="tbl-caption-container">
                  <h4 className="heading mb-0">Employees</h4>
                  <EmployeeSearch
                    onSearch={(...arg) => onSearch(...arg)}
                    roles={roles}
                  />
                  <div>
                    <button
                      className="btn btn-primary ms-1"
                      onClick={(...args) => addModalFields(...args)}
                    >
                      + Add Employee
                    </button>
                  </div>
                </div>
                {isAdmin && (
                  <div className="action_btn_group text-end">
                    <button
                      type="button"
                      className="btn btn-primary-transparent file_download_btn text-primary me-5"
                      onClick={(...args) => downloadExcel(...args)}
                    >
                      <i className="fa fa-download me-2"></i>
                      Download Excel
                    </button>
                  </div>
                )}
                {/* {!!tableData.length && (
                )}
                {tableData.length == 0 && <div className="empty_layout"></div>} */}
                <AppTable
                  data={tableData}
                  columns={columns}
                  onChange={(...arg) => onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  editAction={(e) => editEmployee(e)}
                  viewAction={(e) => viewEmployee(e)}
                  deleteAction={(e) => deleteEmployee(e)}
                  loading={isFetching}
                ></AppTable>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RepaymentModal
        id="employeeCanvas"
        title={`${
          editmode
            ? "Edit Employee"
            : viewmode
            ? "View Employee"
            : "Add Employee"
        }`}
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        editdata={editData}
        fields={popupfields}
        editmode={editmode}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={`${editmode ? "Update" : "Submit"}`}
        disableallfields={viewmode ? true : false}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default constore(Employees);

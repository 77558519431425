import { Input } from "antd";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export class AppFormData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicfields: props?.dynamicfields,
      dynamicinputs: props?.dynamicinputs,
      dynamicdefaultdata: props?.dynamicdefaultdata,
      dynamicfieldid: props?.dynamicfieldid,
    };
  }
  handleAddMoreFieldBtn(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      dynamicfields: [
        ...this.state.dynamicfields,
        ...this.props?.dynamicfields,
      ],
    });
  }

  handleDeleteAddMoreField = (params, index, id) => {
    let data = [...this.state.dynamicfields];
    data.splice(index, 1);
    this.setState({
      dynamicfields: data,
    });
  };

  render() {
    let { props } = this;
    let {
      data,
      size = 2,
      fields = {},
      submitText = "SUBMIT",
      onClose,
      disableallfields = false,
      disablefields = [],
      editdata,
      customcolspan = "6",
      modaltype = "",
      errorMessage,
      hidecancelbtn,
      hasDynamicFields = false,
    } = props;
    let { dynamicfields, dynamicinputs, dynamicdefaultdata, dynamicfieldid } =
      this.state;
    let list = Object.entries(data).chunk(size);
    let getField = (label) => fields[label] || {};
    let getColClass = (c) => fields[c]?.customcolspan || "6";
    let formAttrs = Object.except(props, [
      "data",
      "fields",
      "size",
      "submitText",
    ]);
    // console.log({ fields, data, list });
    // console.log({ dynamicfields, dynamicinputs, dynamicdefaultdata });

    return (
      <AppForm {...formAttrs}>
        {list.map((row, i) => (
          <Row className="mx-0" key={i}>
            {row.map(([label, value, options], j) => (
              <Col lg={getColClass(label)} key={j}>
                <AppInput
                  name={label}
                  label={label.toTitleCase()}
                  defaultValue={value}
                  disabled={disableallfields}
                  {...(disablefields.includes(label)
                    ? // Object.keys(editdata).length > 0
                      {
                        readOnly: true,
                      }
                    : { readOnly: false })}
                  {...getField(label)}
                  errorMessage={errorMessage}
                ></AppInput>
              </Col>
            ))}
          </Row>
        ))}
        {hasDynamicFields && (
          <>
            {this.state.dynamicfields?.map((field, i) => (
              <div className="mx-0 row">
                <>
                  {this.state.dynamicinputs?.map((input) => (
                    <div className="col-12 col-md-12 col-lg-4">
                      <AppInput
                        name={`dynamicfield.${i}.${input?.name}`}
                        label={`${input?.label}`}
                        type={`${input?.type}`}
                      ></AppInput>
                    </div>
                  ))}
                  {i != 0 && (
                    <div
                      className={Object.className({
                        "col-12 col-md-12 col-lg-4 d-flex align-items-center": true,
                      })}
                    >
                      <button
                        type="button"
                        onClick={(...args) =>
                          this.handleDeleteAddMoreField(...args, i)
                        }
                        className="btn btn-primary mt-4"
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </div>
                  )}
                </>
              </div>
            ))}
            <Row className="mx-0 mt-4">
              <Col>
                <div className="action_btn_group mb-4 d-flex align-items-center justify-content-between">
                  <button
                    type="button"
                    className="btn btn-primary-transparent p-0 fs-5 border-right-0"
                    onClick={(...args) => this.handleAddMoreFieldBtn(...args)}
                  >
                    + Add More
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(...args) =>
                      props?.handleDynamicFieldSubmit(...args)
                    }
                  >
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
            {/* Default Dynamic fields */}
            <hr />
            {this.state.dynamicdefaultdata?.map((field, i) => (
              <div
                className="mx-0 row"
                id={`dynamic-form-${field[dynamicfieldid]}`}
              >
                <>
                  {this.state.dynamicinputs?.map((input) => (
                    <div className="col-12 col-md-12 col-lg-4">
                      <AppInput
                        name={`${input?.name}`}
                        label={`${input?.label}`}
                        type={`${input?.type}`}
                        defaultValue={field[input?.name]}
                      ></AppInput>
                    </div>
                  ))}
                  <div
                    className={Object.className({
                      "col-12 col-md-12 col-lg-4 d-flex align-items-center": true,
                    })}
                  >
                    <button
                      type="button"
                      onClick={(...args) =>
                        props.handleDynamicFieldUpdate(
                          ...args,
                          field[dynamicfieldid]
                        )
                      }
                      className="btn btn-primary mb-4 mb-lg-0 mt-lg-4"
                    >
                      Update
                    </button>
                  </div>
                  <hr />
                </>
              </div>
            ))}
          </>
        )}
        {modaltype === "centeredmodal" ? (
          <div className="row mt-4">
            <div
              className={Object.className({
                "col-12 mb-3 text-center d-flex align-item-center justify-content-center": true,
                hidden: disableallfields,
              })}
            >
              <Link
                to={"#"}
                onClick={onClose}
                className={Object.className({
                  "btn btn-danger light me-2": true,
                  "m-auto": disableallfields,
                  "d-none": hidecancelbtn,
                })}
              >
                Cancel
              </Link>
              <button type="submit" className="btn btn-primary">
                {submitText}
              </button>
            </div>
          </div>
        ) : (
          <div className="row mt-4">
            <div className="col-xl-6 mb-3"></div>
            <div
              className={Object.className({
                "col-xl-6 mb-3 text-end d-flex justify-content-end": true,
                hidden: disableallfields,
              })}
            >
              <Link
                to={"#"}
                onClick={onClose}
                className={Object.className({
                  "btn btn-danger light me-2": true,
                  "m-auto": disableallfields,
                  "d-none": hidecancelbtn,
                })}
              >
                Cancel
              </Link>
              <button type="submit" className="btn btn-primary">
                {submitText}
              </button>
            </div>
          </div>
        )}
      </AppForm>
    );
  }
}

export default AppFormData;

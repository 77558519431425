import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";

const IncomeDetails = (props) => {
  const applId = props.urlparams.params.id;
  const toastConfig = {
    autoClose: 5000,
    closeButton: false,
    hideProgressBar: true,
  };
  const [search, setSearch] = useState({
    // page: 1,
    // limit: 10,
  });
  const [incomeEditData, setIncomeEditData] = useState({});
  const hasEditData = incomeEditData?.length > 0 ? true : false;
  const [checkValue, setCheckValue] = useState("SALARIED");
  const [defaultCompanies, setDefaultCompanies] = useState([]);
  const [customerApplicationInfo, setCustomerApplicationInfo] = useState({});

  const [salariedFields, setSalariedFields] = useState([
    {
      name: "companyname",
      label: "Company Name",
      type: "select",
      options: [],
      searchFn: (value) => handleCompanyNameSearch(value),
      onChange: (...args) => handleCompanyChange(...args),
      placeholder: "Please enter",
      required: true,
    },
    {
      name: "newcompanyname",
      label: "Name",
      type: "text",
      placeholder: "Please enter",
      outercolclass: "d-none",
    },
    {
      name: "monthlySalary",
      label: "Monthly Salary",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "salaryMode",
      label: "Mode of Salary",
      type: "select",
      options: _enum.MODE_OF_SALARY,
      defaultValue: props?.editdata?.salaryMode || "",
      placeholder: "Please enter",
    },
    {
      name: "officialEmail",
      label: "Office Email",
      type: "email",
      placeholder: "Please enter",
    },
    {
      name: "officeCardId",
      label: "Office ID card",
      type: "file",
      fileLabel: "Upload Photo",
      foldername: "customerIncomeDocs",
      applicationId: applId,
      accept: "image/*",
    },
    {
      name: "officeAddress",
      label: "Current Office Address",
      type: "textarea",
      placeholder: "Please enter",
      // customcolspan: 12,
    },
  ]);

  const [selfEmployedFields, setSelfEmployedFields] = useState([
    {
      name: "netMonthlyIncome",
      label: "Net Monthly Income",
      type: "number",
      placeholder: "Please enter",
    },
    {
      name: "natureOfBusiness",
      label: "Nature of Business",
      type: "text",
      placeholder: "Please enter",
    },
  ]);

  const [referenceFields1, setReferenceFields1] = useState([
    {
      label: "Enter Name",
      name: "reference1name",
      type: "text",
      required: true,
    },
    {
      label: "Mobile No.",
      name: "reference1mobile",
      type: "number",
      required: true,
    },
    {
      label: "Relation",
      name: "reference1relation",
      type: "select",
      options: _enum.RELATIONS_1,
      required: true,
    },
  ]);
  const [referenceFields2, setReferenceFields2] = useState([
    {
      label: "Enter Name",
      name: "reference2name",
      type: "text",
      required: true,
    },
    {
      label: "Mobile No.",
      name: "reference2mobile",
      type: "number",
      required: true,
    },
    {
      label: "Relation",
      name: "reference2relation",
      type: "select",
      options: _enum.RELATIONS_2,
      required: true,
    },
  ]);

  const handleEmploymentType = (e) => {
    e.persist();
    let value = e.target.value;

    setCheckValue(value);
    if (value === "SELF_EMPLOYED") {
      toast.info(
        "We are Sorry! Currently we are catering to the salaried person only. Thank you for your interest",
        toastConfig
      );
    }
  };

  useEffect(() => {
    if (hasEditData) {
      // if (checkValue === "SALARIED") {
      //   let updatedFields = updateFields(salariedFields, props.editdata);
      //   setSalariedFields([...updatedFields]);
      // } else {
      //   // let updatedFields = updateFields(selfEmployedFields, props.editdata);
      //   // setSelfEmployedFields([...updatedFields]);
      // }
    }
  }, [checkValue]);

  const updateFields = (fields, data) => {
    let result = fields?.map((field) => {
      console.log(field, data[field?.name]);

      let filteredData = Object.only(
        data,
        fields.map((v) => v.name)
      );

      if (field.name === "companyname") {
        var companyNameInput = document.querySelector(
          "[name='appinput-companyname'].form-group"
        );
        var newCompanyNameInput = document.querySelector(
          "[name='newcompanyname']"
        );

        if (companyNameInput) {
          var companySelect = companyNameInput?.vnode.refs.select;
          // console.log({ companySelect });

          if (companySelect && companySelect?.state?.values?.length === 0) {
            let value = filteredData[field.name];
            let payload = {
              companyName: value,
            };
            if (value?.length >= 3) {
              props.apis.apiGetMasterCompanies(payload).then(({ data }) => {
                if (data) {
                  // console.log({ "search data": data });
                  if (companySelect) {
                    companySelect = companyNameInput.vnode.refs.select;
                    let { searchResults } = companySelect?.state || {};
                    let datawithkey = data?.options("companyName");
                    // console.log({ searchResults, datawithkey });

                    if (data.length === 0) {
                      companySelect?.setState(
                        {
                          searchResults: [{ key: "OTHERS", label: "Others" }],
                          values: [{ key: "OTHERS", label: "Others" }],
                        },
                        () => {
                          companySelect?.setState({
                            searchResults,
                            values: [{ key: "OTHERS", label: "Others" }],
                          });
                        }
                      );
                      newCompanyNameInput.value = value;
                    } else {
                      companySelect?.setState({
                        searchResults,
                        values: [
                          {
                            key: datawithkey[0].key,
                            label: datawithkey[0].label,
                          },
                        ],
                      });
                    }
                  }
                }
              });
            }
          }
        }
      }
      return {
        ...field,
        defaultValue: data[field?.name],
        // disabled: true,
      };
    });
    // console.log(result);
    return result;
  };

  const handleCompanyNameSearch = (params) => {
    // console.log(params);
    let value = params?.state?.search;
    let payload = {
      companyName: value,
    };
    if (value.length >= 3) {
      mixins.debounce(() => {
        props.apis.apiGetMasterCompanies(payload).then(({ data }) => {
          if (data) {
            let companyNameInput = document.querySelector(
              "[name='appinput-companyname'].form-group"
            );
            let companySelect = companyNameInput.vnode.refs.select;
            let { searchResults } = companySelect.state;
            let datawithkey = data?.options("companyName");
            // console.log({ searchResults, datawithkey });
            searchResults = datawithkey;

            if (searchResults.length === 0) {
              companySelect.setState({
                searchResults: [{ key: "OTHERS", label: "Others" }],
              });
            } else {
              companySelect.setState({ searchResults });
            }
          }
        });
      }, 1000);
    }
  };

  const handleCompanyChange = (value) => {
    let key = value[0]?.key;

    let newField = {
      name: "newcompanyname",
      label: "Name",
      type: "text",
      placeholder: "Please enter",
    };
    var newCompanyInputOuter = document.querySelector("#input-newcompanyname");
    if (key === "OTHERS") {
      // let newSalariedFields = [...salariedFields];
      // newSalariedFields.splice(1, 0, newField);
      // console.log({ newSalariedFields });
      // setSalariedFields([...newSalariedFields]);
      //
      newCompanyInputOuter.classList.remove("d-none");
    } else {
      // setSalariedFields([...salariedFields]);
      newCompanyInputOuter.classList.add("d-none");
    }
  };

  const getCompanies = () => {
    let payload = {
      ...search,
    };
    props.apis.apiGetMasterCompanies(payload).then(({ data }) => {
      // console.log({ data });
      if (data) {
        setDefaultCompanies(data);
        let companyNameField = salariedFields.find(
          (field) => field.name === "companyname"
        );
        companyNameField.options = data.options("companyName");
        setSalariedFields([...salariedFields]);
      }
    });
  };

  const removeEmpty = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
        delete obj[key];
      }
    });
    return obj;
  };
  const onIncomeSubmit = (e, data) => {
    console.log("Income Details: ", data);

    let incomePayload = {
      companyname: data?.companyname,
      monthlySalary: data?.monthlySalary,
      salaryMode: data?.salaryMode,
      officialEmail: data?.officialEmail,
      officeCardId: data?.officeCardId,
      officeAddress: data?.officeAddress,
      fileName: data?.fileName,
    };

    if (data?.employmentType !== "SALARIED") {
      incomePayload.isSalaried = false;
      swal2.fire({
        title: "We are Sorry! ",
        text: "Currently we are catering to the salaried person only. Thank you for your interest we will get back to you",
        icon: "warning",
        iconColor: "#DE3232",
        confirmButtonColor: "#DE3232",
        confirmButtonText: "Ok",
      });
      return;
    } else {
      incomePayload.isSalaried = true;
    }

    if (data?.companyname === "OTHERS") {
      incomePayload.companyname = data.newcompanyname;
    } else {
      incomePayload.companyname = data.companyname;
    }
    console.log({ incomePayload });

    // ADDING / UPDATING CUSTOMER INCOME DETAILS
    props.apis
      .apiUpdateCustomerApplicationIncome(removeEmpty(incomePayload), {
        applicationId: applId,
      })
      .then((res) => {
        console.log({ res });
        props.changeTab(e, "bsa");
      });

    // ADDING CUSTOMER REFERENCE DETAILS
    let referencePayload = {
      reference1name: data?.reference1name,
      reference1mobile: data?.reference1mobile,
      reference1relation: data?.reference1relation,
      //
      reference2name: data?.reference2name,
      reference2mobile: data?.reference2mobile,
      reference2relation: data?.reference2relation,
    };
    console.log({ referencePayload });
    props.apis
      .apiAddCustomerReferenceDetailsInfo(referencePayload, {
        applicationId: applId,
      })
      .then(() => {
        // resolveReferenceCall();
      });
  };

  const getCustomerApplicationInfo = () => {
    props.apis
      .apiGetCustomerSingleApplication({
        applicationId: applId,
      })
      .then((res) => {
        console.log({ res });
        setCustomerApplicationInfo(res);
        if (res) {
          let salaryInfo = res?.ApplicationSalaryInfo[0];
          let refInfo1 = res?.reference1mobile;
          let refInfo2 = res?.reference2mobile;
          if (salaryInfo) {
            let updatedFields = updateFields(salariedFields, salaryInfo);
            setSalariedFields([...updatedFields]);
            setIncomeEditData(res?.ApplicationSalaryInfo[0]);
          }

          if (refInfo1) {
            let updatedRefInfo1Fields = updateFields(referenceFields1, {
              reference1name: res?.reference1name,
              reference1mobile: res?.reference1mobile,
              reference1relation: res?.reference1relation,
            });
            setReferenceFields1([...updatedRefInfo1Fields]);
          }

          if (refInfo2) {
            let updatedRefInfo2Fields = updateFields(referenceFields2, {
              reference2name: res?.reference2name,
              reference2mobile: res?.reference2mobile,
              reference2relation: res?.reference2relation,
            });
            setReferenceFields2([...updatedRefInfo2Fields]);
          }
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getCompanies();
    getCustomerApplicationInfo();
    if (hasEditData) {
      props?.editdata?.isSalaried
        ? setCheckValue("SALARIED")
        : setCheckValue("SELF_EMPLOYED");
    }
  }, []);
  return (
    <>
      <div className="container-fluid py-0">
        <AppForm onSubmit={onIncomeSubmit}>
          <Card className="form_group_card row">
            <div className="row">
              <h2 className="form_title black mb-3">Employment Type</h2>
              <div
                className={Object.className({
                  "checkbox_container mb-2": true,
                  // "pe-none": hasEditData,
                })}
              >
                <div class="form-check me-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="SALARIED"
                    name="employmentType"
                    id="SALARIED"
                    onChange={(...args) => handleEmploymentType(...args)}
                    checked={checkValue === "SALARIED"}
                  />
                  <label class="form-check-label" for="SALARIED">
                    Salaried
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="SELF_EMPLOYED"
                    name="employmentType"
                    id="SELF_EMPLOYED"
                    onChange={(...args) => handleEmploymentType(...args)}
                    checked={checkValue === "SELF_EMPLOYED"}
                  />
                  <label class="form-check-label" for="SELF_EMPLOYED">
                    Self Employed
                  </label>
                </div>
              </div>
              {checkValue === "SALARIED" &&
                salariedFields?.map((field, i) => {
                  return (
                    <div
                      className={`col-12 col-md-${field?.customcolspan || 6} ${
                        field?.outercolclass || ""
                      }`}
                      key={i}
                      id={`input-${field.name}`}
                    >
                      <AppInput {...field}></AppInput>
                    </div>
                  );
                })}
              {checkValue === "SELF_EMPLOYED" &&
                selfEmployedFields?.map((field, i) => {
                  return (
                    <div
                      className={`col-12 col-md-${field?.customcolspan || 6}`}
                      key={i}
                    >
                      <AppInput {...field}></AppInput>
                    </div>
                  );
                })}
            </div>
            <div className="col-12 pt-3 pb-5">
              <div className="alert_note">
                <span className="mandatory text-danger">*</span>
                <p>
                  <span>Note:</span>
                  <b className="ms-2">
                    Company should be only Pvt. ltd & Public Ltd.
                  </b>
                </p>
              </div>
            </div>
          </Card>

          <Card className="form_group_card row">
            <div className="row">
              <h2 className="form_title black mb-3">Reference Details</h2>
              <h3 className="grey_title text-black">Reference 1</h3>
              {referenceFields1?.map((field, i) => {
                return (
                  <div
                    className={`col-12 col-md-${field?.customcolspan || 4}`}
                    key={i}
                  >
                    <AppInput {...field}></AppInput>
                  </div>
                );
              })}
              <h3 className="grey_title text-black">Reference 2</h3>
              {referenceFields2?.map((field, i) => {
                return (
                  <div
                    className={`col-12 col-md-${field?.customcolspan || 4}`}
                    key={i}
                  >
                    <AppInput {...field}></AppInput>
                  </div>
                );
              })}
            </div>
          </Card>
          <div className="nav_container">
            <button
              type="button"
              className="btn btn-primary-lite me-3"
              onClick={(e) => props.changeTab(e, "addressDetails")}
            >
              Back
            </button>
            {customerApplicationInfo?.ApplicationSalaryInfo?.length > 0 ? (
              <button
                type="submit"
                className="btn btn-primary"
                onClick={(...args) => onIncomeSubmit}
              >
                Update
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-primary"
                onClick={(...args) => onIncomeSubmit}
              >
                Submit
              </button>
            )}
          </div>
        </AppForm>
      </div>
    </>
  );
};

export default constore(IncomeDetails);

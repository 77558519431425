import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import CibilCard from "./CibilCard";
import moment from "moment";

const Cibil = (props) => {
  const isAdmin = props?.$store?.persona === _enum.ROUTE_PERSONAS.admin;
  var isReadonly = props.urlparams.params.readonly === "true" ? true : false;
  var tabStatus = props.urlparams.params.status;
  const infoForm = useRef(null);
  const frompath = props?.urlparams?.params?.frompath;
  var BASE_URL = `${endpoints[environment].api_base}/api/v1/files/download/`;

  const [showCibilreport, setShowCibilreport] = useState(false);
  const [cibilData, setCibilData] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [additionalDocs, setAdditionalDocs] = useState([]);
  const additionalDocForm = useRef(null);

  const [cibilfields, setCibilfields] = useState([
    {
      name: "customerName",
      label: "Customer Name",
      type: "text",
      readOnly: true,
    },
    {
      name: "mobileNumber",
      label: "Mobile No.",
      type: "text",
      readOnly: true,
    },
    {
      name: "emailAddress",
      label: "Email ID",
      type: "text",
      readOnly: true,
    },
    {
      name: "dob",
      label: "DOB",
      type: "date",
      dateClassName: "fakedisabled",
    },
    {
      name: "age",
      label: "Age",
      type: "text",
      readOnly: true,
    },
    {
      name: "gender",
      label: "Gender",
      type: "text",
      readOnly: true,
    },
    {
      name: "pan",
      label: "PAN No.",
      type: "text",
      readOnly: true,
    },
    {
      name: "address",
      label: "Address",
      type: "text",
      readOnly: true,
      customcolspan: 12,
    },
  ]);
  const [obligationAmount, setObligationAmount] = useState(0);
  const [additionalInfoFields, setAdditionalInfoFields] = useState([
    {
      loanType: "",
      loanAmt: "",
      outstandingAmt: "",
      installmentAmt: "",
      factoredInInstallment: "",
    },
  ]);
  // ----------------------------------------------------------------
  const [queryinfo, setQueryinfo] = useState([]);
  const [additionalLoanInfo, setAdditionalLoanInfo] = useState([]);
  // ----------------------------------------------------------------

  const addMoreInfo = () => {
    setAdditionalInfoFields([
      ...additionalInfoFields,
      {
        loanType: "",
        loanAmt: "",
        outstandingAmt: "",
        installmentAmt: "",
        factoredInInstallment: "",
        isnewfield: true,
      },
    ]);
  };

  const handleAddInfoSubmit = (e, data, form) => {
    const parsedPayload = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key.replace(/^\d+\./, ""),
        value,
      ])
    );
    let payload = {
      loanType: parsedPayload?.loanType,
      loanAmt: Number(parsedPayload?.loanAmt),
      outstandingAmt: Number(parsedPayload?.outstandingAmt),
      installmentAmt: Number(parsedPayload?.installmentAmt),
      sanctionedDate: parsedPayload?.sanctionedDate,
      factoredInInstallment: parsedPayload?.factoredInInstallment,
    };
    let id = form?.querySelector("button.submit_btn").id;
    let urlData = {
      cibilAdditionalInfoId: Number(id),
    };
    // console.log({ data, payload, form, id });

    props.apis
      .apiUnderWriterUpdateAdditionalCibilInfo(payload, urlData)
      .then((res) => {
        swal2.fire({
          title: "Additional Cibil Info Updated Successfully !!",
          icon: "success",
          iconColor: "#FF4D4D",
          timer: 4000,
        });
        fetchCibilDetails();
      });
  };

  const deleteField = (params, index, id) => {
    console.log({ id });
    if (id !== undefined) {
      let payload = {
        cibilAdditionalInfoId: id,
      };
      props.apis
        .apiUnderWriterDeleteAdditionalCibilInfo(payload)
        .then((res) => {
          swal2.fire({
            title: "Additional Cibil Info Deleted Successfully !!",
            icon: "success",
            iconColor: "#FF4D4D",
            timer: 4000,
          });
          fetchCibilDetails();
        });
    } else {
      let data = [...additionalInfoFields];
      data.splice(index, 1);
      setAdditionalInfoFields(data);
    }
  };

  const calculateObligationAmount = () => {
    console.log("calculating...");
    let data = infoForm.current.form.current.getData();
    let parsedPayload = parseInput(data);
    let payload = {
      ...parsedPayload,
    };
    mixins.debounce(() => {
      console.log({ payload });
      let totalAmount = payload?.additionalInfo?.reduce((acc, v) => {
        if (v.factoredInInstallment === "yes") {
          return acc + Number(v.installmentAmt);
        }
        return acc;
      }, 0);
      if (additionalLoanInfo?.length > 0) {
        additionalLoanInfo.forEach((loan) => {
          if (loan.factoredInInstallment === "yes") {
            totalAmount = totalAmount + loan.installmentAmt;
          }
        });
      }
      console.log({ additionalLoanInfo, totalAmount });
      setObligationAmount(totalAmount);
    }, 500);
  };

  const handleEmiAmount = () => {
    calculateObligationAmount();
  };

  const handleEmiFactored = (index, type, val) => {
    let value = val[0]?.key || val?.key;
    if (type === "update") {
      let amount = additionalLoanInfo[index]?.installmentAmt;
      mixins.debounce(() => {
        value === "yes"
          ? setObligationAmount((prevstate) => prevstate + amount)
          : setObligationAmount((prevstate) => prevstate - amount);
      }, 1000);
    } else {
      if (value === "yes") {
        calculateObligationAmount();
      } else {
        let data = infoForm.current.form.current.getData();
        let parsedPayload = parseInput(data);
        let reducedAmount =
          parsedPayload?.additionalInfo?.[index]?.installmentAmt || 0;
        mixins.debounce(() => {
          setObligationAmount((prevstate) => prevstate - Number(reducedAmount));
        }, 1000);
      }
    }
  };

  useEffect(() => {
    calculateObligationAmount();
    const hasNewField = additionalInfoFields.some((obj) =>
      obj.hasOwnProperty("isnewfield")
    );
    // console.log({ additionalInfoFields, hasNewField });
    setIsDisabled(hasNewField);
  }, [additionalInfoFields]);

  useEffect(() => {
    fetchUserDetails();
    getQueries();
  }, []);

  const fetchCibilDetails = () => {
    props.apis
      .apiGetUnderWriterCibilDetails({
        applicationNumber: props.urlparams.params.id,
      })
      .then((res) => {
        setCibilData(res);
        setShowCibilreport(true);
        fetchAdditionalCibilInfo(res.applicationEquifaxCibilId);
      })
      .catch((error) => {});
  };

  const mergeAddress = (obj) => {
    // Destructure the relevant properties from the object
    const { address1, address2, address3 } = obj;

    // Initialize an array to hold the address parts
    let addressParts = [];

    // Add each address part to the array if it is not null
    if (address1) addressParts.push(address1);
    if (address2) addressParts.push(address2);
    if (address3) addressParts.push(address3);

    // Join the address parts with a comma and return the result
    return addressParts.join(", ");
  };

  const fetchUserDetails = () => {
    props.apis
      .apiGetUnderWriterApplicationDetails({
        applicationNumber: props.urlparams.params.id,
      })
      .then((data) => {
        if (data.isCibilVerified) fetchCibilDetails();

        let fullAddress = mergeAddress(data?.ApplicationAddressInfo?.[0]);

        let editData = {
          ...data.customer,
          customerName: data?.customerName,
          // dob: moment(data?.customer?.dob)?.format("DD-MM-YYYY"),
          age: moment().diff(data?.customer?.dob, "years"),
          address: fullAddress?.toTitleCase(),
          pan: data?.customer?.customerPanCardInfo?.number,
          gender: data?.customer?.gender,
        };
        let fields = cibilfields.map((field) => {
          field.defaultValue = field.defaultValue;
          if (![undefined].includes(editData[field.name])) {
            if (field.type == "date") {
              field.defaultValue = editData[field.name].date();
            } else {
              field.defaultValue = editData[field.name];
            }
          }
          return field;
        });
        setCibilfields(fields);

        if (data?.additionalDocsSubmitted) {
          setAdditionalDocs(
            data?.additionalDocsSubmitted?.filter((v) => v.category === "CIBIL")
          );
        }
      })
      .catch((error) => {});
  };

  const fetchAdditionalCibilInfo = (applicationEquifaxCibilId) => {
    props.apis
      .apiUnderWriterGetAdditionalCibilInfo({
        applicationEquifaxCibilId: applicationEquifaxCibilId,
      })
      .then(({ data }) => {
        setAdditionalLoanInfo(data);
        calculateObligationAmount();
      })
      .catch((error) => {});
  };

  useEffect(() => {
    calculateObligationAmount();
  }, [additionalLoanInfo]);

  // ----------------------------------------------------------------

  const getQueries = () => {
    props.apis
      .apiUnderWriterGetApplicationQuery({
        applicationNumber: props.urlparams.params.id,
      })
      .then((res) => {
        let obj = Object.groupBy(res, (item) => {
          return item.category;
        });

        setQueryinfo(obj["CIBIL"] || []);
      });
  };

  // ----------------------------------------------------------------

  const handleInfoSubmit = (e, data) => {
    e.preventDefault();
    if (data) {
      let parsedPayload = parseInput(data);
      let payload = parsedPayload?.additionalInfo?.map((item) => {
        return {
          ...item,
          loanAmt: parseInt(item?.loanAmt),
          outstandingAmt: parseInt(item?.outstandingAmt),
          installmentAmt: parseInt(item?.installmentAmt),
          applicationEquifaxCibilId: cibilData.applicationEquifaxCibilId,
        };
      });

      props.apis
        .apiUnderWriterAddAdditionalCibilInfo(payload)
        .then((res) => {
          infoForm?.current?.form?.current?.reset();

          setAdditionalInfoFields([
            {
              loanType: "",
              loanAmt: 0,
              outstandingAmt: 0,
              installmentAmt: 0,
              factoredInInstallment: "",
            },
          ]);
          swal2.fire({
            title: "Additional Info Updated",
            icon: "success",
            iconColor: "#FF4D4D",
            showCancelButton: false,
            timer: 3000,
          });
          fetchCibilDetails();
        })
        .catch((error) => {});
    }
  };

  const handleReport = () => {
    props.apis
      .apiUnderWriterGenerateCibilReport({
        applicationNumber: props.urlparams.params.id,
      })
      .then((res) => {
        setCibilData(res);
        setShowCibilreport(true);
      })
      .catch(({ response }) => {
        if (response.status === 503) {
          swal2.fire({
            title: "Service Unavailable",
            icon: "error",
            iconColor: "#FF4D4D",
            showCancelButton: false,
            timer: 3000,
          });
        }
      });
  };

  // ----------------------------------------------------------------

  const [additionalDocFields, setAdditionalDocFields] = useState([
    {
      docType: "",
      fileName: "",
      fileId: "",
    },
  ]);
  const addMoreDocs = () => {
    setAdditionalDocFields([
      ...additionalDocFields,
      {
        docType: "",
        fileName: "",
        fileId: "",
      },
    ]);
  };
  const deleteDocField = (params, index, id) => {
    // console.log({ id });
    if (id !== "") {
      let payload = {
        fileId: id,
        applicationId: parseInt(props.urlparams.params.id),
      };
      // console.log({ payload });

      props.apis.apiUnderWriterDeleteAdditionalDocs(payload).then((res) => {
        fetchUserDetails();
      });
    } else {
      let data = [...additionalDocFields];
      data.splice(index, 1);
      setAdditionalDocFields(data);
    }
  };

  const handleAdditionalDocsChange = (args, index) => {
    var documentNameInputCol = document.querySelector(
      `#column${index}-fileName`
    );
    var documentNameInput = document.querySelector(
      `#column${index}-fileName [name="additionalDoc.${index}.fileName"]`
    );

    // console.log(documentNameInputCol, documentNameInput);
    const hasOthers = args?.some((obj) => obj.key === "OTHERS");
    if (hasOthers) {
      documentNameInputCol.classList.remove("d-none");
      documentNameInput.setAttribute("required", true);
    } else {
      documentNameInputCol.classList.add("d-none");
      documentNameInput.removeAttribute("required");
    }
  };

  const resolveResponse = (msg) => {
    swal2.fire({
      title: msg,
      icon: "success",
      iconColor: "#DE3232",
      confirmButtonColor: "#DE3232",
      confirmButtonText: "Close",
      timer: 3000,
    });
  };
  const handleAdditionalDocsSubmit = async (e, data) => {
    e.preventDefault();
    if (data) {
      let parsedPayload = parseInput(data);
      let payload = parsedPayload?.additionalDoc?.map((item) => {
        return {
          ...item,
          fileName: item?.fileName || item?.docType?.toTitleCase(),
          docType: item?.docType,
          category: "CIBIL",
        };
      });

      const finalPayload = [];

      for (const obj of payload) {
        let reqdata = {
          file: obj.fileId,
        };
        let filePayload = {
          foldername: "underwriterDocs",
          applicationId: parseInt(props.urlparams.params.id),
          category: "CIBIL",
        };

        const response = await props.apis
          .apiFileUploader(reqdata, filePayload)
          .catch((err) => {
            if (err) {
              console.log({ err });
            }
          });
        const uploadFileResData = await response;

        const key = Object.keys(uploadFileResData)[0];
        const value = uploadFileResData[key];
        const newObj = { ...obj, fileId: value };

        finalPayload.push(newObj);
      }
      if (finalPayload) {
        let applicationId = parseInt(props.urlparams.params.id);
        props.apis
          .apiUpdateUnderWriterAdditionalDocs(finalPayload, {
            applicationId: applicationId,
          })
          .then((res) => {
            resolveResponse("Additional Documents Added Successfully");
            additionalDocForm?.current?.form?.current?.reset();
            fetchUserDetails();
            setAdditionalDocFields([
              {
                docType: "",
                fileName: "",
                fileId: "",
              },
            ]);
          });
      }
    }
  };
  // ----------------------------------------------------------------

  const handleDecision = (e, data) => {
    let parsedPayload = parseInput(data);
    // console.log("formData", data, parsedPayload);
    let payload = {
      ...parsedPayload,
      applicationId: parseInt(props.urlparams.params.id),
      category: "CIBIL",
    };
    // console.log({ payload });
    props.apis
      .apiUnderWriterCreateApplicationQuery(payload)
      .then((res) => {
        swal2.fire({
          title: "Underwriter Decision Updated successfully.",
          icon: "success",
          iconColor: "#FF4D4D",
          timer: 4000,
        });
        props.changeTab(e, "queries");
      })
      .catch((error) => {});
  };

  const getBankLink = () => {
    let link;
    if (frompath === "loanapplications") {
      link = `/app/loanapplications?activetab=${tabStatus}`;
    }
    if (frompath === "dupecheck") {
      link = `/app/dupecheck`;
    }
    return link;
  };

  props.$setNode({
    props,
    additionalInfoFields,
  });
  return (
    <>
      <div className="card p-4">
        <AppForm>
          {cibilfields.map((field, i) => (
            <div
              className={`col-6 col-md-4 col-lg-${field?.customcolspan || 4}`}
              key={i}
            >
              <AppInput {...field}></AppInput>
            </div>
          ))}
        </AppForm>
        <div className="card-footer border-0 px-md-0">
          <div className="text-end">
            <button
              type="button"
              className={Object.className({
                "btn btn-primary": true,
                "d-none": showCibilreport,
              })}
              onClick={(...args) => handleReport(...args)}
            >
              Generate Report
            </button>
          </div>

          <div
            className={Object.className({
              "d-none": !showCibilreport,
            })}
          >
            <div className="action_btn_group text-end">
              <button type="button" className="btn btn-primary-transparent">
                <a
                  href={`${BASE_URL}${cibilData?.cibilFileId}`}
                  className="file_download_btn text-primary"
                >
                  <i className="fa fa-download me-2"></i>
                  Download PDF
                </a>
              </button>
            </div>
            <CibilCard data={cibilData} />
          </div>
        </div>
      </div>

      <div
        className={Object.className({
          "card p-4": true,
          "d-none": !showCibilreport,
        })}
      >
        <div
          className={Object.className({
            "form_group_card px-0": true,
            "d-none": isAdmin,
          })}
        >
          <div className="d-flex align-items-start justify-content-between">
            <h2 className="mb-3 theme_text_sm">
              Additional Information To Be Filled
            </h2>
          </div>
          {additionalLoanInfo?.map((item, i) => (
            <>
              <AppForm onSubmit={(...args) => handleAddInfoSubmit(...args)}>
                <div className="row" key={i}>
                  <div className="col-12 col-md-3 col-lg-2">
                    <AppInput
                      name={`${i}.loanType`}
                      label="Type of Loan"
                      type="select"
                      selectNone={isReadonly}
                      defaultValue={item?.loanType}
                      options={_enum.MOKE_LOAN_TYPES}
                      outerclass={Object.className({
                        "col-xl-6 mb-3 w-100 d-flex flex-column pe-none":
                          isReadonly,
                      })}
                    ></AppInput>
                  </div>
                  <div className="col-12 col-md-3 col-lg-2">
                    <AppInput
                      name={`${i}.loanAmt`}
                      label="Loan Amt."
                      defaultValue={item?.loanAmt}
                      type="number"
                      outerclass={Object.className({
                        "col-xl-6 mb-3 w-100 d-flex flex-column pe-none":
                          isReadonly,
                      })}
                    ></AppInput>
                  </div>
                  <div className="col-12 col-md-3 col-lg-2">
                    <AppInput
                      name={`${i}.outstandingAmt`}
                      label="Outstanding"
                      defaultValue={item?.outstandingAmt}
                      type="number"
                      outerclass={Object.className({
                        "col-xl-6 mb-3 w-100 d-flex flex-column pe-none":
                          isReadonly,
                      })}
                    ></AppInput>
                  </div>
                  <div className="col-12 col-md-3 col-lg-2">
                    <AppInput
                      name={`${i}.installmentAmt`}
                      label="EMI Amt."
                      type="number"
                      defaultValue={item?.installmentAmt}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        setAdditionalLoanInfo((prevstate) =>
                          prevstate.map((info, index) =>
                            index === i
                              ? { ...info, installmentAmt: value }
                              : info
                          )
                        );
                      }}
                      outerclass={Object.className({
                        "col-xl-6 mb-3 w-100 d-flex flex-column pe-none":
                          isReadonly,
                      })}
                    ></AppInput>
                  </div>
                  <div className="col-12 col-md-3 col-lg-2">
                    <AppInput
                      name={`${i}.factoredInInstallment`}
                      label=" EMI To Be Factored"
                      type="select"
                      selectNone={isReadonly}
                      defaultValue={item?.factoredInInstallment}
                      options={_enum.YES_OR_NO}
                      onChange={(...args) =>
                        handleEmiFactored(i, "update", ...args)
                      }
                      outerclass={Object.className({
                        "col-xl-6 mb-3 w-100 d-flex flex-column pe-none":
                          isReadonly,
                      })}
                    ></AppInput>
                  </div>
                  <div className="col-12 col-md-3 col-lg-2 d-flex align-items-center">
                    <button
                      type="submit"
                      id={`${item?.cibilAdditionalInfoId}`}
                      className={Object.className({
                        "btn btn-primary me-3 mt-3 submit_btn": true,
                        "d-none": isReadonly,
                      })}
                    >
                      Update
                    </button>
                    <button
                      type="button"
                      onClick={(...args) =>
                        deleteField(...args, i, item?.cibilAdditionalInfoId)
                      }
                      className={Object.className({
                        "btn btn-primary mt-3": true,
                        "d-none": isReadonly,
                      })}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              </AppForm>
              <hr />
            </>
          ))}
          <div className="action_btn_group d-flex align-items-start justify-content-end">
            <button
              type="button"
              className={Object.className({
                "btn btn-primary-transparent py-0": true,
                "d-none": isReadonly,
              })}
              onClick={(...args) => addMoreInfo(...args)}
            >
              + Add New Loan
            </button>
          </div>
          <AppForm
            ref={infoForm}
            className="mt-3"
            onSubmit={(...args) => handleInfoSubmit(...args)}
          >
            {additionalInfoFields?.map((item, i) => (
              <div className="row" key={i}>
                <div className="col-12 col-md-3 col-lg-2">
                  <AppInput
                    name={`additionalInfo.${i}.loanType`}
                    label="Type of Loan"
                    type="select"
                    selectNone={isReadonly}
                    defaultValue={item?.loanType}
                    options={_enum.MOKE_LOAN_TYPES}
                    outerclass={Object.className({
                      "col-xl-6 mb-3 w-100 d-flex flex-column pe-none":
                        isReadonly,
                    })}
                    required
                  ></AppInput>
                </div>
                <div className="col-12 col-md-3 col-lg-2">
                  <AppInput
                    name={`additionalInfo.${i}.loanAmt`}
                    label="Loan Amt."
                    defaultValue={item?.loanAmt}
                    type="number"
                    outerclass={Object.className({
                      "col-xl-6 mb-3 w-100 d-flex flex-column pe-none":
                        isReadonly,
                    })}
                    required
                  ></AppInput>
                </div>
                <div className="col-12 col-md-3 col-lg-2">
                  <AppInput
                    name={`additionalInfo.${i}.outstandingAmt`}
                    label="Outstanding"
                    defaultValue={item?.outstandingAmt}
                    type="number"
                    outerclass={Object.className({
                      "col-xl-6 mb-3 w-100 d-flex flex-column pe-none":
                        isReadonly,
                    })}
                    required
                  ></AppInput>
                </div>
                <div className="col-12 col-md-3 col-lg-2">
                  <AppInput
                    name={`additionalInfo.${i}.installmentAmt`}
                    label="EMI Amt."
                    type="number"
                    defaultValue={item?.installmentAmt}
                    // onChange={(...args) => handleEmiAmount(...args, i)}
                    outerclass={Object.className({
                      "col-xl-6 mb-3 w-100 d-flex flex-column pe-none":
                        isReadonly,
                    })}
                    required
                  ></AppInput>
                </div>
                <div className="col-12 col-md-3 col-lg-2">
                  <AppInput
                    name={`additionalInfo.${i}.factoredInInstallment`}
                    label=" EMI To Be Factored"
                    type="select"
                    selectNone={isReadonly}
                    defaultValue={item?.factoredInInstallment}
                    options={_enum.YES_OR_NO}
                    onChange={(...args) => handleEmiFactored(i, ...args)}
                    outerclass={Object.className({
                      "col-xl-6 mb-3 w-100 d-flex flex-column pe-none":
                        isReadonly,
                    })}
                    required
                  ></AppInput>
                </div>
                {i != 0 && (
                  <div className="col-12 col-md-3 col-lg-2 d-flex align-items-center">
                    <button
                      type="button"
                      onClick={(...args) =>
                        deleteField(...args, i, item?.cibilAdditionalInfoId)
                      }
                      className={Object.className({
                        "btn btn-primary": true,
                        "d-none": isReadonly,
                      })}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                )}
              </div>
            ))}
            <div
              className={Object.className({
                "col-12 col-md-12 text-end": true,
                "d-none": isReadonly,
              })}
            >
              <button
                type="submit"
                className="btn btn-primary"
                // disabled={!isDisabled}
              >
                Submit
              </button>
            </div>
          </AppForm>
        </div>
        <div className="obligation_banner">
          <p className="">
            Total Monthly Obligation : Rs. <span>{obligationAmount}</span>
          </p>
        </div>
      </div>

      {/* ADDITIONAL DOCS CARD */}
      <div className="card profile_card p-4">
        <div className="card-header p-0 border-0">
          <div className="d-flex align-items-center mb-3">
            <img
              src={imagepaths.cardGaurdIcon}
              alt="cardGaurdIcon"
              className="card_icon"
            />
            <h5 className="title ms-2 mb-0">Additional Documents</h5>
          </div>
        </div>
        <div className="py-4">
          <AppForm
            ref={additionalDocForm}
            onSubmit={(...args) => handleAdditionalDocsSubmit(...args)}
          >
            {additionalDocFields?.map((item, i) => (
              <div className="row" key={i}>
                <div className="col-12 col-md-3" id={`column${i}-docType`}>
                  <AppInput
                    name={`additionalDoc.${i}.docType`}
                    label="Document Type"
                    type="select"
                    options={_enum.DOC_TYPES_NEW_WITH_OTHERS}
                    onChange={(e) => handleAdditionalDocsChange(e, i)}
                    required
                  ></AppInput>
                </div>
                <div
                  className="col-12 col-md-3 d-none"
                  id={`column${i}-fileName`}
                >
                  <AppInput
                    name={`additionalDoc.${i}.fileName`}
                    label="Document Name"
                    type="text"
                    required
                  ></AppInput>
                </div>
                <div className="col-12 col-md-3" id={`column${i}-fileId`}>
                  <AppInput
                    name={`additionalDoc.${i}.fileId`}
                    label="Upload Document"
                    type="defaultfile"
                    fileLabel="Upload document"
                    foldername="underwriterDocs"
                    required
                  ></AppInput>
                </div>
                {i != 0 && (
                  <div
                    className={Object.className({
                      "col-12 col-md-3 d-flex align-items-center": true,
                    })}
                  >
                    <button
                      type="button"
                      onClick={(...args) =>
                        deleteDocField(...args, i, item?.fileId)
                      }
                      className={Object.className({
                        "btn btn-primary mt-4": true,
                        "d-none": isReadonly,
                      })}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                )}
              </div>
            ))}
            <div className="action_btn_group mb-4 d-flex align-items-center justify-content-between">
              <button
                type="button"
                className={Object.className({
                  "btn btn-primary-transparent p-0 fs-5 border-right-0": true,
                  "d-none": isReadonly,
                })}
                onClick={(...args) => addMoreDocs(...args)}
              >
                + Add more document
              </button>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </AppForm>

          {additionalDocs &&
            additionalDocs?.map((item, i) => {
              return (
                <div className="row" key={i}>
                  <div className="col-12 col-md-3">
                    <AppInput
                      name="docType"
                      label="Document Type"
                      defaultValue={item?.docType}
                      type="select"
                      options={_enum.DOC_TYPES_NEW_WITH_OTHERS}
                      required
                      disabled={true}
                    ></AppInput>
                  </div>
                  <div className="col-12 col-md-3">
                    <AppInput
                      name="fileName"
                      label="Document Name"
                      defaultValue={item?.fileName}
                      type="text"
                      required
                      disabled={true}
                    ></AppInput>
                  </div>
                  <div className="col-12 col-md-3">
                    <AppInput
                      name="fileId"
                      label="Upload Document"
                      type="file"
                      defaultValue={item?.fileId || ""}
                      fileLabel="Upload document"
                      foldername="underwriterDocs"
                      required
                      disabled={true}
                    ></AppInput>
                  </div>
                  <div
                    className={Object.className({
                      "col-12 col-md-3 d-flex align-items-center": true,
                    })}
                  >
                    <button
                      type="button"
                      onClick={(...args) =>
                        deleteDocField(...args, i, item?.fileId)
                      }
                      className={Object.className({
                        "btn btn-primary": true,
                        "d-none": isReadonly,
                      })}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      {isReadonly ? (
        <div className="nav_container mt-5">
          <button
            type="button"
            onClick={(...args) => props.changeTab(...args, "incomedetails")}
            className="btn btn-primary-lite me-3"
          >
            Back
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={(...args) => props.changeTab(...args, "queries")}
          >
            Next
          </button>
        </div>
      ) : (
        <div>
          {queryinfo
            .filter((q) => !q.responseId)
            .map((item) => (
              <>
                <div className="row">
                  <AppInput
                    name="uwDecision"
                    label="Underwriter Decision"
                    type="text"
                    defaultValue={item.uwDecision}
                    outerclass="col-12 col-md-3"
                    readOnly="true"
                  ></AppInput>

                  <AppInput
                    name="messageText"
                    label="Remarks"
                    readOnly="true"
                    defaultValue={item.messageText}
                    type="textarea"
                    outerclass="col-12 col-md-9 mb-3"
                  ></AppInput>
                </div>
              </>
            ))}
          <AppForm
            className="mx-auto"
            onSubmit={(...args) => handleDecision(...args)}
          >
            <AppInput
              name="uwDecision"
              label="Underwriter Decision"
              type="select"
              options={_enum.UNDER_WRITER_DECISION_DISCREPANCY}
              outerclass="col-12 col-md-3"
              required="true"
            ></AppInput>

            <AppInput
              name="messageText"
              label="Remarks"
              type="textarea"
              outerclass="col-12 col-md-9 mb-3"
            ></AppInput>
            <div className="nav_container mt-5">
              <Link to={getBankLink()} className="btn btn-primary-lite me-3">
                Cancel
              </Link>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </AppForm>
        </div>
      )}
    </>
  );
};

export default constore(Cibil);

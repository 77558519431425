import { DatePicker } from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import Select from "react-dropdown-select";
import { AppMonthPicker } from "./AppMonthPicker";
import AppUploadFile from "./AppUploadFile";
import AppUploadFileArea from "./AppUploadFileArea";

export class AppInput extends Component {
  constructor(props) {
    super(props);
    this.refs = {
      appinput: React.createRef(),
    };
    this.state = {
      attrs: this.attrs,
      options: [],
      selectedDate: this.selectDate,
      selectedNumber: this.selectNumber,
      selectedText: this.selectText,
    };
  }

  get attrs() {
    let props = Object.except(this.props, [
      "className",
      "style",
      "labelClass",
      "controlClass",
      "labelpos",
      "children",
      "type",
      `dispatch`,
      `setstore`,
      `initialconstruct`,
      `navigate`,
      "apis",
      `$l`,
    ]);
    let onInvalid = (e) => {
      // console.log("onInvalid",{target:e.target,e,validity:e.target.validity})
      // if(props.invalidMessage&&!e.target.validity.valid) {
      //   e.target.setCustomValidity(props.invalidMessage);
      // } else if(e.target.validity.valid) {
      //   e.target.setCustomValidity("");
      // }
    };
    props.onInvalid = onInvalid;
    return props;
  }

  get selectDate() {
    let date = this.props?.type === "date" ? this.props?.defaultValue : null;
    return date;
  }

  get selectText() {
    let text = this.props?.type === "text" ? this.props?.defaultValue : null;
    return text;
  }

  get selectNumber() {
    let number =
      this.props?.type === "number" ? this.props?.defaultValue : null;
    return number;
  }

  get options() {
    let options = this.props.options;
    if (this.state.options.length) {
      return this.state.options;
    } else if (typeof options == "string") {
      console.log({ ...app?.props?.store[options] });
      return [{ key: "", lable: "ALL" }, ...(app?.props?.store[options] || [])];
    } else if (typeof options == "function") {
      let results = options(this.props);
      if (results instanceof Array && this.state.options.length == 0) {
        return results;
      } else if (results instanceof Promise && this.state.options.length == 0) {
        results.then((data) => {
          data instanceof Array && this.setState({ options: data });
        });
        return this.state.options;
      }
    } else if (options instanceof Array) {
      return options;
    } else {
      return this.state.options;
    }
  }

  componentDidMount() {
    window.AppInputComponent = this;
    this.refs.appinput.vnode = this;
    if (["select"].includes(this.props.type)) {
      let inputEle = this.refs.appinput.querySelector(
        ".react-dropdown-select>div+input"
      );
      // console.log({inputEle},this,this.props.name);
      if (inputEle) {
        let defaultValue = this.props.defaultValue || "";
        inputEle.setAttribute("type", "select");
        let value =
          defaultValue instanceof Array ? defaultValue[0] || "" : defaultValue;
        if (this.props.multi) {
          inputEle.dataset.invalue =
            defaultValue instanceof Array
              ? defaultValue.join(",") || ""
              : defaultValue;
        } else {
          inputEle.dataset.invalue = defaultValue;
        }
        inputEle.parentElement.dataset.type = "select";
        inputEle.parentElement.dataset.value = value;
        inputEle.autoComplete = "off";
      }
    }
  }

  componentDidUpdate() {
    if (
      this.state.attrs.value != this.attrs.value ||
      this.state.attrs.defaultValue != this.attrs.defaultValue ||
      this.state.attrs.checked != this.attrs.checked ||
      this.state.attrs.required != this.attrs.required ||
      this.state.attrs.multi != this.attrs.multi
    ) {
      this.setState({
        attrs: this.attrs,
      });
    }
    if (
      this.state.attrs.defaultValue != this.attrs.defaultValue &&
      this.props.type === "date"
    ) {
      this.setState({
        selectedDate: this.attrs.defaultValue,
      });
    }
    if (
      this.state.attrs.defaultValue != this.attrs.defaultValue &&
      this.props.type === "number"
    ) {
      this.setState({
        selectedNumber: this.attrs.defaultValue,
      });
    }
    if (
      this.state.attrs.defaultValue != this.attrs.defaultValue &&
      this.props.type === "text"
    ) {
      this.setState({
        selectedText: this.attrs.defaultValue,
      });
    }
  }

  handleNumberChange(e) {
    let value = e.target.value;
    if (this.attrs.readOnly && value !== this.attrs.defaultValue) {
      window.alert("Unauthorized modification detected.");
      return;
    } else {
      this.setState({
        selectedNumber: value,
      });
    }
    if (this.props.onChange) this.props.onChange(value);
  }

  handleTextChange(e) {
    let value = e.target.value;
    if (this.attrs.readOnly && value !== this.attrs.defaultValue) {
      window.alert("Unauthorized modification detected.");
      return;
    } else {
      this.setState({
        selectedText: value,
      });
    }
    if (this.props.onChange) this.props.onChange(value);
  }

  handleVerify(value) {
    if (this.props.onVerifyBtnClick) this.props.onVerifyBtnClick(value);
  }

  handleDateChange(value) {
    this.setState({
      selectedDate: value,
    });
    if (this.props.onChange) this.props.onChange(value);
  }

  handleDatePlaceholder() {
    let placeholder = this.props?.placeholder;
    if (this.props.type === "date") {
      placeholder = this.props?.placeholder || "Select a date";
    }
    return placeholder;
  }

  setOption(values, attrs) {
    let inputEle = this.refs.appinput.querySelector(
      ".react-dropdown-select>div+input"
    );
    let value = values[0];
    let valkey = values.map((v) => v?.key).join(",");
    inputEle.dataset.invalue = valkey;
    inputEle.parentElement.dataset.type = "select";
    inputEle.parentElement.dataset.value = value;
    this.props.onChange && this.props.onChange(values, value);
  }

  setCheckVal(e) {
    // console.log(e.target.checked);
    this.props.onChange && this.props.onChange(e.target.checked);
  }

  setTextarea(e) {
    if (this.props.pattern) {
      mixins.debounce(() => {
        if (!e.target.value.match(new RegExp(this.props.pattern))) {
          e.target.setCustomValidity(
            this.props.invalidmessage || "Invalid Text"
          );
          e.target.dataset.invalid = true;
        } else {
          e.target.setCustomValidity("");
          e.target.dataset.invalid = false;
        }
      });
    } else {
    }
  }

  searchOptions(search) {
    if (search.state.searchResults.length == 0) {
      this.props.onSearch && this.props.onSearch(search.state.search, search);
    }
    // console.log("searchOptions", search.state.search, search);
  }

  getInputEle() {
    return document.querySelector(`[name='appinput-${this.props.name}']>input`);
  }

  getInputValue() {
    return this.getInputEle()?.value;
  }

  clearValues() {
    let ref = this.refs.appinput.querySelector("input");
    if (!ref) {
      return;
    }
    switch (this.props.type) {
      case "select":
        ref = this.refs.select;
        ref.setState({ values: [] });
        break;
      case "date":
        ref = this.refs.datepicker;
        this.setState((state) => ({
          ...state,
          attrs: { ...state.attrs, value: "" },
          selectedDate: "",
        }));
        break;
      case "number":
        ref = this.refs.numinputref;
        this.setState((state) => ({
          ...state,
          attrs: { ...state.attrs, value: "" },
          selectedNumber: "",
        }));
        break;
      case "text":
        ref = this.refs.textinputref;
        this.setState((state) => ({
          ...state,
          attrs: { ...state.attrs, value: "" },
          selectedText: "",
        }));
        break;
      case "month":
        ref = this.refs.monthpicker;
        ref.setState((state) => ({
          ...state,
          attrs: { ...state.attrs, value: "" },
        }));
        break;
      default:
        ref.value = "";
    }
  }

  handleEyeToggle(e) {
    e.preventDefault();
    e.stopPropagation();
    let currentelem = e.target;
    let targetelem = document.querySelector(
      `[name='appinput-${this.props.name}'] input`
    );
    if (targetelem.type === "text") {
      targetelem.setAttribute("type", "password");
      currentelem.classList.remove("fa-eye");
      currentelem.classList.add("fa-eye-slash");
    } else {
      targetelem.setAttribute("type", "text");
      currentelem.classList.remove("fa-eye-slash");
      currentelem.classList.add("fa-eye");
    }
  }

  numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };
  render() {
    let { props, state } = this;
    let { attrs, selectedDate, selectedNumber, selectedText } = state;
    let {
      type,
      label,
      labelClass = "col-form-label",
      selectplaceholder = "Select",
      labelpos = "top",
      errorMessage,
      selectNone,
      hasVerifyBtn,
      hasVerifyBadge,
    } = props;
    let isText = ["text"].includes(type);
    let isNumber = ["number"].includes(type);
    let isPassword = ["password"].includes(type);
    let isEmail = ["email"].includes(type);
    let isSelect = ["select"].includes(type);
    let isDate = ["date"].includes(type);
    let isTime = ["time"].includes(type);
    let isMonth = ["month"].includes(type);
    let isTextarea = ["textarea"].includes(type);
    let isRadio = ["radio"].includes(type);
    let isCheckbox = ["checkbox"].includes(type);
    let isDefaultFile = ["defaultfile"].includes(type);
    let isFile = ["file"].includes(type);
    let isFilearea = ["filearea"].includes(type);
    let isLabel = ["label"].includes(type);

    let isOthers = !(
      isText ||
      isNumber ||
      isPassword ||
      isEmail ||
      isSelect ||
      isDate ||
      isTime ||
      isTextarea ||
      isRadio ||
      isCheckbox ||
      isMonth ||
      isDefaultFile ||
      isFile ||
      isFilearea ||
      isLabel
    );
    attrs.placeholder = attrs.placeholder || "Enter Here";
    attrs = Object.except(attrs, ["options"]);
    let value = attrs.defaultValue;
    if ([undefined, "", null].includes(value)) {
      value = this.props.defaultValue;
    }
    if (isSelect) {
      value = (this.options || []).find(
        (opt) => opt[attrs.valueField || "key"] == attrs.defaultValue
      );
      if (attrs.defaultValue instanceof Array) {
        value = this.options.filter((o) =>
          attrs.defaultValue.includes(o[attrs.valueField || "key"])
        );
      }
    }
    this.value = value;
    if (value != this.getInputValue() && this.getInputEle() && value) {
      this.getInputEle().value = value;
    }
    if (isSelect) {
      // console.log({ attrs, value, defaultValue: attrs.defaultValue });

      if (attrs.selectAllByDefault) {
        setTimeout(() => {
          // select all product types by default
          let selectInput = document.querySelector(
            `[name='appinput-${attrs?.name}'].form-group`
          );
          // console.log({ selectInput });
          selectInput?.vnode.refs.select.selectAll();
        }, 1000);
      }
    }

    return (
      <div
        ref={"appinput"}
        className={
          "form-group relative " +
          (props?.className || "") +
          " " +
          (this.props?.outerclass || "col-xl-6 mb-3 w-100 d-flex flex-column")
        }
        name={"appinput-" + props?.name || ""}
        style={props?.style || {}}
      >
        {labelpos == "top" && label && (
          <>
            {typeof label === "string" && (
              <label className={labelClass || ""} htmlFor={attrs.name}>
                {label}
                {attrs.required && <span className="text-danger">*</span>}
              </label>
            )}
            {typeof label !== "string" && label}
          </>
        )}
        {labelpos == "none" && label && <></>}
        {isLabel && <div>{props.children}</div>}
        {isSelect && (
          <>
            {attrs.showtooltip && (
              <span
                className="text_truncate cursor-pointer tooltip-hover"
                title={attrs.tooltipvalue}
                onClick={(e) => alert(attrs.tooltipvalue)}
              >
                {attrs.tooltipvalue}
              </span>
            )}
            <Select
              ref="select"
              id="select"
              labelField={"label"}
              valueField={"key"}
              values={value instanceof Array ? value : (value && [value]) || []}
              options={this.options}
              name={attrs.name}
              className={Object.className({
                // "h-full": true,
                hasSelectAll: attrs.selectAll ? true : false,
                "pe-none": selectNone,
              })}
              searchFn={(...args) => this.searchOptions(...args)}
              {...attrs}
              onChange={(values) => this.setOption(values)}
              placeholder={attrs.placeholder || "Enter Here"}
              disabled={props?.readOnly || props?.disabled || false}
            />
          </>
        )}
        {isText && (
          <div>
            <input
              ref="textinputref"
              className={"form-control " + (props?.controlClass || "")}
              type="text"
              autoComplete="off"
              {...(!this.props.onChange
                ? {
                    value: selectedText,
                  }
                : {})}
              onChange={(...args) => this.handleTextChange(...args)}
              {...attrs}
            />
            {errorMessage?.name === props?.name && (
              <div className="invalid-feedback d-block">
                {errorMessage.message}
              </div>
            )}
            {hasVerifyBtn && (
              <button
                type="button"
                className="text-primary verify_btn border-0 bg-transparent text-underlined float-right"
                onClick={(...args) => this.handleVerify(selectedText)}
              >
                Verify
              </button>
            )}
            {hasVerifyBadge && (
              <i className="fa fa-check text-green floating_verified_badge"></i>
            )}
          </div>
        )}
        {isNumber && (
          <div>
            <input
              ref="numinputref"
              className={
                "form-control numberinput" + (props?.controlClass || "")
              }
              type="number"
              autoComplete="off"
              onWheel={(values) => this.numberInputOnWheelPreventChange(values)}
              {...(!this.props.onChange
                ? {
                    value: selectedNumber,
                  }
                : {})}
              onChange={(...args) => this.handleNumberChange(...args)}
              {...attrs}
            />
            {errorMessage?.name === props?.name && (
              <div className="invalid-feedback d-block">
                {errorMessage.message}
              </div>
            )}
          </div>
        )}
        {isPassword && (
          <div className="position-relative">
            <input
              className={"form-control " + (props?.controlClass || "")}
              type="password"
              autoComplete="off"
              {...attrs}
            />
            <button
              type="button"
              className="password_eye_btn"
              onClick={(...args) => this.handleEyeToggle(...args)}
            >
              <i class="far fa-eye-slash"></i>
            </button>
            {errorMessage?.name === props?.name && (
              <div className="invalid-feedback d-block">
                {errorMessage.message}
              </div>
            )}
          </div>
        )}
        {isEmail && (
          <div>
            <input
              className={"form-control " + (props?.controlClass || "")}
              type="email"
              autoComplete="off"
              {...attrs}
            />
            {errorMessage?.name === props?.name && (
              <div className="invalid-feedback d-block">
                {errorMessage.message}
              </div>
            )}
          </div>
        )}
        {isDate && (
          <>
            <DatePicker
              ref="datepicker"
              className={"form-control " + (props?.controlClass || "")}
              value={selectedDate ? dayjs(selectedDate) : ""}
              format="YYYY-MM-DD"
              onChange={(...args) => this.handleDateChange(...args)}
              disabledDate={
                this.props.disabledDate
                  ? (...args) => this.props.disabledDate(...args)
                  : ""
              }
              size="large"
              picker="date"
              {...(this.props.dateClassName
                ? {
                    className: this.props.dateClassName,
                  }
                : {})}
              disabled={attrs?.readOnly || attrs?.disabled}
              required={attrs?.required}
              name={attrs.name}
              placeholder={this.handleDatePlaceholder()}
            />
            {!selectedDate && attrs?.required && (
              <div className="invalid-feedback d-block">Please select date</div>
            )}
          </>
        )}
        {isTime && (
          <input
            className={
              "form-control AppTimePicker " + (props?.controlClass || "")
            }
            type="time"
            {...attrs}
          />
        )}
        {isMonth && (
          <AppMonthPicker
            ref="monthpicker"
            className={"form-control " + (props?.controlClass || "")}
            type={type}
            {...attrs}
          ></AppMonthPicker>
        )}
        {isFile && (
          <AppUploadFile
            className={"form-control " + (props?.controlClass || "")}
            type={type}
            {...attrs}
          />
        )}
        {isDefaultFile && (
          <input
            className={"form-control " + (props?.controlClass || "")}
            type="file"
            {...attrs}
          />
        )}
        {isFilearea && (
          <AppUploadFileArea
            className={"form-control " + (props?.controlClass || "")}
            type="file"
            {...attrs}
          />
        )}
        {isTextarea && (
          <textarea
            className={"form-control " + (props?.controlClass || "")}
            type={type}
            {...attrs}
            onChange={(...args) => this.setTextarea(...args)}
          ></textarea>
        )}
        {isRadio && (
          <div className="radio">
            <label htmlFor={attrs.id} className={"cursor-pointer"}>
              <input
                className={"me-2" + (props?.controlClass || "")}
                type={type}
                {...attrs}
              />
              {props.children}
            </label>
          </div>
        )}
        {isCheckbox && (
          <div className="checkbox">
            <label htmlFor={attrs.id} className="cursor-pointer">
              <input
                className="form-check-input mt-0 me-2"
                onChange={(e) => this.setCheckVal(e)}
                type={type}
                id={attrs.name}
                {...attrs}
              />
              {props.children}
            </label>
          </div>
        )}
        {isOthers && (
          <input
            className={"form-control " + (props?.controlClass || "")}
            type={type}
            {...attrs}
          />
        )}
        {labelpos != "top" && labelpos != "none" && label && (
          <label className={labelClass || ""}>
            {label}
            {attrs.required && <span className="text-danger">*</span>}
          </label>
        )}
        {/* <span className="text-danger">error message here</span> */}
        {this.props.note && (
          <span className="note mt-1 d-block">{this.props.note}</span>
        )}
      </div>
    );
  }
}

export default constore(AppInput);

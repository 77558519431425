import React, { useEffect, useState } from "react";
import AadharVerification from "./AadharVerification";
import PanVerification from "./PanVerification";
import AddressDetails from "./AddressDetails";
import { Nav, Tab } from "react-bootstrap";

const KycDetails = (props) => {
  const [activeKey, setActiveKey] = useState("aadhar");
  const [editInfo, setEditInfo] = useState({});
  const {
    isAadharVerified,
    isPanVerified,
    customerAadharCardInfo,
    customerPanCardInfo,
    CustomerAddressInfo,
  } = props?.editdata;

  const handleSelect = (key) => {
    setActiveKey(key);
  };

  useEffect(() => {
    props.apis.apiGetCustomerInfo().then((res) => {
      props?.apis
        .apiGetSettingInfo({ key: "MANUAL_AADHAAR" })
        .then((settingResponse) => {
          console.log({ settingResponse });
          setEditInfo({
            ...res,
            manualAadhaarStatus:
              settingResponse?.value === "ENABLE" ? true : false,
          });
        });
    });
  }, [activeKey]);

  const tabData = [
    {
      key: "aadhar",
      name: "Aadhar",
      isVerified:
        customerAadharCardInfo && Object.keys(customerAadharCardInfo).length > 0
          ? true
          : false,
    },
    {
      key: "pan",
      name: "PAN ",
      isVerified:
        customerPanCardInfo && Object.keys(customerPanCardInfo).length > 0
          ? true
          : false,
    },
    // {
    //   key: "addressDetails",
    //   name: "Address Details",
    //   isVerified: CustomerAddressInfo?.length > 0 ? true : false,
    // },
  ];
  return (
    <>
      <div className="custom-tab-1 mb-5">
        <Tab.Container
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          onSelect={handleSelect}
        >
          <Nav as="ul" className="nav-tabs pe-none">
            {tabData.map((data, i) => (
              <Nav.Item as="li" key={i}>
                <Nav.Link eventKey={data.key}>
                  {data.name}
                  <i
                    className={Object.className({
                      "fa fa-check fs-12 ms-2": true,
                      invisible: !data?.isVerified,
                    })}
                  ></i>
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <Tab.Content className="pt-4">
            {activeKey === "aadhar" && (
              <Tab.Pane eventKey="aadhar">
                <AadharVerification
                  editdata={editInfo}
                  handleSelect={handleSelect}
                />
              </Tab.Pane>
            )}
            {activeKey === "pan" && (
              <Tab.Pane eventKey="pan">
                <PanVerification
                  editdata={props.editdata}
                  handleSelect={handleSelect}
                  skipToStep={props.skipToStep}
                />
              </Tab.Pane>
            )}
            {activeKey === "addressDetails" && (
              <Tab.Pane eventKey="addressDetails">
                <AddressDetails
                  skipToStep={props.skipToStep}
                  handleSelect={handleSelect}
                  editdata={editInfo}
                  onClose={props.onClose}
                />
              </Tab.Pane>
            )}
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};

export default constore(KycDetails);

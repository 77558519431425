import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";

const ProfileDetails = (props) => {
  const hasEditData = props.editdata?.emailAddress?.length > 0 ? true : false;
  const { emailVerification, emailAddress } = props?.editdata;
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });

  const [emailValue, setEmailValue] = useState(emailAddress);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleEmail = (e) => {
    const emailReg = _enum?.REGEX?.email;
    const fieldValue = e.target.value;
    let verifyBtn = document.querySelector("#verifyTextBtn");

    if (emailReg.test(fieldValue)) {
      setErrorMessage("");
      setEmailValue(fieldValue);
      verifyBtn?.classList.remove("pe-none");
    } else {
      setErrorMessage({
        name: "emailAddress",
        message: "Please enter a valid Email Address",
      });
      verifyBtn?.classList.add("pe-none");
    }
  };

  const customerVerifyEmail = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let urldata = {
      email: emailValue,
    };
    document.querySelector("[name='emailAddress']").readOnly = true;

    const resolve = () => {
      setIsDisabled(true);
      const targetTime = new Date().getTime() + 120000; // 2 minutes from now
      localStorage.setItem("customerVerifyBtnTimer", targetTime);

      const timer = setTimeout(() => {
        setIsDisabled(false);
        localStorage.removeItem("customerVerifyBtnTimer");
      }, 120000);

      swal2
        .fire({
          text: "Email verification send to your email ID. Please check your registered Email ID to verify it.",
          icon: "success",
          timer: 4000,
          iconColor: "#FF4D4D",
          confirmButtonText: "Ok",
          showCancelButton: false,
        })
        .then((result) => {
          if (result.isDismissed) {
            props.onClose();
          }
        });

      return () => clearTimeout(timer);
    };
    props.apis.apiCustomerGenerateEmail(urldata).then((res) => {
      resolve();
    });
  };

  useEffect(() => {
    const targetTime = localStorage.getItem("customerVerifyBtnTimer");
    const currentTime = new Date().getTime();

    if (targetTime && currentTime < targetTime) {
      setIsDisabled(true);
      const remainingTime = targetTime - currentTime;
      const timer = setTimeout(() => {
        setIsDisabled(false);
        localStorage.removeItem("customerVerifyBtnTimer");
      }, remainingTime);
      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    document.querySelector("[name='emailAddress']").readOnly =
      emailVerification;
  }, []);

  props.$setNode({
    props,
    errorMessage,
  });
  return (
    <div className="customer_profile_details">
      <h2 className="form_title mb-3">Profile Details</h2>
      <AppForm onSubmit={props.onProfileSubmit}>
        <div className="col-12 col-md-6">
          <AppInput
            name="customerName"
            label="Full Name * (As per PAN card)"
            type="text"
            placeholder="Please enter"
            defaultValue={props.editdata?.customerName}
            required={true}
            readOnly={false}
          ></AppInput>
        </div>
        <div className="col-12 col-md-6 email_input_column">
          <AppInput
            name="emailAddress"
            label="Email ID"
            type="email"
            placeholder="Please enter"
            defaultValue={props.editdata?.emailAddress}
            onChange={(...args) => handleEmail(...args)}
            errorMessage={errorMessage}
            required={true}
          ></AppInput>
          {emailVerification ? (
            <i className="fa fa-check-circle text-green fs-4"></i>
          ) : (
            <button
              id="verifyTextBtn"
              onClick={(...args) => customerVerifyEmail(...args)}
              className={Object.className({
                "text-primary verify_btn border-0 bg-transparent": true,
                disabled: isDisabled,
              })}
              disabled={isDisabled}
            >
              Verify
            </button>
          )}
        </div>
        <div className="col-12 col-md-6">
          <AppInput
            name="pincode"
            label="PIN Code"
            type="number"
            placeholder="Please enter"
            defaultValue={props.editdata?.pincode}
            required={true}
            readOnly={false}
          ></AppInput>
        </div>
        <div className="col-12 col-md-6">
          <AppInput
            name="gender"
            label="Gender"
            type="select"
            options={_enum.GENDER}
            placeholder="Please enter"
            defaultValue={props.editdata?.gender}
            required={true}
            readOnly={false}
          ></AppInput>
        </div>
        <div className="col-12 col-md-6">
          <AppInput
            name="mobileNumber"
            label="Mobile No."
            type="number"
            placeholder="Please enter"
            defaultValue={props.editdata?.mobileNumber}
            required={true}
            readOnly={true}
          ></AppInput>
        </div>
        <div className="col-12 col-md-6">
          <AppInput
            name="alternateMobileNumber"
            label="Alternate Mobile No."
            type="number"
            placeholder="Please enter"
            defaultValue={props.editdata?.alternateMobileNumber}
            required={false}
            readOnly={false}
          ></AppInput>
        </div>
        <div className="col-12 col-md-6">
          <AppInput
            name="dob"
            label="DOB"
            type="date"
            placeholder="Please enter"
            defaultValue={props.editdata?.dob ? props.editdata?.dob : ""}
            disabledDate={(current) => {
              const today = moment();
              const maxDate = today.subtract(18, "years");
              return current && current > maxDate.endOf("day");
            }}
            required={true}
            readOnly={false}
          ></AppInput>
        </div>
        <div className="col-12 col-md-6">
          <AppInput
            name="maritalStatus"
            label="Marital Status"
            type="select"
            options={_enum.MARITAL_STATUS}
            placeholder="Please enter"
            defaultValue={props.editdata?.maritalStatus}
            required={true}
            readOnly={false}
          ></AppInput>
        </div>
        <div className="col-12 col-md-6">
          <AppInput
            name="religion"
            label="Religion"
            type="select"
            options={_enum.RELIGIONS}
            placeholder="Please enter"
            defaultValue={props.editdata?.religion}
            required={true}
            readOnly={false}
          ></AppInput>
        </div>
        <div className="nav_container">
          <button
            type="button"
            className="btn btn-primary-lite me-3"
            onClick={(...args) => props.onClose(...args)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={(...args) => props.onProfileSubmit}
            disabled={emailVerification === true ? false : true}
          >
            {hasEditData ? "Update & Next" : "Submit"}
          </button>
        </div>
      </AppForm>
    </div>
  );
};

export default constore(ProfileDetails);

import React from "react";

const VerifyEmail = (props) => {
  const handleEmailVerification = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const url = props?.urlparams?.href;
    const correctedUrl = url.replace("?type=", "&type=");
    const urlParams = new URLSearchParams(correctedUrl.split("?")[1]);
    const type = urlParams.get("type");
    // console.log(type);

    const resolve = () => {
      swal2.fire({
        title: "Your Email Address is successfully Verified!",
        text: "You can now close this window",
        icon: "success",
        iconColor: "#FF4D4D",
        showConfirmButton: false,
        showCancelButton: false,
      });
    };

    let urlToken = props.urlparams.params.token;
    let urldata = {
      token: urlToken,
    };

    if (type === "official") {
      props.apis
        .apiCustomerVerifyOfficialEmailWithToken(urldata)
        .then((res) => {
          resolve();
        });
    } else {
      props.apis.apiCustomerVerifyEmailWithToken(urldata).then((res) => {
        resolve();
      });
    }
  };

  return (
    <>
      <div className="container-fluid customer_verify_email_container">
        <div className="card p-4">
          <div className="row py-5">
            <div className="col-12 text-center">
              <img
                src={imagepaths.emailVerificationIcon}
                alt="emailVerificationIcon"
                className="img-fluid mb-4"
              />
              <h2 className="mb-2">Verify your email address</h2>
              <p className="">
                Please verify this email address by clicking button below.
              </p>
              <button
                type="button"
                className="btn btn-primary my-4"
                onClick={(...args) => handleEmailVerification(...args)}
              >
                Verify your email
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default constore(VerifyEmail);

import React, { useEffect, useRef, useState } from "react";

const OverallPortfolioSalesCard = (props) => {
  const infoForm = useRef(null);
  const [overallPortfolioData, setOverallPortfolioData] = useState({});
  const [timeline, setTimeline] = useState("TODAY");

  // OVERALL PORTFOLIO
  const getOverallDisbursementDetails = (query, params) => {
    let data = {
      ...query,
    };

    let payload = {};
    if (params) {
      payload = {
        timeline: params,
      };
    } else {
      payload = {
        timeline: timeline,
      };
    }

    props.apis.apiGetOverallDisbursementDetails(data, payload).then((res) => {
      setOverallPortfolioData((prevState) => ({
        ...prevState,
        disbursementDetails: res,
      }));
    });
  };
  const getOverallActiveDetails = (query, params) => {
    let data = {
      ...query,
    };

    let payload = {};
    if (params) {
      payload = {
        timeline: params,
      };
    } else {
      payload = {
        timeline: timeline,
      };
    }

    props.apis.apiGetOverallActiveDetails(data, payload).then((res) => {
      setOverallPortfolioData((prevState) => ({
        ...prevState,
        overallActive: res,
      }));
    });
  };
  const getOverallClosedDetails = (query, params) => {
    let data = {
      ...query,
    };

    let payload = {};
    if (params) {
      payload = {
        timeline: params,
      };
    } else {
      payload = {
        timeline: timeline,
      };
    }

    props.apis.apiGetOverallClosedDetails(data, payload).then((res) => {
      setOverallPortfolioData((prevState) => ({
        ...prevState,
        overallClosed: res,
      }));
    });
  };
  const getOverallOverdueDetails = (query, params) => {
    let data = {
      ...query,
    };

    let payload = {};
    if (params) {
      payload = {
        timeline: params,
      };
    } else {
      payload = {
        timeline: timeline,
      };
    }

    props.apis.apiGetOverallOverdueDetails(query, payload).then((res) => {
      setOverallPortfolioData((prevState) => ({
        ...prevState,
        overallOverdue: res,
      }));
    });
  };

  const showInputs = (inputnames) => {
    if (inputnames.length > 0) {
      inputnames.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          element.classList.remove("d-none");
        }
      });
    }
  };

  const hideInputs = (inputnames) => {
    if (inputnames.length > 0) {
      inputnames.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          element.classList.add("d-none");
        }
      });
    }
  };

  const handleDateChange = (value) => {
    let val = value[0]?.key || "TODAY";
    let inputs = [
      "column-start_date",
      "column-end_date",
      "column-search_btn",
      "column-reset_btn",
    ];
    if (val === "CUSTOM") {
      showInputs(inputs);
      setOverallPortfolioData({});
    } else {
      setTimeline(val);
      hideInputs(inputs);
    }
  };

  const onSearch = () => {
    let data = infoForm.current.form.current.getData();
    let queryData = {
      ...Object.except(data, ["timeline"]),
    };
    // getOverallDisbursementDetails(queryData, "CUSTOM");
    // getOverallActiveDetails(queryData, "CUSTOM");
    // getOverallClosedDetails(queryData, "CUSTOM");
    // getOverallOverdueDetails(queryData, "CUSTOM");
  };

  const resetSearchForm = () => {
    infoForm?.current?.form?.current?.reset();
    let inputs = [
      "column-start_date",
      "column-end_date",
      "column-search_btn",
      "column-reset_btn",
    ];
    hideInputs(inputs);
    // getOverallDisbursementDetails({}, "TODAY");
    // getOverallActiveDetails({}, "TODAY");
    // getOverallClosedDetails({}, "TODAY");
    // getOverallOverdueDetails({}, "TODAY");
  };

  useEffect(() => {
    // OVERALL PORTFOLIO
    // getOverallDisbursementDetails();
    // getOverallActiveDetails();
    // getOverallClosedDetails();
    // getOverallOverdueDetails();
  }, [timeline]);

  props.$setNode({
    props,
    overallPortfolioData,
  });

  return (
    <>
      <div className="metrics_card_container mb-4">
        <div className="leads_detail_select_box">
          <AppForm
            ref={infoForm}
            onSubmit={(...args) => onSearch(...args)}
            className="row justify-content-end"
          >
            <div
              className="col-12 col-md-3 col-lg-3 col-xl-2"
              id="column-timeline"
            >
              <AppInputFocus
                type="select"
                name="timeline"
                label="Time Line"
                options={_enum.OVERALL_DETAIL}
                onChange={(...args) => handleDateChange(...args)}
                defaultValue={"TODAY"}
                placeholder="Time Line"
              ></AppInputFocus>
            </div>
            <div
              className="col-12 col-md-3 col-lg-3 col-xl-2 d-none"
              id="column-start_date"
            >
              <AppInputFocus
                type="date"
                name="start_date"
                label="Start Date"
                placeholder="Start Date"
                required={true}
              ></AppInputFocus>
            </div>
            <div
              className="col-12 col-md-3 col-lg-3 col-xl-2 d-none"
              id="column-end_date"
            >
              <AppInputFocus
                type="date"
                name="end_date"
                label="End Date"
                placeholder="End Date"
                required={true}
              ></AppInputFocus>
            </div>
            <div
              className="col-2 col-md-1 col-lg-1 col-xl-1 d-none"
              id="column-search_btn"
            >
              <button type="submit" className="btn search_btn white_variant">
                <i className="fa fa-search"></i>
              </button>
            </div>
            <div
              className="col-2 col-md-1 col-lg-1 col-xl-1 d-none"
              id="column-reset_btn"
            >
              <button
                type="button"
                className="btn search_btn white_variant"
                onClick={(...args) => resetSearchForm(...args)}
              >
                <i class="fa-solid fa-filter-circle-xmark"></i>
              </button>
            </div>
          </AppForm>
        </div>
        <div className="row g-4 metrics_card_row">
          <div className="col-12 col-md-6 col-lg-6 col-xl-4 metrics_card">
            <div className="col_left">
              <img
                src={imagepaths.overallBookImg}
                className="lead_card_img"
                alt="card icon"
              />
            </div>
            <div className="col_right">
              <h5 className="card-title">Total Interested</h5>
              <div className="bottom_content">
                <p className="card-text">
                  <span className="me-2">No. Of Applications :</span>
                  <span>
                    {overallPortfolioData?.disbursementDetails
                      ?.disbursedApplicationCount || 0}
                  </span>
                </p>
                <p className="card-text">
                  <span className="me-2">Amount :</span>
                  <span>
                    {overallPortfolioData?.disbursementDetails?.disbursedSanctionedLoanAmt?.cur() ||
                      0}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 col-xl-4 metrics_card">
            <div className="col_left">
              <img
                src={imagepaths.dollarCheckImg}
                className="lead_card_img"
                alt="card icon"
              />
            </div>
            <div className="col_right">
              <h5 className="card-title">Total Applied</h5>
              <div className="bottom_content">
                <p className="card-text">
                  <span className="me-2">No. Of Applications :</span>
                  <span>
                    {overallPortfolioData?.overallActive?.loanAccountCount || 0}
                  </span>
                </p>
                <p className="card-text">
                  <span className="me-2">Amount :</span>
                  <span>
                    {overallPortfolioData?.overallActive?.sanctionedLoanAmount?.cur() ||
                      0}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 col-xl-4 metrics_card">
            <div className="col_left">
              <img
                src={imagepaths.dollarCloseImg}
                className="lead_card_img"
                alt="card icon"
              />
            </div>
            <div className="col_right">
              <h5 className="card-title">Total Disbursed</h5>
              <div className="bottom_content">
                <p className="card-text">
                  <span className="me-2">No. Of Applications :</span>
                  <span>
                    {overallPortfolioData?.overallClosed?.loanAccountCount || 0}
                  </span>
                </p>
                <p className="card-text">
                  <span className="me-2">Amount :</span>
                  <span>
                    {overallPortfolioData?.overallClosed?.sanctionedLoanAmount?.cur() ||
                      0}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default constore(OverallPortfolioSalesCard);

import React, { useEffect, useState } from "react";
import MainPagetitle from "../../../layouts/MainPagetitle";
import CreditScoreModal from "../KYC/CreditScoreModal";
import { Modal } from "react-bootstrap";
import CibilCard from "../LoanApplications/Cibil/CibilCard";
import moment from "moment";
import dayjs from "dayjs";

const CreditScore = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  var BASE_URL = `${endpoints[environment].api_base}/api/v1/files/download/`;
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });
  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "fullName",
      title: "Customer Name",
      sorter: (a, b) => a.name.length - b.name.length,
      render: (text, record) => {
        return (
          <div>
            <h6 className="text-primary">{text}</h6>
          </div>
        );
      },
    },
    // {
    //   dataIndex: "mobileNumber",
    //   title: "Mobile No.",
    // },
    // {
    //   dataIndex: "emailAddress",
    //   title: "Email ID",
    // },
    {
      dataIndex: "createdAt",
      title: "Report Generated On",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        if (record?.createdAt) {
          return date;
        } else {
          return "-";
        }
      },
    },
  ];

  // CREDIT SCORE MODAL
  const [showCibilModal, setShowCibilModal] = useState(false);
  const [cibilData, setCibilData] = useState({});
  const [modalFields, setModalFields] = useState([
    {
      label: "Customer First Name",
      name: "FirstName",
      type: "text",
      placeholder: "Please Enter",
    },
    {
      label: "Customer Middle Name",
      name: "MiddleName",
      type: "text",
      placeholder: "Please Enter",
    },
    {
      label: "Customer Last Name",
      name: "LastName",
      type: "text",
      placeholder: "Please Enter",
    },
    {
      label: "Mobile No.",
      name: "Number",
      type: "text",
      placeholder: "Please Enter",
      onChange: (value) => handleMobileNo(value),
      required: true,
    },
    // {
    //   label: "Email ID",
    //   name: "emailAddress",
    //   type: "email",
    //   placeholder: "Please Enter",
    // },
    {
      name: "DOB",
      label: "DOB",
      type: "date",
      onChange: (...args) => handleDobChange(...args),
      disabledDate: (current) => {
        const today = moment();
        const maxDate = today.subtract(18, "years");
        return current && current > maxDate.endOf("day");
      },
      defaultValue: moment().subtract(18, "years"),
      placeholder: "Please enter",
    },
    {
      type: "text",
      name: "age",
      label: "Age",
      min: 18,
      max: 80,
      defaultValue: 18,
      disabled: true,
    },
    // {
    //   name: "gender",
    //   label: "Gender",
    //   type: "select",
    //   options: _enum.GENDER,
    //   placeholder: "Please enter",
    //   customcolspan: 6,
    // },
    {
      name: "PAN",
      label: "PAN No.",
      type: "text",
      placeholder: "PAN No.",
      onChange: (value) => handlePan(value),
      required: true,
    },
    {
      name: "AadharNumber",
      label: "Aadhar Number",
      type: "number",
      placeholder: "Aadhar Number",
      onChange: (value) => handleAadhar(value),
      required: true,
    },
    {
      name: "State",
      label: "State",
      type: "select",
      options: _enum.STATIC_STATES,
      placeholder: "State",
      required: true,
    },
    {
      name: "Postal",
      label: "Postal",
      type: "number",
      placeholder: "Postal",
      required: true,
    },
    {
      name: "AddressLine1",
      label: "Address",
      type: "textarea",
      placeholder: "Please enter",
      required: true,
      customcolspan: 12,
    },
  ]);
  const [editData, setEditData] = useState([]);
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});

  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  const openCibilModal = () => {
    setShowCibilModal(true);
  };

  const closeCibilModal = () => {
    setShowCibilModal(false);
    setCibilData({});
  };

  const calculateAge = (birthdate) => {
    const birthDate = dayjs(birthdate);
    const today = dayjs();
    let age = today.year() - birthDate.year();
    if (
      today.month() < birthDate.month() ||
      (today.month() === birthDate.month() && today.date() < birthDate.date())
    ) {
      age--;
    }
    return age;
  };

  const handleDobChange = (date) => {
    let ageInput = document.querySelector(`input[name='age']`);
    if (date) {
      const calculatedAge = calculateAge(date);
      ageInput.value = calculatedAge;
    }
    return date;
  };

  const handleMobileNo = (e) => {
    const numberReg = _enum?.REGEX?.number;
    const fieldValue = e.target.value;
    if (numberReg.test(fieldValue) && fieldValue.length === 10)
      setErrorMessage({ name: "", message: "" });
    else
      setErrorMessage({
        name: "Number",
        message: "Please enter a valid Mobile Number",
      });
  };

  const handlePan = (e) => {
    const panReg = _enum?.REGEX?.pan;
    const fieldValue = e.target.value;
    if (panReg.test(fieldValue)) setErrorMessage({ name: "", message: "" });
    else
      setErrorMessage({
        name: "PAN",
        message: "Please enter a valid PAN Number",
      });
  };

  const handleAadhar = (e) => {
    const aadharReg = _enum?.REGEX?.aadhar;
    const fieldValue = e.target.value;
    if (aadharReg.test(fieldValue)) setErrorMessage({ name: "", message: "" });
    else
      setErrorMessage({
        name: "AadharNumber",
        message: "Please enter a valid Aadhar Number",
      });
  };

  const handleSubmit = (e, data, form) => {
    e.preventDefault();
    let formdata = data;
    let payload = {
      ...Object.except(formdata, [
        "age",
        "Number",
        "Postal",
        "State",
        "AddressLine1",
      ]),
      FirstName: data?.FirstName,
      MiddleName: data?.MiddleName,
      LastName: data?.LastName,
      DOB: data?.DOB,
      AadharNumber: data?.AadharNumber,
      PAN: data?.PAN,
      InquiryAddresses: [
        {
          AddressType: ["H"],
          AddressLine1: data?.AddressLine1,
          State: data?.State,
          Postal: data?.Postal,
        },
      ],
      InquiryPhones: [
        {
          PhoneType: ["M"],
          Number: data?.Number,
        },
      ],
    };
    console.log({ data, payload });

    // props.apis.apiGetKycCibilDetailsMoke().then((res) => {
    //   console.log({ res });
    //   if (res) {
    //     closeModal();
    //     setCibilData(res);
    //     openCibilModal();
    //   }
    // });

    props.apis.apiGetKycCibilDetails(payload).then((res) => {
      console.log({ res });
      if (res) {
        closeModal();
        setCibilData(res);
        getCibilUsers();
        openCibilModal();
      }
    });
  };

  const addModalFields = (values) => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    setPopupfields(fields);
    setPopupdata(formdata);
    setOpenCanvas(true);
  };

  const onModalClose = () => {
    console.log("onModalClose");
    closeModal();
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});
  };

  const onPageChange = (pageData) => {
    console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 10,
    });
  };

  const getCibilUsers = (params = {}, reset) => {
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, ["limit", "page"]);

      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
        ...params,
      };
    } else {
      payload = {
        ...search,
        ...params,
      };
    }

    props.apis.apiGetCibilUsersHistory(payload).then(({ data, total }) => {
      setIsFetching(false);
      setTableData(data);
      setRowcount(total);
    });
  };

  const viewSummary = (record) => {
    console.log("view summary", record);
    setCibilData(record);
    openCibilModal();
  };

  useEffect(() => {
    let payload = {
      ...search,
    };
    getCibilUsers(payload);
  }, [search]);

  return (
    <>
      <MainPagetitle
        mainTitle="Know Your Customer"
        back="Credit Score"
        backLink="/app/kyc"
        pageTitle={"Know Your Customer"}
        parentTitle={"Home"}
        pageIcon={imagepaths.PersonSearchIconActive}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="tbl-caption-container justify-content-end">
                  <button
                    className="btn btn-primary ms-1"
                    onClick={(...args) => addModalFields(...args)}
                  >
                    + Create New
                  </button>
                </div>
                <AppTable
                  data={tableData}
                  columns={columns}
                  onChange={(...arg) => onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  editable={false}
                  viewable={false}
                  deletable={false}
                  viewSummary={true}
                  viewSummaryAction={(...args) => viewSummary(...args)}
                  loading={isFetching}
                ></AppTable>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CIBIL MODAL */}
      <Modal
        show={showCibilModal}
        onHide={closeCibilModal}
        centered
        size="xl"
        backdrop="static"
        className="cibil_modal"
      >
        <Modal.Body>
          <h2 className="modal_title">
            Customer Name:
            <span className="ms-1">{cibilData?.fullName || "-"}</span>
          </h2>
          <CibilCard data={cibilData} cardvariant={"variant_grey"} />
          <div className="nav_container mt-5 justify-content-center">
            <button
              type="button"
              className="btn btn-primary-lite me-3 px-5"
              onClick={(e) => closeCibilModal(e)}
            >
              Cancel
            </button>
            <a
              href={`${BASE_URL}${cibilData?.cibilFileId}`}
              className="btn btn-primary"
            >
              <i className="fa fa-download me-2"></i>
              Download PDF
            </a>
          </div>
        </Modal.Body>
      </Modal>

      <CreditScoreModal
        title="Credit Score Check"
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        editdata={editData}
        fields={popupfields}
        editmode={editmode}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={`${editmode ? "Update" : "Generate Report"}`}
        disableallfields={viewmode ? true : false}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default constore(CreditScore);

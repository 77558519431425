export const SalesLeadsMenuList = [
    {
        title: "Dashboard",
        iconStyle: imagepaths.Dashboard,
        iconActive: imagepaths.DashboardActive,
        to: "/app/home",
        id: ["home", "portfolioinfo"],
    },
    {
        title: "Leads",
        iconStyle: imagepaths.SingleUserIcon,
        iconActive: imagepaths.SingleUserIconActive,
        to: "/app/leads",
        id: "leads",
    },
    {
        title: "Assigned to Opps",
        iconStyle: imagepaths.applicationCheckImg,
        iconActive: imagepaths.applicationCheckActiveImg,
        to: "/app/applications",
        id: "applications",
    },
    {
        title: "Applications",
        iconStyle: imagepaths.applicationCheckImg,
        iconActive: imagepaths.applicationCheckActiveImg,
        to: "/app/loanapplications",
        id: ["loanapplications", "loanapplicationsview"],
    },
];

import React, { useEffect, useState } from "react";
import MainPagetitle from "../../layouts/MainPagetitle";
import TelecallerInfoCard from "./elements/TelecallerInfoCard";
import OverallPortfolioCard from "./OverallPortfolioCard";
import RevenueInfo from "./DashboardHelpers/RevenueInfo/RevenueInfo";
import DisbursementDetailsInfo from "./DashboardHelpers/DisbursementDetailsInfo/DisbursementDetailsInfo";
import UserRegistrationInfo from "./DashboardHelpers/UserRegistrationInfo/UserRegistrationInfo";
import LeadInfo from "./DashboardHelpers/LeadInfo/LeadInfo";
import OverallPortfolioSalesCard from "./OverallPortfolioSalesCard";
import SalesLeadInfo from "./DashboardHelpers/SalesLeadInfo/SalesLeadInfo";
import TelecallerInfo from "./DashboardHelpers/TelecallerInfo/TelecallerInfo";

const Home = (props) => {
  const isSeniorUnderwriter =
    props?.$store?.persona === _enum.ROUTE_PERSONAS.senior_underwriter;
  const isUnderwriter =
    props?.$store?.persona === _enum.ROUTE_PERSONAS.underwriter;
  const isSalesLeads =
    props?.$store?.persona === _enum.ROUTE_PERSONAS.sales_lead;
  const [overallPortfolioData, setOverallPortfolioData] = useState({});
  const [applicationStatusData, setApplicationStatusData] = useState({});
  const [activitiesData, setActivitiesData] = useState({});

  const [activeKey, setActiveKey] = useState("portfolio_info");
  const filterTabData = [
    {
      key: "portfolio_info",
      name: "Portfolio Info.",
    },
    {
      key: "sales_info",
      name: "Sales Info.",
    },
    {
      key: "credit_info",
      name: "Credit Info.",
    },
  ];

  const handleSelect = (e, key) => {
    setActiveKey(key);
  };

  const overdueCardData = [
    {
      title: "0-30",
      count: overallPortfolioData?.last0030dayOverdueCount,
      amount: overallPortfolioData?.last0030dayOverdueAmt,
      path: `/app/portfolioinfo?cardname=overdue30`,
    },
    {
      title: "30-60",
      count: overallPortfolioData?.last6030dayOverdueCount,
      amount: overallPortfolioData?.last6030dayOverdueAmt,
      path: `/app/portfolioinfo?cardname=overdue60`,
    },
    {
      title: "60-90",
      count: overallPortfolioData?.last6090dayOverdueCount,
      amount: overallPortfolioData?.last6090dayOverdueAmt,
      path: `/app/portfolioinfo?cardname=overdue90`,
    },
    {
      title: "90-180",
      count: overallPortfolioData?.last90180dayOverdueCount,
      amount: overallPortfolioData?.last90180dayOverdueAmt,
      path: `/app/portfolioinfo?cardname=overdue180`,
    },
    {
      title: "180+",
      count: overallPortfolioData?.last180day$OverdueCount,
      amount: overallPortfolioData?.last180day$OverdueAmt,
      path: `/app/portfolioinfo?cardname=overdue180Plus`,
    },
  ];

  const applicationStatusCardData = [
    {
      title: "Total",
      count: applicationStatusData?.totalApplications?.totalApplicationCount,
      amount:
        applicationStatusData?.totalApplications?.totalApplicationLoanAmount,
      icon: imagepaths.dashboardFileIcon,
      path: `/app/portfolioinfo?tablename=totalApplication`,
    },
    {
      title: "Pending",
      count: applicationStatusData?.pendingApplications?.applicationCount,
      amount: applicationStatusData?.pendingApplications?.applicationLoanAmount,
      icon: imagepaths.dashboardFileWarningIcon,
      path: `/app/portfolioinfo?tablename=pendingapplications`,
    },
    {
      title: "Underwriting",
      count: applicationStatusData?.underwritingApplications?.applicationCount,
      amount:
        applicationStatusData?.underwritingApplications?.applicationLoanAmount,
      icon: imagepaths.dashboardFileSearchIcon,
      path: `/app/portfolioinfo?tablename=underwriting`,
    },
    {
      title: "Sanctioned",
      count: applicationStatusData?.sanctionedApplications?.applicationCount,
      amount:
        applicationStatusData?.sanctionedApplications?.applicationLoanAmount,
      icon: imagepaths.dashboardFileCleanIcon,
      path: `/app/portfolioinfo?tablename=sanctioned`,
    },
    {
      title: "Approved",
      count: applicationStatusData?.approvedApplications?.applicationCount,
      amount:
        applicationStatusData?.approvedApplications?.applicationLoanAmount,
      icon: imagepaths.dashboardFileCheckIcon,
      path: `/app/portfolioinfo?tablename=approved`,
    },
    {
      title: "Agreement",
      count: applicationStatusData?.agreementApplications?.applicationCount,
      amount:
        applicationStatusData?.agreementApplications?.applicationLoanAmount,
      icon: imagepaths.dashboardFilePenIcon,
      path: `/app/portfolioinfo?tablename=agreement`,
    },
    {
      title: "Disbursed",
      count: applicationStatusData?.disbursedApplications?.applicationCount,
      amount:
        applicationStatusData?.disbursedApplications?.applicationLoanAmount,
      icon: imagepaths.dashboardFileRightIcon,
      path: `/app/portfolioinfo?tablename=disbursed`,
    },
    {
      title: "Rejected",
      count: applicationStatusData?.rejectedApplications?.applicationCount,
      amount:
        applicationStatusData?.rejectedApplications?.applicationLoanAmount,
      icon: imagepaths.dashboardFileCrossIcon,
      path: `/app/portfolioinfo?tablename=rejected`,
    },
  ];

  // OVERALL PORTFOLIO
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getActivitiesOverdueYesterday = () => {
    props.apis.apiGetActivitiesOverdueYesterday().then((res) => {
      console.log({ res });
      setActivitiesData((prevState) => ({
        ...prevState,
        activitiesOverdueYesterday: res,
      }));
    });
  };

  const getActivitiesCollectionsYesterday = () => {
    props.apis.apiGetActivitiesCollectionsYesterday().then((res) => {
      console.log({ res });
      setActivitiesData((prevState) => ({
        ...prevState,
        activitiesCollectionsYesterday: res,
      }));
    });
  };

  const getActivitiesOverdueToday = () => {
    props.apis.apiGetActivitiesOverdueToday().then((res) => {
      console.log({ res });
      setActivitiesData((prevState) => ({
        ...prevState,
        activitiesOverdueToday: res,
      }));
    });
  };

  const getActivitiesCollectionsToday = () => {
    props.apis.apiGetActivitiesCollectionsToday().then((res) => {
      console.log({ res });
      setActivitiesData((prevState) => ({
        ...prevState,
        activitiesCollectionsToday: res,
      }));
    });
  };

  // OVERDUE PORTFOLIO
  const GetDashboardAdminOverduePortfolio = () => {
    props.apis.apiGetDashboardAdminOverduePortfolio().then((res) => {
      console.log({ res });
      setOverallPortfolioData(res);
    });
  };

  // APPLICATION STATUS
  const getTotalApplicationStatus = () => {
    props.apis.apiGetTotalApplicationStatus().then((res) => {
      setApplicationStatusData((prevState) => ({
        ...prevState,
        totalApplications: res,
      }));
    });
  };

  const getPendingApplicationStatus = () => {
    props.apis.apiGetPendingApplicationStatus().then((res) => {
      setApplicationStatusData((prevState) => ({
        ...prevState,
        pendingApplications: res,
      }));
    });
  };

  const getUnderwritingApplicationStatus = () => {
    props.apis.apiGetUnderwritingApplicationStatus().then((res) => {
      setApplicationStatusData((prevState) => ({
        ...prevState,
        underwritingApplications: res,
      }));
    });
  };

  const getSanctionedApplicationStatus = () => {
    props.apis.apiGetSanctionedApplicationStatus().then((res) => {
      setApplicationStatusData((prevState) => ({
        ...prevState,
        sanctionedApplications: res,
      }));
    });
  };

  const getApprovedApplicationStatus = () => {
    props.apis.apiGetApprovedApplicationStatus().then((res) => {
      setApplicationStatusData((prevState) => ({
        ...prevState,
        approvedApplications: res,
      }));
    });
  };

  const getAgreementApplicationStatus = () => {
    props.apis.apiGetAgreementApplicationStatus().then((res) => {
      setApplicationStatusData((prevState) => ({
        ...prevState,
        agreementApplications: res,
      }));
    });
  };

  const getDisbursedApplicationStatus = () => {
    props.apis.apiGetDisbursedApplicationStatus().then((res) => {
      setApplicationStatusData((prevState) => ({
        ...prevState,
        disbursedApplications: res,
      }));
    });
  };

  const getRejectedApplicationStatus = () => {
    props.apis.apiGetRejectedApplicationStatus().then((res) => {
      setApplicationStatusData((prevState) => ({
        ...prevState,
        rejectedApplications: res,
      }));
    });
  };

  // ----------------------------------------------------------------
  const [timeline, setTimeline] = useState("TODAY");
  const [customTimeline, setCustomTimeline] = useState("CUSTOM");

  const handleTimelineChange = (timeline) => {
    setTimeline(timeline);
    // console.log({ timeline });
  };

  const handleCustomTimelineChange = (customTimeline) => {
    // console.log({ customTimeline });
    setCustomTimeline(customTimeline);
  };

  const handleDownloadExcel = async () => {
    // console.log({ timeline, customTimeline });

    let data = {};
    if (customTimeline.start_date) data.start_date = customTimeline.start_date;
    if (customTimeline.end_date) data.end_date = customTimeline.end_date;

    let payload = {};
    payload = {
      timeline: timeline || "TODAY",
    };
    const resolve = async (res, filename) => {
      let data = await res;
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      let link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", `${filename}.xlsx`);
      link.click();
    };

    const handlePortfolioExcelDownload = () => {
      // EXPORT REVENUE INFO
      props.apis
        .apiExportRevenueTotalCollections(data, payload)
        .then(async (res) => {
          if (res) resolve(res, "total_collections");
        });
      props.apis.apiExportRevenueProcessing(data, payload).then(async (res) => {
        if (res) resolve(res, "processing_fees");
      });
      props.apis.apiExportRevenuePrinciple(data, payload).then(async (res) => {
        if (res) resolve(res, "principle");
      });
      props.apis.apiExportRevenueInterest(data, payload).then(async (res) => {
        if (res) resolve(res, "interest");
      });

      // EXPORT ACTIVITIES
      props.apis.apiExportActivitiesOverdueYesterday().then(async (res) => {
        if (res) resolve(res, "overdue_yesterday");
      });
      props.apis.apiExportActivitiesCollectionsYesterday().then(async (res) => {
        if (res) resolve(res, "collections_yesterday");
      });
      props.apis.apiExportActivitiesOverdueToday().then(async (res) => {
        if (res) resolve(res, "overdue_today");
      });
      props.apis.apiExportActivitiesCollectionsToday().then(async (res) => {
        if (res) resolve(res, "collections_today");
      });

      // EXPORT OVERDUE PORTFOLIO
      _enum.OVERDUE_PORTFOLIO_RANGE_KEY_MAP?.map((key) => {
        props.apis
          .apiExportOverduePortfolio({
            overdueRange: _enum.OVERDUE_PORTFOLIO_RANGE_MAP[key],
          })
          .then(async (res) => {
            if (res) resolve(res, `overdue_portfolio_${key}`);
          });
      });
    };

    const handleSalesInfoExcelDownload = () => {
      // Disbursement Details
      props.apis
        .apiExportTotalDisbursementDetails(data, payload)
        .then(async (res) => {
          if (res) resolve(res, "total_disbursement_details");
        });

      _enum.SOURCING_CHANNEL_KEY_MAP?.map((key) => {
        props.apis
          .apiExportDisbursementDetailsByChannel(data, {
            channel: _enum.SOURCING_CHANNEL_MAP[key],
            timeline: timeline,
          })
          .then(async (res) => {
            if (res) resolve(res, `disbursement_details_${key}`);
          });
      });

      // User Registration
      props.apis
        .apiExportTotalRegisteredUsers(
          {
            ...(customTimeline?.startDate
              ? {
                  start_date: customTimeline?.startDate,
                }
              : {}),
            ...(customTimeline?.endDate
              ? {
                  end_date: customTimeline?.endDate,
                }
              : {}),
          },
          payload
        )
        .then(async (res) => {
          if (res) resolve(res, "total_registered_users");
        });

      _enum.CUSTOMER_FILTER_STATUS_KEY_MAP?.map((key) => {
        props.apis
          .apiExportTotalRegisteredUsers(
            {
              ...(customTimeline?.startDate
                ? {
                    start_date: customTimeline?.startDate,
                  }
                : {}),
              ...(customTimeline?.endDate
                ? {
                    end_date: customTimeline?.endDate,
                  }
                : {}),
              filterStatus: _enum.CUSTOMER_FILTER_STATUS_MAP[key],
            },
            payload
          )
          .then(async (res) => {
            if (res) resolve(res, `${key}_registered_users`);
          });
      });

      // Lead Info
      _enum.SOURCES_KEY_MAP?.map((key) => {
        props.apis
          .apiExportDashboardLeadsInfo(
            {
              ...(customTimeline?.startDate
                ? {
                    start_date: customTimeline?.startDate,
                  }
                : {}),
              ...(customTimeline?.endDate
                ? {
                    end_date: customTimeline?.endDate,
                  }
                : {}),
              filterStatus: _enum.SOURCES_MAP[key],
            },
            payload
          )
          .then(async (res) => {
            if (res) resolve(res, `${key}_leads_info`);
          });
      });
    };

    const handleCreditInfoExcelDownload = () => {
      // Application Status
      _enum.DASHBOARD_APPLICATION_STATUS_KEY_MAP?.map((key) => {
        props.apis
          .apiExportApplicationsByStatus({
            ...(key === "totalApplication"
              ? {}
              : {
                  filterStatus: _enum.DASHBOARD_APPLICATION_STATUS_MAP[key],
                }),
          })
          .then(async (res) => {
            if (res) resolve(res, `application_status_${key}`);
          });
      });
    };

    if (activeKey === "portfolio_info") handlePortfolioExcelDownload();
    if (activeKey === "sales_info") handleSalesInfoExcelDownload();
    if (activeKey === "credit_info") handleCreditInfoExcelDownload();
  };

  useEffect(() => {
    if (isSeniorUnderwriter || isUnderwriter) {
      setActiveKey("credit_info");
      // APPLICATION STATUS
      // getTotalApplicationStatus();
      // getPendingApplicationStatus();
      // getUnderwritingApplicationStatus();
      // getSanctionedApplicationStatus();
      // getApprovedApplicationStatus();
      // getAgreementApplicationStatus();
      // getDisbursedApplicationStatus();
      // getRejectedApplicationStatus();
    } else {
      if (isSalesLeads) {
      } else {
        // ACTIVITIES
        getActivitiesOverdueYesterday();
        getActivitiesCollectionsYesterday();
        getActivitiesOverdueToday();
        getActivitiesCollectionsToday();

        // OVERDUE PORTFOLIO
        GetDashboardAdminOverduePortfolio();

        // APPLICATION STATUS
        getTotalApplicationStatus();
        getPendingApplicationStatus();
        getUnderwritingApplicationStatus();
        getSanctionedApplicationStatus();
        getApprovedApplicationStatus();
        getAgreementApplicationStatus();
        getDisbursedApplicationStatus();
        getRejectedApplicationStatus();
      }
    }
  }, []);

  props.$setNode({
    props,
    overallPortfolioData,
    activitiesData,
  });
  return (
    <>
      <MainPagetitle
        mainTitle="Dashboard"
        pageTitle="Dashboard"
        parentTitle="Home"
        pageIcon={imagepaths.DashboardActive}
      />

      <div className="container-fluid">
        {!isSeniorUnderwriter && !isUnderwriter ? (
          <>
            <h2 className="fs-4 mb-3">Overall Portfolio</h2>
            {isSalesLeads ? (
              <>
                <OverallPortfolioSalesCard />
              </>
            ) : (
              <>
                <OverallPortfolioCard />
                <div className="filter_tab_container">
                  {filterTabData?.map((btn) => {
                    return (
                      <>
                        <button
                          type="button"
                          id={btn.key}
                          className={Object.className({
                            active: activeKey === btn.key,
                          })}
                          onClick={(...args) => handleSelect(...args, btn.key)}
                        >
                          {btn.name}
                        </button>
                      </>
                    );
                  })}
                  <div className="action_btn_group d-inline float-right">
                    <button
                      type="button"
                      className="btn btn-primary-transparent file_download_btn text-primary me-5"
                      onClick={(...args) => handleDownloadExcel(...args)}
                    >
                      <i className="fa fa-download me-2"></i>
                      Download Excel
                    </button>
                  </div>
                </div>
              </>
            )}
          </>
        ) : null}

        {isSalesLeads ? (
          <>
            <h2 className="fs-4 mt-4 mb-3">Lead Info.</h2>
            <SalesLeadInfo />
            <h2 className="fs-4 mt-4 mb-3">Telecaller Info.</h2>
            <TelecallerInfo />
          </>
        ) : (
          <>
            {activeKey === "portfolio_info" &&
              !isSeniorUnderwriter &&
              !isUnderwriter && (
                <>
                  <h2 className="fs-4 mt-4 mb-3">Revenue Info.</h2>
                  <RevenueInfo
                    onTimelineChange={handleTimelineChange}
                    onCustomTimelineChange={handleCustomTimelineChange}
                  />
                  <h2 className="fs-4 mt-4 mb-5">Activities</h2>
                  <div className="row g-5 g-lg-4">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                      <div className="card activity_card">
                        <div className="row">
                          <div className="col-12 col-md-6 col-lg-6 position-relative">
                            <div
                              className="dashboard_card application_card left"
                              onClick={(...args) =>
                                navigate(
                                  `/app/portfolioinfo?cardname=overdueYesterday`
                                )
                              }
                            >
                              <div className="card-body">
                                <div className="icon_container">
                                  <div className="icon_label">
                                    <p className="m-0">Yesterday</p>
                                  </div>
                                  <div className="icon">
                                    <img
                                      src={imagepaths.dollarBagInHandIcon}
                                      alt="icon image"
                                    />
                                  </div>
                                </div>
                                <div className="content">
                                  <p className="icon_title">Due Yesterday</p>
                                  <p className="count">
                                    {
                                      activitiesData?.activitiesOverdueYesterday
                                        ?.overdueCount
                                    }
                                  </p>
                                  <p className="card_label">
                                    Amount :
                                    <span className="primary-red ms-1">
                                      {activitiesData?.activitiesOverdueYesterday?.overdueAmt?.cur()}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="verticle_line d-none d-md-block"></div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-6">
                            <div
                              className="dashboard_card application_card right"
                              onClick={(...args) =>
                                navigate(
                                  `/app/portfolioinfo?cardname=collectionsYesterday&tablename=collections`
                                )
                              }
                            >
                              <div className="card-body">
                                <div className="icon_container">
                                  <div className="icon">
                                    <img
                                      src={imagepaths.dollarCollectionIcon}
                                      alt="icon image"
                                    />
                                  </div>
                                </div>
                                <div className="content">
                                  <p className="icon_title">Collections</p>
                                  <p className="count">
                                    {
                                      activitiesData
                                        ?.activitiesCollectionsYesterday
                                        ?.repaymentCount
                                    }
                                  </p>
                                  <p className="card_label">
                                    Amount :
                                    <span className="primary-red ms-1">
                                      {activitiesData?.activitiesCollectionsYesterday?.repaymentAmt?.cur()}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="border_bottom_line"></div>
                      </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                      <div className="card activity_card">
                        <div className="row">
                          <div className="col-12 col-md-6 col-lg-6 position-relative">
                            <div
                              className="dashboard_card application_card left"
                              onClick={(...args) =>
                                navigate(
                                  `/app/portfolioinfo?cardname=overdueToday`
                                )
                              }
                            >
                              <div className="card-body">
                                <div className="icon_container">
                                  <div className="icon_label">
                                    <p className="m-0">Today</p>
                                  </div>
                                  <div className="icon">
                                    <img
                                      src={imagepaths.dollarBagInHandIcon}
                                      alt="icon image"
                                    />
                                  </div>
                                </div>
                                <div className="content">
                                  <p className="icon_title">Due Today</p>
                                  <p className="count">
                                    {
                                      activitiesData?.activitiesOverdueToday
                                        ?.overdueCount
                                    }
                                  </p>
                                  <p className="card_label">
                                    Amount :
                                    <span className="primary-red ms-1">
                                      {activitiesData?.activitiesOverdueToday?.overdueAmt?.cur()}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="verticle_line d-none d-md-block"></div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-6">
                            <div
                              className="dashboard_card application_card right"
                              onClick={(...args) =>
                                navigate(
                                  `/app/portfolioinfo?cardname=collectionsToday&tablename=collections`
                                )
                              }
                            >
                              <div className="card-body">
                                <div className="icon_container">
                                  <div className="icon">
                                    <img
                                      src={imagepaths.dollarCollectionIcon}
                                      alt="icon image"
                                    />
                                  </div>
                                </div>
                                <div className="content">
                                  <p className="icon_title">Collections</p>
                                  <p className="count">
                                    {
                                      activitiesData?.activitiesCollectionsToday
                                        ?.repaymentCount
                                    }
                                  </p>
                                  <p className="card_label">
                                    Amount :
                                    <span className="primary-red ms-1">
                                      {activitiesData?.activitiesCollectionsToday?.repaymentAmt?.cur()}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="border_bottom_line"></div>
                      </div>
                    </div>
                  </div>

                  <h2 className="fs-4 mt-4 mb-3">Over Due Portfolio</h2>
                  <div className="row">
                    {overdueCardData &&
                      overdueCardData.map((data) => {
                        return (
                          <div className="col-xl-3 col-sm-6">
                            <div
                              className="card dashboard_card overdue_card"
                              onClick={(...args) => navigate(data?.path)}
                            >
                              <div className="border_bottom_line"></div>
                              <div className="card-body">
                                <div className="icon">
                                  <p className="count_text">{data.title}</p>
                                  <p className="count_label">Days</p>
                                </div>
                                <div className="content">
                                  <p className="count">{data.count}</p>
                                  <p className="card_label">
                                    Amount :
                                    <span className="primary-red ms-1">
                                      {data?.amount?.cur()}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </>
              )}
            {activeKey === "sales_info" &&
              !isSeniorUnderwriter &&
              !isUnderwriter && (
                <>
                  <h2 className="fs-4 mt-4">Disbursement Details</h2>
                  <DisbursementDetailsInfo
                    onTimelineChange={handleTimelineChange}
                    onCustomTimelineChange={handleCustomTimelineChange}
                  />

                  <h2 className="fs-4 mt-4 mb-3">User Registration</h2>
                  <UserRegistrationInfo
                    onTimelineChange={handleTimelineChange}
                    onCustomTimelineChange={handleCustomTimelineChange}
                  />

                  <h2 className="fs-4 mt-4 mb-3">Lead Info</h2>
                  <LeadInfo
                    onTimelineChange={handleTimelineChange}
                    onCustomTimelineChange={handleCustomTimelineChange}
                  />

                  <h2 className="fs-4 mt-4 mb-3">Tele callers Info.</h2>
                  <TelecallerInfoCard title="Lead Status" />
                </>
              )}
            {activeKey === "credit_info" && (
              <>
                <h2 className="fs-4 mt-4 mb-3">Application Status</h2>
                <div className="row">
                  {applicationStatusCardData &&
                    applicationStatusCardData.map((application) => {
                      return (
                        <>
                          <div
                            className="col-xl-3 col-sm-6"
                            onClick={(...args) => navigate(application?.path)}
                          >
                            <div className="card dashboard_card application_card">
                              <div className="border_bottom_line"></div>
                              <div className="card-body">
                                <div className="icon_container">
                                  <p className="icon_title">
                                    {application?.title}
                                  </p>
                                  <div className="icon">
                                    <img
                                      src={application?.icon}
                                      alt="icon image"
                                    />
                                  </div>
                                </div>
                                <div className="content">
                                  <p className="count">{application?.count}</p>
                                  <p className="card_label">
                                    Amount :{" "}
                                    <span className="primary-red ms-1">
                                      {application?.amount?.cur()}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default constore(Home);

import React, { useEffect, useState } from "react";
import FlexCard from "../../LoanApplications/Helpers/FlexCard";
import DocsCard from "../../MyProfile/DocsCard";
import moment from "moment";

const PersonalInfo = (props) => {
  const [empdata, setEmpdata] = useState({});
  const [myinfo, setMyinfo] = useState({});
  const [maindata, setMaindata] = useState({});
  const [proofdata, setProofdata] = useState({});
  const [addressdata, setAddressdata] = useState({});
  const [communicationAddressData, setCommunicationAddressData] = useState({});
  const [permanentAddressData, setPermanentAddressData] = useState({});
  const isReadonly = props.urlparams.params.readonly === "true" ? true : false;
  var tabStatus = props.urlparams.params.status;

  const getUnderwriterInfo = () => {
    props.apis
      .apiGetUnderWriterApplicationDetails({
        applicationNumber: props.urlparams.params.id,
      })
      .then((res) => {
        if (res) {
          console.log({ res });
          setEmpdata(res);
          setMyinfo({
            panFileMetaData: {
              fileId: res?.customer?.panFileId,
            },
            // aadharFrontFileMetaData: {
            //   fileId: res?.customer?.aadharFrontFileId,
            // },
            // aadharBackFileMetaData: {
            //   fileId: res?.customer?.aadharBackFileId,
            // },
            photoFileMetaData: {
              fileId: res?.customer?.photoFileId,
            },
          });
          setMaindata({
            user_id: res?.customerId,
            gender: res?.customer?.gender,
            dob: moment(res?.customer?.dob)?.format("DD-MM-YYYY"),
            age: moment().diff(res?.customer?.dob, "years"),
            mobileNumber: res?.customer?.mobileNumber,
            emailAddress: res?.customer?.emailAddress,
            pincode: res?.customer?.CustomerAddressInfo?.[0]?.pincode,
            aadhar_no: `XXXX-XXXX-${res?.customer?.customerAadharCardInfo?.uid}`,
            pan_number: res?.customer?.customerPanCardInfo?.number,
            address: res?.customer?.CustomerAddressInfo?.[0]?.address1,
          });
          setProofdata({
            name_as_per_application: res.customerName,
            name_as_per_aadhar_card:
              res?.customer?.customerAadharCardInfo?.name,
            name_as_per_pan_card: res?.customer?.customerPanCardInfo?.name,
            aadhar_seeding_successful:
              res?.customer?.customerPanCardInfo?.aadhaarSeedingStatus,
          });
          let addr1 = res?.ApplicationAddressInfo[0]?.address1;
          let addr2 = res?.ApplicationAddressInfo[0]?.address2;
          let addr3 = res?.ApplicationAddressInfo[0]?.address3;

          let address = {
            ...Object.except(res?.ApplicationAddressInfo[0], [
              "fileId",
              "fileName",
              "addressId",
              "address1",
              "address2",
              "address3",
            ]),
            address: `${addr1 ? addr1 + "," : ""} ${addr2 ? addr2 + "," : ""} ${
              addr3 ? addr3 + "." : ""
            }`,
          };
          address.docType = _enum.DOC_TYPES_MAP[address?.docType];
          // ----------------------------------------------------------------
          let communicationAddrObj = res?.ApplicationAddressInfo?.filter(
            (v) => v.addressType === "COMMUNICATION"
          )[0];
          let cmAddr1 = communicationAddrObj?.address1?.toTitleCase();
          let cmAddr2 = communicationAddrObj?.address2?.toTitleCase();
          let cmAddr3 = communicationAddrObj?.address3?.toTitleCase();

          let communicationAddrData = {
            residenceStatus:
              communicationAddrObj?.residenceStatus?.toTitleCase() || "-",
            state: communicationAddrObj?.state,
            pincode: communicationAddrObj?.pincode,
            docType: _enum.DOC_TYPES_MAP[communicationAddrObj?.docType],
            fileId: communicationAddrObj?.fileId,
            city: communicationAddrObj?.city,
            address: `${cmAddr1 ? cmAddr1 + "," : ""} ${
              cmAddr2 ? cmAddr2 + "," : ""
            } ${cmAddr3 ? cmAddr3 + "." : ""}`,
            colspan_key: "col-12 col-lg-6",
            colspan_value: "col-12 col-lg-6",
          };
          setCommunicationAddressData(communicationAddrData);
          // ----------------------------------------------------------------
          let permanentAddrObj = res?.ApplicationAddressInfo?.filter(
            (v) => v.addressType === "PERMANENT"
          )[0];
          let pmAddr1 = permanentAddrObj?.address1?.toTitleCase();
          let pmAddr2 = permanentAddrObj?.address2?.toTitleCase();
          let pmAddr3 = permanentAddrObj?.address3?.toTitleCase();

          let permanentAddrData = {
            residenceStatus:
              permanentAddrObj?.residenceStatus?.toTitleCase() || "-",
            state: permanentAddrObj?.state,
            pincode: permanentAddrObj?.pincode,
            docType: _enum.DOC_TYPES_MAP[permanentAddrObj?.docType],
            fileId: permanentAddrObj?.fileId,
            city: permanentAddrObj?.city,
            address: `${pmAddr1 ? pmAddr1 + "," : ""} ${
              pmAddr2 ? pmAddr2 + "," : ""
            } ${pmAddr3 ? pmAddr3 + "." : ""}`,
            colspan_key: "col-12 col-lg-6",
            colspan_value: "col-12 col-lg-6",
          };
          setPermanentAddressData(permanentAddrData);
          // setApplicationAddressInfo(res?.ApplicationAddressInfo[0]);
        }
      });
  };
  useEffect(() => {
    getUnderwriterInfo();
  }, []);

  props.$setNode({
    props,
    maindata,
  });
  return (
    <>
      <div className="card employee_card">
        <div className="card-body">
          <div className="card_header">
            <div className="avatar_card">
              <div className="me-3">
                <img
                  src={imagepaths.fakeUserIcon}
                  className="avatar"
                  alt="avatar"
                />
              </div>
              <div className="content">
                <h3 className="title">{empdata?.customerName || "-"}</h3>
              </div>
            </div>
          </div>
          <hr />
          <div className="card_body row mx-auto">
            <FlexCard data={maindata} />
          </div>
        </div>
      </div>
      <div className="card p-4">
        <FlexCard data={proofdata} />
      </div>
      {/* <div className="card p-4">
        <FlexCard title="Communication Address" data={addressdata} />
      </div> */}

      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="card p-4">
            <FlexCard
              title="Communication Address"
              data={
                communicationAddressData
                  ? {
                      ...Object.except(communicationAddressData, [
                        "colspan_key",
                        "colspan_value",
                      ]),
                    }
                  : null
              }
              hasFileId={
                communicationAddressData?.docType !== undefined ? true : false
              }
              fileIdKey="docType"
              editable={
                isReadonly ? false : tabStatus !== "inprogress" ? false : true
              }
              onEdit={(...args) =>
                editCommunicationAddress(...args, cmAddressInfo, "cmAddress")
              }
              colspan_key={
                communicationAddressData &&
                communicationAddressData?.colspan_key
              }
              colspan_value={
                communicationAddressData &&
                communicationAddressData?.colspan_value
              }
            />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="card p-4">
            <FlexCard
              title="Permanent Address"
              data={
                permanentAddressData
                  ? {
                      ...Object.except(permanentAddressData, [
                        "colspan_key",
                        "colspan_value",
                      ]),
                    }
                  : null
              }
              hasFileId={
                permanentAddressData?.docType !== undefined ? true : false
              }
              fileIdKey="docType"
              editable={
                isReadonly ? false : tabStatus !== "inprogress" ? false : true
              }
              onEdit={(...args) =>
                editCommunicationAddress(...args, pmAddressInfo, "pmAddress")
              }
              colspan_key={
                permanentAddressData && permanentAddressData?.colspan_key
              }
              colspan_value={
                permanentAddressData && permanentAddressData?.colspan_value
              }
            />
          </div>
        </div>
      </div>
      <DocsCard data={myinfo} />
      {isReadonly && (
        <div className="nav_container mt-5">
          <button
            type="button"
            onClick={(...args) => props.changeTab(...args, "loans")}
            className="btn btn-primary-lite me-3"
          >
            Back
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={(...args) => props.changeTab(...args, "incomedetails")}
          >
            Next
          </button>
        </div>
      )}
    </>
  );
};

export default constore(PersonalInfo);

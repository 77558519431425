import { Card, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import DocsCard from "../../MyProfile/DocsCard";

const CollectionDocs = (props) => {
  var BASE_URL = `${endpoints[environment].api_base}/api/v1/files/download/`;
  const isAdmin = props?.$store?.persona === _enum.ROUTE_PERSONAS.admin;
  var isReadonly = props.urlparams.params.readonly === "true" ? true : false;
  var tabStatus = props.urlparams.params.status;
  const [personalInfoDocs, setPersonalInfoDocs] = useState({});
  const [payslipDocs, setPayslipDocs] = useState([]);
  const [bankstatementDocs, setBankstatementDocs] = useState([]);
  const [additionalDocs, setAdditionalDocs] = useState([]);
  const [fileIds, setFileIds] = useState([]);

  // ----------------------------------------------------------------
  const CheckboxGroup = Checkbox.Group;

  const options = [
    { label: "Personal Info.", value: "personalInfo" },
    { label: "Income & BSA Info.", value: "incomeBsaInfoCheck" },
    { label: "Cibil", value: "cibil" },
  ];

  const [checkedList, setCheckedList] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (list) => {
    setCheckedList(list);
    setCheckAll(list.length === options.length);
    if (list.length > 0) {
      let fileIds = [];
      if (list.includes("personalInfo")) {
        fileIds = [
          ...additionalDocs
            ?.filter((v) => v.category === "PERSONAL")
            .map((v) => v.fileId),
        ];
      } else if (list.includes("incomeBsaInfoCheck")) {
        fileIds = [
          ...payslipDocs?.map((v) => v.fileId),
          ...bankstatementDocs?.map((v) => v.fileId),
          ...additionalDocs
            ?.filter((v) => v.category === "INCOME")
            .map((v) => v.fileId),
        ];
      } else if (list.includes("cibil")) {
        fileIds = [
          ...additionalDocs
            ?.filter((v) => v.category === "CIBIL")
            .map((v) => v.fileId),
        ];
      } else {
        fileIds = [];
      }
      setFileIds(fileIds);
    } else {
      setFileIds([]);
    }
  };

  const onCheckAllChange = (e) => {
    setCheckedList(
      e.target.checked ? options.map((option) => option.value) : []
    );
    setCheckAll(e.target.checked);
    let isSelectAll = e.target.checked;

    if (isSelectAll) {
      let fileIds = [
        ...payslipDocs?.map((v) => v.fileId),
        ...bankstatementDocs?.map((v) => v.fileId),
        ...additionalDocs?.map((v) => v.fileId),
      ];
      setFileIds(fileIds);
    } else {
      setFileIds([]);
    }
  };

  // ----------------------------------------------------------------

  useEffect(() => {
    getDocs();
  }, []);

  const getDocs = () => {
    props.apis
      .apiGetUnderWriterApplicationDetails({
        applicationNumber: props.urlparams.params.id,
      })
      .then((res) => {
        console.log({ res });
        if (res) {
          setPersonalInfoDocs({
            panFileMetaData: {
              fileId: res?.customer?.panFileId,
            },
            aadharFrontFileMetaData: {
              fileId: res?.customer?.aadharFrontFileId,
            },
            aadharBackFileMetaData: {
              fileId: res?.customer?.aadharBackFileId,
            },
            photoFileMetaData: {
              fileId: res?.customer?.photoFileId,
            },
          });
          setPayslipDocs(res?.applicationPayslip);
          setBankstatementDocs(res?.applicationbankStatement);

          if (res?.additionalDocsSubmitted) {
            setAdditionalDocs([
              {
                fileId: res?.customer?.panFileId,
                fileName: "PAN Card Front",
                category: "PERSONAL",
              },
              {
                fileId: res?.customer?.aadharFrontFileId,
                fileName: "Aadhar Card (Front)",
                category: "PERSONAL",
              },
              {
                fileId: res?.customer?.aadharBackFileId,
                fileName: "Aadhar Card (Back)",
                category: "PERSONAL",
              },
              {
                fileId: res?.customer?.photoFileId,
                fileName: "Live Photo",
                category: "PERSONAL",
              },
              ...res?.additionalDocsSubmitted,
            ]);
          }
        }
      })
      .catch((error) => {});
  };

  const downloadAllDocs = async () => {
    let payload = {
      fileIds,
    };
    if (fileIds?.length > 0) {
      props.apis.apiDownloadMultipleFiles(payload).then(async (res) => {
        if (res) {
          let data = await res;
          let blob = new Blob([data]);
          let link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.setAttribute(
            "download",
            `${
              checkedList.length > 1
                ? "underwriter_docs.zip"
                : checkedList.includes("personalInfo")
                ? "personal_info_docs.zip"
                : checkedList.includes("incomeBsaInfoCheck")
                ? "income_and_bsa_docs.zip"
                : checkedList.includes("cibil")
                ? "cibil_docs.zip"
                : "underwriter_docs.zip"
            }`
          );
          link.click();
          setCheckedList([]);
          setCheckAll(false);
        }
      });
    }
  };

  return (
    <>
      <div>
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <Checkbox
            onChange={onCheckAllChange}
            checked={checkAll}
            style={{ marginBottom: "16px" }}
            className="fs-5"
          >
            Select All
          </Checkbox>
          <div className="action_btn_group text-end position-relative">
            <button
              type="button"
              className="btn btn-primary-transparent file_download_btn text-primary"
              onClick={(...args) => downloadAllDocs(...args)}
            >
              <i className="fa fa-download me-2"></i>
              Download All
            </button>
          </div>
        </div>
        <CheckboxGroup
          value={checkedList}
          onChange={onChange}
          className="d-flex"
        >
          <div className="row w-100 g-4">
            {options.map((option) => (
              <div className="col-12">
                <Card>
                  <Checkbox value={option.value} className="mb-3 fs-5 fw-bold">
                    {option.label}
                  </Checkbox>
                  {option.value === "cibil" ? (
                    <>
                      <div className="card profile_card mx-0 sm">
                        <div className="card-header p-0 border-0">
                          <div className="d-flex align-items-center mb-3">
                            <img
                              src={imagepaths.cardGaurdIcon}
                              alt="cardGaurdIcon"
                              className="card_icon"
                            />
                            <h5 className="title ms-2 mb-0">Docs Details</h5>
                          </div>
                        </div>
                        <div className="card-body p-0">
                          <div className="docs_card_container row g-0">
                            {additionalDocs &&
                              additionalDocs
                                ?.filter((v) => v.category === "CIBIL")
                                ?.map((doc, i) => {
                                  return (
                                    <div className="col-6 col-sm-4 col-md-6 col-lg-3">
                                      <div className="docs_card">
                                        <img
                                          src={imagepaths.docsCardRedIcon}
                                          alt="docsCardRedIcon"
                                        />
                                        <h2 className="title">
                                          {doc?.fileName || "-"}
                                        </h2>
                                        {doc?.fileId ? (
                                          <a
                                            href={`${BASE_URL}${doc?.fileId}`}
                                            className="text-primary"
                                          >
                                            Download
                                          </a>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : option.value === "incomeBsaInfoCheck" ? (
                    <>
                      <div className="card profile_card mx-0 sm">
                        <div className="card-header p-0 border-0">
                          <div className="d-flex align-items-center mb-3">
                            <img
                              src={imagepaths.cardGaurdIcon}
                              alt="cardGaurdIcon"
                              className="card_icon"
                            />
                            <h5 className="title ms-2 mb-0">Docs Details</h5>
                          </div>
                        </div>
                        <div className="card-body p-0">
                          <div className="docs_card_container row g-0">
                            {payslipDocs &&
                              payslipDocs?.map((payslip, i) => {
                                return (
                                  <div className="col-6 col-sm-4 col-md-6 col-lg-3">
                                    <div className="docs_card">
                                      <img
                                        src={imagepaths.docsCardRedIcon}
                                        alt="docsCardRedIcon"
                                      />
                                      <h2 className="title">
                                        Salary Slip {i + 1}
                                      </h2>
                                      {payslip?.fileId ? (
                                        <a
                                          href={`${BASE_URL}${payslip?.fileId}`}
                                          className="text-primary"
                                        >
                                          Download
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            {bankstatementDocs &&
                              bankstatementDocs?.map((bankstatement, i) => {
                                return (
                                  <div className="col-6 col-sm-4 col-md-6 col-lg-3">
                                    <div className="docs_card">
                                      <img
                                        src={imagepaths.docsCardRedIcon}
                                        alt="docsCardRedIcon"
                                      />
                                      <h2 className="title">BSA {i + 1}</h2>
                                      {bankstatement?.fileId ? (
                                        <a
                                          href={`${BASE_URL}${bankstatement?.fileId}`}
                                          className="text-primary"
                                        >
                                          Download
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            {additionalDocs &&
                              additionalDocs
                                ?.filter((v) => v.category === "INCOME")
                                ?.map((doc, i) => {
                                  return (
                                    <div className="col-6 col-sm-4 col-md-6 col-lg-3">
                                      <div className="docs_card">
                                        <img
                                          src={imagepaths.docsCardRedIcon}
                                          alt="docsCardRedIcon"
                                        />
                                        <h2 className="title">
                                          {doc?.fileName || "-"}
                                        </h2>
                                        {doc?.fileId ? (
                                          <a
                                            href={`${BASE_URL}${doc?.fileId}`}
                                            className="text-primary"
                                          >
                                            Download
                                          </a>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="card profile_card mx-0 sm">
                        <div className="card-header p-0 border-0">
                          <div className="d-flex align-items-center mb-3">
                            <img
                              src={imagepaths.cardGaurdIcon}
                              alt="cardGaurdIcon"
                              className="card_icon"
                            />
                            <h5 className="title ms-2 mb-0">Docs Details</h5>
                          </div>
                        </div>
                        <div className="card-body p-0">
                          <div className="docs_card_container row g-0">
                            {additionalDocs &&
                              additionalDocs
                                ?.filter((v) => v.category === "PERSONAL")
                                ?.map((doc, i) => {
                                  return (
                                    <div className="col-6 col-sm-4 col-md-6 col-lg-3">
                                      <div className="docs_card">
                                        <img
                                          src={imagepaths.docsCardRedIcon}
                                          alt="docsCardRedIcon"
                                        />
                                        <h2 className="title">
                                          {doc?.fileName || "-"}
                                        </h2>
                                        {doc?.fileId ? (
                                          <a
                                            href={`${BASE_URL}${doc?.fileId}`}
                                            className="text-primary"
                                          >
                                            Download
                                          </a>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Card>
              </div>
            ))}
          </div>
        </CheckboxGroup>
      </div>
      {/* <div className="nav_container mt-5">
        <button
          type="button"
          onClick={(...args) => props.changeTab(...args, "cibil")}
          className="btn btn-primary-lite me-3"
        >
          Cancel
        </button>
        <button type="button" className="btn btn-primary">
          Generate Form
        </button>
      </div> */}
    </>
  );
};

export default constore(CollectionDocs);

export const UnderwriterMenuList = [
  {
    title: "Dashboard",
    iconStyle: imagepaths.Dashboard,
    iconActive: imagepaths.DashboardActive,
    to: "/app/home",
    id: ["home", "portfolioinfo"],
  },
  {
    title: "Pending Applications",
    iconStyle: imagepaths.applicationCheckImg,
    iconActive: imagepaths.applicationCheckActiveImg,
    to: "/app/pendingapplications",
    id: "pendingapplications",
  },
  {
    title: "My Applications",
    iconStyle: imagepaths.applicationCheckImg,
    iconActive: imagepaths.applicationCheckActiveImg,
    to: "/app/loanapplications",
    id: "loanapplications",
  },
  {
    title: "Loan Details",
    iconStyle: imagepaths.applicationCheckImg,
    iconActive: imagepaths.applicationCheckActiveImg,
    to: "/app/loandetails",
    id: "loandetails",
  },
  {
    title: "Dedup Check",
    iconStyle: imagepaths.DupeCheckIcon,
    iconActive: imagepaths.DupeCheckIconActive,
    to: "/app/dupecheck",
    id: "dupecheck",
  },
  {
    title: "BSA",
    iconStyle: imagepaths.applicationCheckImg,
    iconActive: imagepaths.applicationCheckActiveImg,
    to: "/app/bankstatementanalyzer",
    id: "bankstatementanalyzer",
  },
  {
    title: "BSA 2",
    iconStyle: imagepaths.applicationCheckImg,
    iconActive: imagepaths.applicationCheckActiveImg,
    to: "/app/bsa",
    id: "bsa",
  },
];

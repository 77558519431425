import devapis from "./dev.endpoints";
import stagingapis from "./staging.endpoints";
import prodapis from "./prod.endpoints";
const localtodev = "http://localhost:3000,http://localhost:3001,";
const localtostage = "";
const localtoprod = "";

export const endpoints = {
  dev: {
    base: `${localtodev}https://dev2login.afiloans.co.in/open/login`,
    baseFE_URL: `https://dev2login.afiloans.co.in/open/login`,
    api_base: "https://devapi2.afiloans.co.in",
    static_token: "",
    ...devapis,
    "open/login": {
      base: `${localtodev}https://dev2login.afiloans.co.in/open/login`,
      api_base: "https://devapi2.afiloans.co.in",
      ...devapis,
    },
    "open/customer/login": {
      base: `${localtodev}https://dev2login.afiloans.co.in/open/customer/login`,
      api_base: "https://devapi2.afiloans.co.in",
      ...devapis,
    },
  },
  staging: {
    base: `${localtostage}https://stagelogin.afiloans.co.in/`,
    baseFE_URL: `https://stagelogin.afiloans.co.in/`,
    api_base: "https://stageapi.afiloans.co.in",
    static_token: "",
    ...stagingapis,

    "open/login": {
      base: `${localtostage}https://stagelogin.afiloans.co.in/`,
      api_base: "https://stageapi.afiloans.co.in",
      ...stagingapis,
    },
  },
  production: {
    base: `${localtoprod}https://login.afiloans.co.in/open/login`,
    baseFE_URL: `https://login.afiloans.co.in/open/login`,
    api_base: "https://api.afiloans.co.in",
    static_token: "",
    ...prodapis,

    "open/login": {
      base: "https://login.afiloans.co.in/open/login",
      api_base: "https://api.afiloans.co.in",
      ...prodapis,
    },
    "open/customer/login": {
      base: "https://login.afiloans.co.in/open/customer/login",
      api_base: "https://api.afiloans.co.in",
      ...prodapis,
    },
  },
};
console.log(
  Object.entries(endpoints)
    .map(([key, val]) => ({ ...val, name: key }))
    .find((obj) =>
      (obj?.base?.split(",") || []).includes(window?.location?.origin)
    )
);
export default endpoints;

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { Dropdown } from "react-bootstrap";
import MainPagetitle from "../../../layouts/MainPagetitle";
import PendingApplicationModal from "./PendingApplicationModal";
import PendingApplicationSearch from "./PendingApplicationSearch/PendingApplicationSearch";
import LeadsCenteredModal from "../Leads/LeadsCenteredModal";

const PendingApplications = (props) => {
  const { role } = props?.$store?.user;
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [modalFields, setModalFields] = useState([
    {
      label: "Customer Name",
      name: "customerName",
      type: "text",
      placeholder: "Please Enter",
    },
    {
      label: "Mobile No.",
      name: "mobileNumber",
      type: "number",
      placeholder: "Please Enter",
    },
    // {
    //   label: "Leads",
    //   name: "leadId",
    //   type: "select",
    //   options: [],
    //   placeholder: "Please Enter",
    // },
    {
      label: "Source",
      name: "source",
      type: "text",
      // options: _enum.SOURCES,
      placeholder: "Please Enter",
    },
    {
      label: "Product Type",
      name: "productType",
      type: "select",
      options: [],
      placeholder: "Please Enter",
    },
    {
      label: "Bank Name",
      name: "bankName",
      type: "select",
      options: [],
      placeholder: "Please Enter",
    },
    {
      label: "Application No.",
      name: "applicationNumber",
      type: "text",
      placeholder: "Please Enter",
    },
    {
      label: "Application Date",
      name: "applicationDate",
      type: "date",
      placeholder: "Please Enter",
    },
    {
      label: "Loan Amount",
      name: "loanAmount",
      type: "number",
      placeholder: "Please Enter",
    },
    {
      label: "Status",
      name: "status",
      type: "select",
      options: _enum.APPLICATION_STATUS,
      placeholder: "Please Enter",
    },
    {
      label: "Remarks",
      name: "remarks",
      type: "textarea",
    },
  ]);
  const [editData, setEditData] = useState({});
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});

  const [rowcount, setRowcount] = useState(0);
  const [columns, setColumns] = useState([
    {
      dataIndex: "customer",
      title: "Name",
      render: (text, record) => {
        return (
          <div>
            <h6 className="text-primary">{record.customerName}</h6>
            <span>{record.applicationId}</span>
          </div>
        );
      },
    },
    {
      dataIndex: "customer",
      title: "Mobile No.",
      render: (text, record) => {
        return record?.customer?.mobileNumber;
      },
    },
    {
      dataIndex: "ApplicationAddressInfo",
      title: "Location",
      render: (text, record) => {
        return (
          <>
            {record?.ApplicationAddressInfo?.filter(
              (v) => v?.addressType === "COMMUNICATION"
            )[0]?.city || "-"}
          </>
        );
      },
    },
    {
      dataIndex: "createdAt",
      title: "Application Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "customerType",
      title: "Customer Type",
      render: (text, record) => {
        return record?.customer?.customerType?.toTitleCase();
      },
    },
    {
      dataIndex: "productType",
      title: "Product Type",
      render: (text, record) => {
        return <>{text || "Personal Loan"}</>;
      },
    },
    {
      dataIndex: "loanAmount",
      title: "Applied Loan Amount",
      render: (text) => {
        return <>{text?.cur()}</>;
      },
    },
    {
      dataIndex: "duration",
      title: "Tenure",
      render: (text, record) => {
        return <span>{text ? `${text} Days` : "N/A"}</span>;
      },
    },
    {
      dataIndex: "status",
      title: "Application Status",
      render: (text, record) => {
        let mappedStatus =
          _enum.STATUS_COLOR_MAP[record?.status?.toLowerCase()] || "";
        return (
          <Dropdown className="status-dropdown no-arrow pe-none">
            <Dropdown.Toggle as="div" className={mappedStatus}>
              <span>{record.status.toTitleCase()}</span>
              <i class="fa-solid fa-chevron-down ms-2"></i>
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
  ]);
  //
  const changePasswordModalRef = useRef(0);
  const [popupChangePasswordFields, setPopupChangePasswordFields] = useState(
    {}
  );
  const [changepasswordData, setChangepasswordData] = useState({});

  const [changepasswordFields, setChangepasswordFields] = useState([
    {
      label: "Old Password",
      name: "oldPassword",
      type: "password",
      customcolspan: "12",
    },
    {
      label: "New Password ",
      name: "newPassword",
      type: "password",
      customcolspan: "12",
    },
    {
      label: "Confirm Password",
      name: "confirmPassword",
      type: "password",
      customcolspan: "12",
    },
  ]);

  const onPageChange = (pageData) => {
    // console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  const getApplications = (params) => {
    let payload = {
      ...params,
    };
    console.log({ payload });
    props.apis
      .apiGetUnderWriterUnassignedApplications(payload)
      .then(({ data, total }) => {
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      });
  };

  const GetToSelfApplication = (record) => {
    // setEditmode(true);
    // setViewmode(false);
    // GET APPLICATION BY ID
    props.apis
      .apiUnderwriterSelfAssignApplication({
        applicationNumber: record.applicationId,
      })
      .then((data) => {
        swal2.fire({
          title: "Application self-assigned successfully.",
          icon: "success",
          iconColor: "#FF4D4D",
          timer: 4000,
        });
        getApplications();
      })
      .catch((e) => {});
  };

  const handleSubmit = (e, data, form) => {
    e.preventDefault();
    console.log("formdata: ", data);
    let formdata = data;
    let payload = {
      ...formdata,
    };
    payload.leadId = Number(formdata.leadId);
    payload.loanAmount = Number(formdata.loanAmount);
    payload.applicationDate = new Date(formdata.applicationDate).toISOString();
    // if (editmode) {
    //   props.apis
    //     .apiUpdateApplication(payload, {
    //       applicationNumber: editData?.applicationNumber,
    //     })
    //     .then((res) => {
    //       console.log({ apiUpdateApplication: res });
    //       swal2.fire({
    //         title: "Application updated successfully.",
    //         icon: "success",
    //         iconColor: "#FF4D4D",
    //         timer: 4000,
    //       });
    //       // closeModal();
    //       onModalClose();
    //       getApplications();
    //     });
    // } else {
    //   payload.assignedPersonId = props?.$store?.user?.employeeId;
    //   props.apis.apiAddApplication(payload).then((res) => {
    //     console.log({ apiAddApplication: res });
    //     swal2.fire({
    //       title: "Application added successfully.",
    //       icon: "success",
    //       iconColor: "#FF4D4D",
    //       timer: 4000,
    //     });
    //     closeModal();
    //     getApplications();
    //   });
    // }
  };

  const onSearch = (data, reset) => {
    console.log("onSearch on leads", data, reset);
    if (Object.keys(data).length > 0) {
      let defaultSearchData = Object.only(data, ["limit", "page"]);
      let payload;
      if (reset) {
        payload = {
          ...defaultSearchData,
        };
      } else {
        payload = {
          ...data,
          page: 1,
          limit: 10,
        };
      }
      console.log({ payload });
      setSearch(payload);
    }
  };

  const addModalFields = (values) => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    setPopupfields(fields);
    setPopupdata(formdata);
    setOpenCanvas(true);
  };

  const onModalClose = () => {
    console.log("onModalClose");
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});
    closeModal();
  };

  // ----------------------------------------------------------------
  // HANDLE CHANGE PASSWORD MODAL
  const openChangePasswordmodal = () => {
    changePasswordModalRef.current.click();
  };

  const closeChangePasswordmodal = () => {
    document.querySelector("#changepasswordModal #bsmodalclose").click();
    setChangepasswordData({});
    setPopupChangePasswordFields({});
  };

  const addChangepasswordData = () => {
    let fields = (changepasswordFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    setPopupChangePasswordFields(fields);
    setChangepasswordData(formdata);
    openChangePasswordmodal();
  };

  const handleChangePasswordSubmit = (e, data) => {
    console.log({ handleChangePasswordSubmit: data });
    if (data.newPassword !== data.confirmPassword) {
      swal2.fire({
        title: "Password and Confirm Password does not match",
        icon: "warning",
        iconColor: "#FF4D4D",
        timer: 4000,
      });
    } else {
      let payload = {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      };

      props.apis.apiPasswordReset(payload).then((res) => {
        console.log({ apiPasswordReset: res });
        if (res.status === 200) {
          swal2
            .fire({
              title:
                "Password changed successfully, Please re-login with new password",
              icon: "success",
              iconColor: "#FF4D4D",
            })
            .then((result) => {
              if (result.isConfirmed) {
                closeChangePasswordmodal();
                props?.apis?.applogout();
              }
            });
        }
      });
    }
  };

  // ----------------------------------------------------------------

  useEffect(() => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    // console.log({ updatedfields: fields });
    setPopupfields(fields);
  }, [editData]);

  useEffect(() => {
    const { emailAddress } = props?.$store?.logged;
    const { isPasswordUpdated, role } = props?.$store?.user;
    if (
      !isPasswordUpdated &&
      role !== _enum.PERSONAS.admin &&
      emailAddress !== undefined
    ) {
      addChangepasswordData();
    }
  }, []);

  useEffect(() => {
    let payload = {
      ...search,
    };
    console.log({ payload, search });

    getApplications(payload);
  }, [search]);

  props.$setNode({
    props,
    search,
    editData,
  });
  return (
    <>
      <MainPagetitle
        mainTitle="Pending Applications"
        parentTitle={"Home"}
        pageIcon={imagepaths.applicationCheckActiveImg}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card border-0">
              <div className="card-body p-0">
                <div className="tbl-caption-container">
                  {/* <h4
                    className={Object.className({
                      "heading mb-0": true,
                      "d-none": role === _enum.PERSONAS.admin,
                    })}
                  >
                    {role === _enum.PERSONAS.sales
                      ? "Assigned to Opps"
                      : "Applications"}
                  </h4> */}
                  <PendingApplicationSearch
                    onSearch={(...arg) => onSearch(...arg)}
                  />
                  {/* <div
                    className={Object.className({
                      "separator ms-4": true,
                      "d-none":
                        role === _enum.PERSONAS.sales ||
                        role === _enum.PERSONAS.admin,
                    })}
                  ></div> */}
                  {/* <div className="header_box">
                    <Link
                      to={"#"}
                      className={Object.className({
                        "btn btn-primary ms-3": true,
                        "d-none":
                          role === _enum.PERSONAS.sales ||
                          role === _enum.PERSONAS.admin,
                      })}
                      onClick={(...args) => addModalFields(...args)}
                    >
                      + Add Application
                    </Link>
                  </div> */}
                </div>
                <AppTable
                  data={tableData}
                  columns={columns}
                  onChange={(...arg) => onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  editAction={(e) => GetToSelfApplication(e)}
                  editActionText="Get to self"
                  viewable={false}
                  deletable={false}
                  loading={isFetching}
                ></AppTable>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PendingApplicationModal
        id="applicationCanvas"
        title={`${
          editmode
            ? "Edit Application"
            : viewmode
            ? "View Application"
            : "Add Application"
        }`}
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        editdata={editData}
        fields={popupfields}
        editmode={editmode}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={`${editmode ? "Update" : "Add"}`}
        disableallfields={viewmode ? true : false}
      />
      {/* below button hidden on UI */}
      <button
        className="btn btn-sm btn-outline-secondary d-none"
        onClick={(e) => addChangepasswordData(e)}
        ref={changePasswordModalRef}
        data-bs-toggle="modal"
        data-bs-target="#changepasswordModal"
      >
        <span>Change Password</span>
      </button>

      <LeadsCenteredModal
        id="changepasswordModal"
        title="Change Password"
        submitText="Chage Password"
        hidecancelbtn={true}
        data={changepasswordData}
        fields={popupChangePasswordFields}
        onSubmit={(...args) => handleChangePasswordSubmit(...args)}
        modaltype="centeredmodal"
        onClose={() => closeChangePasswordmodal()}
      />
    </>
  );
};

export default constore(PendingApplications);

export default {

    apiGetTelecallerDashboardMoke() {
        let data = [
            {
                title: "Total Leads",
                icon: imagepaths.userIconLight,
                value: 100,
            },
            {
                title: "Pending Leads",
                icon: imagepaths.userInfoIcon,
                value: 40,
            },
            {
                title: "Completed Leads",
                icon: imagepaths.userCheckIcon,
                value: 60,
            },
            {
                title: "Interested Leads",
                icon: imagepaths.userOutIcon,
                value: 5,
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },

    apiGetOperationsDashboardMoke() {
        let data = [
            {
                title: "Total Applications",
                icon: imagepaths.fileIconLight,
                value: 100,
            },
            {
                title: "Total Applied",
                icon: imagepaths.checkCircleRightIcon,
                value: 40,
            },
            {
                title: "Total Disbursed",
                icon: imagepaths.boxUpIcon,
                value: 60,
            },
            {
                title: "Total Rejected",
                icon: imagepaths.fileErrorIcon,
                value: 5,
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },

    apiAddLead(data) {
        return this.request(this.api().ADD_LEAD, data).exec().log().get();
    },

    apiGetLeads(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_LEADS, payload)
            .exec().log()
            .mapresults((response) => {
                return {
                    data: response?.data,
                    total: response?.count
                }
            })
            .get();
    },

    apiAddCustomerLead(data) {
        return this.request(this.api().ADD_CUSTOMER_LEAD, data).exec().log().get();
    },

    apiGetLeadHistory(data) {
        return this.request(this.api().GET_LEAD_HISTORY)
            .exec().log()
            .mapresults((response) => {
                return response
            })
            .get();
    },
    apiGetCustomerLogsById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().GET_CUSTOMER_LOAN_LOGS)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((res) => {
                return {
                    data: res?.Leads
                }
            })
            .get();
    },
    apiGetLeadsById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().GET_LEADS_BY_ID,)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((res) => {
                return res
            })
            .get();
    },

    apiUpdateLead(data, urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };

        let payload = {
            leadName: data?.leadName,
            mobileNumber: data?.mobileNumber,
            // emailAddress: data?.emailAddress,
            location: data?.location,
            source: data?.source,
            remarks: data?.remarks,
            status: data?.status,
            followUpAt: data?.followUpAt,
            loanAmount: data.loanAmount ? Number(data.loanAmount) : ""
        }
        // payload = Object.filter(payload, (v) => v);

        return this.request(this.api().UPDATE_LEAD, payload).urltransform(urldata).exec().log().get();
    },

    apiDeleteLead(urldata = mixins.params()) {

        return this.request(this.api().DELETE_LEAD)
            .urltransform(urldata)
            .exec().log().get();
    },

    apiDeleteBulkLeads(data) {

        return this.request(this.api().DELETE_BULK_LEADS, data)
            .exec().log().get();
    },

    apiAssignDeptToLead(data) {
        return this.request(this.api().ASSIGN_DEPT_TO_LEAD, data)
            .exec()
            .log()
            .get();
    },
    // BULK FILES
    apiBulkUploadLead(data) {
        return this.request(this.api().BULK_UPLOAD_LEAD, data)
            .multipart()
            .exec()
            .log()
            .get();
    },
    apiBulkDownloadLead(data) {
        return this.request(this.api().DOWNLOAD_LEADS_LISTS, data)
            .exec()
            .log()
            .get();
    },
    apiDownloadLeadsTemplate() {
        return this.request(this.api().DOWNLOAD_LEADS_TEMPLATE)
            .urltransform({})
            .geturl();
    },
    apiAdminReassignLeads(data) {
        return this.request(this.api().REASSIGN_LEADS_TO_DIFFERENT_USER, data).exec().log().get();
    },
    // SALES DASHBOARD API'S

    apiGetTotalLeadsData(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_TOTAL_LEADS_DATA, payload)
            .exec()
            .log()
            .get();
    },
    apiGetPendingLeadsData(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_PENDING_LEADS_DATA, payload)
            .exec()
            .log()
            .get();
    },
    apiGetCompletedLeadsData(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_COMPLETED_LEADS_DATA, payload)
            .exec()
            .log()
            .get();
    },
    apiGetInterestedLeadsData(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_INTERESTED_LEADS_DATA, payload)
            .exec()
            .log()
            .get();
    },
}
import React, { useEffect, useRef, useState } from "react";

const SalesLeadInfo = (props) => {
  const infoForm = useRef(null);
  const [leadsInfoData, setLeadsInfoData] = useState([]);
  const [timeline, setTimeline] = useState("TODAY");
  const [searchdata, setSearchdata] = useState({});
  const [isFetching, setIsFetching] = useState(true);
  const columns = [
    { dataIndex: "total_leads", title: "Total Leads" },
    { dataIndex: "open", title: "Open" },
    { dataIndex: "interested", title: "Interested" },
    { dataIndex: "not_interested", title: "Not Interested" },
    { dataIndex: "call_not_answered", title: "Call Not Answered" },
    { dataIndex: "not_reachable", title: "Not Reachable" },
    { dataIndex: "follow_up", title: "Follow Up" },
  ];

  // ADMIN LEADS INFO
  const getAdminLeadsInfo = (query, params) => {
    let data = {
      ...(params && params === "CUSTOM"
        ? {
            ...query,
            timeline: "CUSTOM",
          }
        : {
            ...query,
            timeline: timeline,
          }),
    };

    console.log({ params, data, timeline });

    // props.apis.apiGetDashboardAdminLeadsInfo(data).then((res) => {
    //   setLeadsInfoData(res);
    // });
    setIsFetching(false);
  };

  const showInputs = (inputnames) => {
    if (inputnames.length > 0) {
      inputnames.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          element.classList.remove("d-none");
        }
      });
    }
  };

  const hideInputs = (inputnames) => {
    if (inputnames.length > 0) {
      inputnames.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          element.classList.add("d-none");
        }
      });
    }
  };

  const handleDateChange = (value) => {
    let val = value[0]?.key || "TODAY";
    let inputs = [
      "lead-column-startDate",
      "lead-column-endDate",
      "lead-column-search_btn",
      "lead-column-reset_btn",
    ];
    console.log({ val });
    if (val === "CUSTOM") {
      showInputs(inputs);
      setLeadsInfoData([]);
    } else {
      setTimeline(val);
      hideInputs(inputs);
    }
  };

  const onSearch = () => {
    let data = infoForm.current.form.current.getData();
    let queryData = {
      ...Object.except(data, ["timeline"]),
    };
    setSearchdata(queryData);
    // getAdminLeadsInfo(queryData, "CUSTOM");
  };

  const resetSearchForm = () => {
    infoForm?.current?.form?.current?.reset();
    let inputs = [
      "lead-column-startDate",
      "lead-column-endDate",
      "lead-column-search_btn",
      "lead-column-reset_btn",
    ];
    hideInputs(inputs);
    // getAdminLeadsInfo({}, "TODAY");
  };

  useEffect(() => {
    // ADMIN LEADS INFO
    getAdminLeadsInfo();
  }, [timeline]);

  props.$setNode({
    props,
  });
  return (
    <>
      <div className="mb-4 dashboard_form_box">
        <div className="form_outer">
          <AppForm
            ref={infoForm}
            onSubmit={(...args) => onSearch(...args)}
            className="row justify-content-end"
          >
            <div
              className="col-12 col-md-3 col-lg-3 col-xl-2"
              id="column-timeline"
            >
              <AppInputFocus
                type="select"
                name="timeline"
                label="Time Line"
                options={_enum.OVERALL_DETAIL}
                onChange={(...args) => handleDateChange(...args)}
                defaultValue={"TODAY"}
                placeholder="Time Line"
              ></AppInputFocus>
            </div>
            <div
              className="col-12 col-md-3 col-lg-3 col-xl-2 d-none"
              id="lead-column-startDate"
            >
              <AppInputFocus
                type="date"
                name="startDate"
                label="Start Date"
                placeholder="Start Date"
                required={true}
              ></AppInputFocus>
            </div>
            <div
              className="col-12 col-md-3 col-lg-3 col-xl-2 d-none"
              id="lead-column-endDate"
            >
              <AppInputFocus
                type="date"
                name="endDate"
                label="End Date"
                placeholder="End Date"
                required={true}
              ></AppInputFocus>
            </div>
            <div
              className="col-2 col-md-1 col-lg-1 col-xl-1 d-none"
              id="lead-column-search_btn"
            >
              <button type="submit" className="btn btn-primary search_btn">
                <i className="fa fa-search"></i>
                <span>Search</span>
              </button>
            </div>
            <div
              className="col-2 col-md-1 col-lg-1 col-xl-1 d-none"
              id="lead-column-reset_btn"
            >
              <button
                type="button"
                className="btn btn-primary search_btn"
                onClick={(...args) => resetSearchForm(...args)}
              >
                <i class="fa-solid fa-filter-circle-xmark"></i>
                <span>Search</span>
              </button>
            </div>
          </AppForm>
        </div>
        <div className="pt-3">
          <AppTable
            data={leadsInfoData}
            columns={columns}
            reorder={true}
            showPagination={false}
            editable={false}
            viewable={false}
            deletable={false}
            loading={isFetching}
          ></AppTable>
        </div>
      </div>
    </>
  );
};

export default constore(SalesLeadInfo);

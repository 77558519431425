import React, { useState, useEffect } from "react";
import { Nav, Tab } from "react-bootstrap";
import MainPagetitle from "../../../../layouts/MainPagetitle";
import AddressDetails from "./AddressDetails/addressDetails";
import BSA from "./BSA/bsa";
import IncomeDetails from "./IncomeDetails/incomeDetails";

const LoanCalculationDocs = (props) => {
  const fromPath = props?.urlparams?.params?.path;
  const [activeKey, setActiveKey] = useState("addressDetails");
  const tabData = [
    { key: "addressDetails", name: "Address Details" },
    { key: "incomedetails", name: "Income Details" },
    { key: "bsa", name: "Pay Slip & BSA Details" },
  ];
  const [editInfo, setEditInfo] = useState({});
  const handleSelect = (key) => {
    setActiveKey(key);
  };

  const changeTab = (e, key) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveKey(key);
    scrollToTop();
  };

  useEffect(() => {
    props.apis.apiGetCustomerInfo().then((res) => {
      setEditInfo(res);
    });
  }, [activeKey]);

  const getPageIcon = () => {
    let icon;
    if (fromPath === "customerhome") {
      icon = imagepaths.DashboardActive;
    }
    if (fromPath === "services") {
      icon = imagepaths.serviceHomeIconActive;
    }
    return icon;
  };

  const getMainTitle = () => {
    let mainTitle = "";
    if (fromPath === "customerhome") {
      mainTitle = "Dashboard";
    }
    if (fromPath === "services") {
      mainTitle = "Service";
    }
    return mainTitle;
  };

  const getBreadCums = () => {
    let pagetitle = "";
    if (fromPath === "customerhome") {
      pagetitle = "Loans Rs. 50000";
    }
    if (fromPath === "services") {
      pagetitle = "Loans Rs. 50000";
    }
    return pagetitle;
  };

  const getBackLink = () => {
    let url;
    if (fromPath === "customerhome") {
      url = "/app/customerhome";
    }
    if (fromPath === "services") {
      url = "/app/services";
    }
    return url;
  };

  props.$setNode({
    props,
  });
  return (
    <>
      <MainPagetitle
        parentTitle={"Home"}
        mainTitle={getMainTitle()}
        back={getBreadCums()}
        backLink={getBackLink()}
        pageIcon={getPageIcon()}
      />
      {/* <!-- Nav tabs --> */}
      <div className="custom-tab-1 p-4">
        <Tab.Container
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          onSelect={handleSelect}
        >
          <Nav as="ul" className="nav-tabs border-0">
            {tabData.map((data, i) => (
              <Nav.Item as="li" key={i}>
                <Nav.Link
                  eventKey={data.key}
                  className="text-dark bg-transparent"
                >
                  {data.name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <Tab.Content className="pt-4">
            {activeKey === "addressDetails" && (
              <Tab.Pane eventKey="addressDetails">
                <AddressDetails changeTab={(...args) => changeTab(...args)} />
              </Tab.Pane>
            )}
            {activeKey === "incomedetails" && (
              <Tab.Pane eventKey="incomedetails">
                <IncomeDetails
                  changeTab={(...args) => changeTab(...args)}
                  editdata={editInfo}
                />
              </Tab.Pane>
            )}
            {activeKey === "bsa" && (
              <Tab.Pane eventKey="bsa">
                <BSA changeTab={(...args) => changeTab(...args)} />
              </Tab.Pane>
            )}
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};

export default constore(LoanCalculationDocs);
